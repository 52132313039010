import React, { useMemo } from 'react';
import { MapRoutePointT, SyncAssignmentFormMapStateContextValueT } from '../contexts/sync-map-state';

export const usySyncMapStateContextValue = (): SyncAssignmentFormMapStateContextValueT => {
    const [onSetMapBoundingBox, setOnSetMapBoundingBox] =
        React.useState<SyncAssignmentFormMapStateContextValueT['onSetMapBoundingBox']>(null);
    const [onMapClickCallback, setOnMapClickCallback] =
        React.useState<SyncAssignmentFormMapStateContextValueT['onMapClickCallback']>(null);
    const [onDragEndDnDCallback, setOnDragEndDnDCallback] =
        React.useState<SyncAssignmentFormMapStateContextValueT['onDragEndDnDCallback']>(null);
    const [mapRoutePoints, setMapRoutePoints] = React.useState<Array<MapRoutePointT>>([]);

    return useMemo(() => {
        return {
            onSetMapBoundingBox,
            setOnSetMapBoundingBox,

            mapRoutePoints,
            setMapRoutePoints,

            onMapClickCallback,
            setOnMapClickCallback,

            onDragEndDnDCallback,
            setOnDragEndDnDCallback,
        };
    }, [
        onSetMapBoundingBox,
        setOnSetMapBoundingBox,

        mapRoutePoints,
        setMapRoutePoints,

        onMapClickCallback,
        setOnMapClickCallback,

        onDragEndDnDCallback,
        setOnDragEndDnDCallback,
    ]);
};
