import * as React from 'react';

import classNames from 'classnames/bind';

import styles from './NameCell.scss';
import { AdditionalServiceT } from 'broker-admin/store/price-settings/additional-services/models';
import AdditionalServiceLabel from 'common/components/AdditionalServiceLabel/AdditionalServiceLabel';

type PropsT = {
    additionalService: AdditionalServiceT;
};

const cx = classNames.bind(styles);

const NameCell: React.FC<PropsT> = React.memo((props) => {
    const { additionalService } = props;

    return (
        <div className={cx('cell')}>
            <div className={cx('name')}>
                <AdditionalServiceLabel type={additionalService.enum} />
            </div>
            <div className={cx('group')}>{additionalService.type}</div>
        </div>
    );
});

export default NameCell;
