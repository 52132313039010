import { InfoTableRowT } from 'design-system/components/InfoTable/InfoTable';
import FlagIcon from 'common/icons/FlagIcon/FlagIcon';
import AsyncCountryFormatter from 'design-system/components/InfoTable/formatters/AsyncCountryFormatter/AsyncCountryFormatter';
import { ApiDistanceByCountryT, CountryCodeT } from 'common/utils/api/models';
import UnitTypeCount from 'common/components/units/UnitTypeCount/UnitTypeCount';
import { UnitTypeEnum } from 'common/constants';
import { convertToKm } from 'common/utils/distance';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

export const getDistanceByCountryRows = (distanceByCountry: ApiDistanceByCountryT | null | undefined) => {
    const { t } = useTranslation();

    return (
        distanceByCountry?.map(({ country, distance }): InfoTableRowT => {
            // @ts-expect-error This comparison appears to be unintentional because the types 'Countries' and '"null"' have no overlap.
            const isUnspecified = country === 'null';

            return {
                icon: <FlagIcon countryCode={country} />,
                name:
                    !country || isUnspecified ? (
                        t('common:rfq-details.unspecified')
                    ) : (
                        <AsyncCountryFormatter countryCode={country as CountryCodeT} />
                    ),
                value: <UnitTypeCount type={UnitTypeEnum.kilometersAbbreviation} count={convertToKm(distance)} />,
                emptyValue: t('common:info-table.placeholders.empty'),
                isBoldValue: true,
                testSelector: `payload-mileage-${country}`,
            };
        }) || []
    );
};
