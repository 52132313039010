import * as React from 'react';
import classNames from 'classnames/bind';

import styles from './StartEditTimeWindows.scss';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import { useTranslation } from 'react-i18next';
import { DispatchDetailsT } from 'broker-admin/store/dispatch-details/models';
import TimeLeftForStart from 'common/layouts/sidebar-footers/TimeLeftForStart/TimeLeftForStart';
import TimeWindowIcon from 'common/icons/TimeWindowIcon';
import { StyleGuideColorsEnum } from 'common/constants';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
    tourId: TourIdT | null;
    tour: DispatchDetailsT['tours'][number] | null | undefined;
    onStartEditTimeWindows: () => void;
    isDisableAnyActions: boolean;
};

const StartEditTimeWindows: React.FC<PropsT> = React.memo((props) => {
    const { tourId, tour, onStartEditTimeWindows, isDisableAnyActions } = props;

    const { t } = useTranslation();

    if (!tour) {
        return null;
    }

    const firstWaypoint = tour?.waypoints?.[0] || null;
    const deadline = firstWaypoint?.correctedDateTimeFrom || firstWaypoint?.originalDateTimeFrom || null;

    return (
        <TimeLeftForStart
            deadline={deadline}
            alreadyStartedLabel={t('assignment.tour-already-started')}
            deadlineLabel={t('assignment.to-assign')}
            renderActions={(isDisabled) => (
                <Button
                    leftIcon={
                        <TimeWindowIcon
                            fillColor={StyleGuideColorsEnum.transparent}
                            strokeColor={StyleGuideColorsEnum.white}
                        />
                    }
                    theme={ButtonThemeEnum.primary}
                    className={cx('action', 'action--start')}
                    onClick={onStartEditTimeWindows}
                    isDisabled={isDisabled || isDisableAnyActions}
                >
                    {t('dispatch-details.edit-time-windows.actions.specify-time-slots')}
                </Button>
            )}
        />
    );
});

export default StartEditTimeWindows;
