import * as React from 'react';
import { useMemo } from 'react';
import classNames from 'classnames/bind';

import styles from './TransportOrderDetailsCard.scss';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import Card from 'design-system/components/Card/Card';
import { useTranslation } from 'react-i18next';
import InfoTable, { InfoTableRowT } from 'design-system/components/InfoTable/InfoTable';
import CarrierIcon from 'common/icons/CarrierIcon';
import Link, { LinkThemeEnum } from 'common/components/Link/Link';
import TruckIcon from 'common/icons/TruckIcon';
import AssetLinkFormatter from 'design-system/components/InfoTable/formatters/AssetLinkFormatter/AssetLinkFormatter';
import AssetLabelFormatter from 'design-system/components/InfoTable/formatters/AssetLabelFormatter/AssetLabelFormatter';
import TrailerIcon from 'common/icons/TrailerIcon';
import { DispatchDetailsT } from 'broker-admin/store/dispatch-details/models';
import { urlFactory } from 'broker-admin/utils/urls';
import {
    ApiTransportationOrderFullT,
    ApiTransportOrderStatusT,
    PartnerTypeEnum,
    TransportOrderStatusEnum,
} from 'common/utils/api/models';
import { logWarning } from 'common/utils/logger';
import { CommonSidebarsTypeEnum } from 'common/layouts/SideBars/models';
import { useOpenLeftSidebar } from 'broker-admin/layouts/SideBars/hooks';
import DriverIcon from 'common/icons/DriverIcon';
import TeamDrive2Icon from 'common/icons/TeamDrive2Icon';
import DocIcon from 'common/icons/DocIcon';
import TransportOrderStatusPill from 'common/components/status-pill/TransportOrderStatusPill/TransportOrderStatusPill';
import CarrierCostPriceInfoTable from 'broker-admin/components/info-tables/CarrierCostPriceInfoTable/CarrierCostPriceInfoTable';
import SimpleDriverFormatter from 'design-system/components/InfoTable/formatters/SimpleDriverFormatter/SimpleDriverFormatter';
import ChangeTransportOrderStatusControl from 'broker-admin/layouts/DispatchesPage/DispatchDetailsPage/DispatchDetails/TransportOrderDetailsCard/ChangeTransportOrderStatusControl/ChangeTransportOrderStatusControl';
import { useSelector } from 'react-redux';
import { selectPermissions } from 'common/store/auth/selectors';

export type PropsT = {
    transportOrderNumber: string;
    tour: DispatchDetailsT['tours'][number] | null | undefined;
    transportOrderDetails: ApiTransportationOrderFullT | null | undefined;
    dispatchDetails: DispatchDetailsT | null | undefined;
    keyboardShortcut?: string;
    className?: string;
};

const cx = classNames.bind(styles);

const hideLoaderStatusSet = new Set<ApiTransportOrderStatusT | null | undefined>([
    TransportOrderStatusEnum.done,
    TransportOrderStatusEnum.declined,
    TransportOrderStatusEnum.canceled,
]);

const TransportOrderDetailsCard: React.FC<PropsT> = React.memo((props) => {
    const { transportOrderNumber, tour, transportOrderDetails, dispatchDetails, keyboardShortcut, className } = props;

    const { t } = useTranslation();

    const permissions = useSelector(selectPermissions);

    const carrier = transportOrderDetails?.carrier || null;
    const truck = transportOrderDetails?.truck || null;
    const trailer = transportOrderDetails?.trailer || null;

    const carrierLink = urlFactory.partnerDetails(PartnerTypeEnum.carrier, carrier?.id);

    const openLeftSidebar = useOpenLeftSidebar();

    const handleOpenTrailerDetails = () => {
        if (!trailer?.id || !carrier?.id) {
            logWarning('failed to open trailer details, empty trailer');
            return;
        }

        openLeftSidebar(
            {
                type: CommonSidebarsTypeEnum.trailerDetails,
                trailerId: trailer.id,
                partnerId: carrier.id,
            },
            {
                isForceShowBackAction: true,
            },
        );
    };

    const handleOpenTruckDetails = () => {
        if (!truck?.id || !carrier?.id) {
            logWarning('failed to open trailer details, empty truck');
            return;
        }

        openLeftSidebar(
            {
                type: CommonSidebarsTypeEnum.truckDetails,
                truckId: truck.id,
                partnerId: carrier.id,
            },
            {
                isForceShowBackAction: true,
            },
        );
    };

    const mainDriver = transportOrderDetails?.drivers?.[0] || null;

    const teamDrivers = useMemo(() => {
        const shouldShowTeamDrivers = !!transportOrderDetails?.teamDrive;
        if (!shouldShowTeamDrivers) {
            return [];
        }

        const drivers = transportOrderDetails?.drivers?.slice(1) || [];
        return drivers?.length ? drivers : [null];
    }, [transportOrderDetails]);

    const moveDetails: Array<InfoTableRowT | null> = [
        {
            icon: (
                <TruckIcon
                    size={DEFAULT_ICON_SIZE}
                    strokeColor={StyleGuideColorsEnum.gray}
                    fillColor={StyleGuideColorsEnum.light}
                />
            ),
            name: t('dispatch-details.transport-order-details.columns.truck'),
            value: truck ? (
                <AssetLinkFormatter assetId={truck?.id} onOpenAssetDetails={handleOpenTruckDetails}>
                    <AssetLabelFormatter model={truck?.dictTruckInfo?.model} plateNumber={truck?.plateNumber} />
                </AssetLinkFormatter>
            ) : null,
            emptyValue: t('common:info-table.placeholders.truck-not-assigned'),
            isBoldValue: true,
        },
        {
            icon: (
                <TrailerIcon
                    size={DEFAULT_ICON_SIZE}
                    strokeColor={StyleGuideColorsEnum.gray}
                    fillColor={StyleGuideColorsEnum.light}
                />
            ),
            name: t('dispatch-details.transport-order-details.columns.trailer'),
            value: trailer ? (
                <AssetLinkFormatter assetId={trailer?.id} onOpenAssetDetails={handleOpenTrailerDetails}>
                    <AssetLabelFormatter model={trailer?.dictTrailerInfo?.model} plateNumber={trailer?.plateNumber} />
                </AssetLinkFormatter>
            ) : null,
            emptyValue: t('common:info-table.placeholders.trailer-not-assigned'),
            isBoldValue: true,
        },
        {
            icon: <DriverIcon size={DEFAULT_ICON_SIZE} fillColor={StyleGuideColorsEnum.gray} />,
            name: t('dispatch-details.transport-order-details.columns.driver'),
            value: mainDriver ? <SimpleDriverFormatter driver={mainDriver} /> : null,
            emptyValue: t('common:info-table.placeholders.driver-not-assigned'),
            isBoldValue: true,
        },
        ...teamDrivers.map((driver) => ({
            icon: <TeamDrive2Icon size={DEFAULT_ICON_SIZE} strokeColor={StyleGuideColorsEnum.gray} />,
            name: t('dispatch-details.transport-order-details.columns.team-driver'),
            value: driver ? <SimpleDriverFormatter driver={driver} /> : null,
            emptyValue: t('common:info-table.placeholders.driver-not-assigned'),
            isBoldValue: true,
        })),
        {
            icon: <DocIcon strokeColor={StyleGuideColorsEnum.gray} />,
            name: t('dispatch-details.transport-order-details.columns.tour'),
            value: tour?.number,
            emptyValue: t('common:info-table.placeholders.assets-not-assigned'),
            isBoldValue: true,
        },
    ];

    const carrierDetails: Array<InfoTableRowT> = [
        {
            icon: <CarrierIcon size={DEFAULT_ICON_SIZE} strokeColor={StyleGuideColorsEnum.gray} />,
            name: t('dispatch-details.transport-order-details.columns.carrier'),
            value: carrier?.id ? (
                <Link to={carrierLink} className={cx('link')} theme={LinkThemeEnum.boldBrandDark}>
                    {carrier?.name}
                </Link>
            ) : null,
            emptyValue: t('common:info-table.placeholders.carrier-not-assigned'),
            isBoldValue: true,
        },
    ];

    return (
        <>
            <Card
                titleNode={t('dispatch-details.transport-order-details.title', {
                    number: transportOrderNumber,
                })}
                rightNode={
                    <>
                        <TransportOrderStatusPill isSymmetrical status={transportOrderDetails?.status} />
                        {permissions.canChangeTransportOrderStatusOnDispatch && (
                            <ChangeTransportOrderStatusControl
                                isShowLoading={
                                    !!transportOrderDetails && !hideLoaderStatusSet.has(transportOrderDetails?.status)
                                }
                                transportOrderId={transportOrderDetails?.id || null}
                            />
                        )}
                    </>
                }
                className={className}
                hasHeaderBottomBorder
                keyboardShortcut={keyboardShortcut}
            >
                <div className={cx('content')}>
                    <InfoTable shouldRenderIcons rows={carrierDetails} className={cx('table', 'table--carrier')} />
                    <InfoTable shouldRenderIcons rows={moveDetails} className={cx('table', 'table--move')} />
                    <CarrierCostPriceInfoTable
                        className={cx('table', 'table--carrier-cost')}
                        totalPrice={transportOrderDetails?.carrierCost?.totalCost}
                        totalPriceLabel={t('dispatch-details.transport-order-details.carrier-cost')}
                        lineHaulCost={transportOrderDetails?.carrierCost?.lineHaul}
                        tollCost={transportOrderDetails?.carrierCost?.toll?.totalCost}
                        tollByRoadType={transportOrderDetails?.carrierCost?.toll?.byRoadType}
                        co2={transportOrderDetails?.carrierCost?.co2}
                        layoverCost={transportOrderDetails?.carrierCost?.layover?.totalCost}
                        layoverSeconds={transportOrderDetails?.carrierCost?.layover?.totalSeconds}
                        fuelCost={transportOrderDetails?.carrierCost?.fuel?.totalCost}
                        fuelByCountry={transportOrderDetails?.carrierCost?.fuel?.byCountry}
                        teamDriveCost={transportOrderDetails?.carrierCost?.teamDriveCost}
                        otherCosts={transportOrderDetails?.carrierCost?.additionalServices?.totalCost}
                        deadheadLineHaulCost={transportOrderDetails?.carrierCost?.deadhead?.lineHaul}
                        payloadLineHaulCost={transportOrderDetails?.carrierCost?.payload?.lineHaul}
                    />
                </div>
            </Card>
        </>
    );
});

export default TransportOrderDetailsCard;
