import { BrokerAssetsSearchRequestInvalidReasonT } from 'common/utils/assignment';

export enum FieldsEnum {
    trailerId = 'trailerId',
    truckId = 'truckId',
    locationToDropTrailer = 'locationToDropTrailer',
    locationToDropTruck = 'locationToDropTruck',
}

export type FormValuesT = {
    [FieldsEnum.trailerId]: TrailerIdT | null;
    [FieldsEnum.truckId]: TruckIdT | null;

    [FieldsEnum.locationToDropTrailer]: LocationT | null;
    [FieldsEnum.locationToDropTruck]: LocationT | null;
};

export type FormErrorsT = Partial<Record<FieldsEnum, string>>;
export type AsyncFormErrorsT = Partial<Record<FieldsEnum, string>>;

export const reasonI18nMap: Record<BrokerAssetsSearchRequestInvalidReasonT, string> = {
    OTHER: 'assignment.validation.reasons.OTHER',
    CARRIER_NOT_IN_LIST: 'assignment.validation.reasons.CARRIER_NOT_IN_LIST',
    CARRIER_DOESNT_HAVE_REQUIRED_CERTIFICATES:
        'assignment.validation.reasons.CARRIER_DOESNT_HAVE_REQUIRED_CERTIFICATES',
    INCAPABLE_EMISSION_STANDARD: 'assignment.validation.reasons.INCAPABLE_EMISSION_STANDARD',
    CANT_BE_ON_TIME_FOR_NEXT_TOUR: 'assignment.validation.reasons.CANT_BE_ON_TIME_FOR_NEXT_TOUR',
    NO_AVAILABLE_HOOKS: 'assignment.validation.reasons.NO_AVAILABLE_HOOKS',
    DIFFERENT_CARRIERS: 'assignment.validation.reasons.DIFFERENT_CARRIERS',
    INCAPABLE_TRAILER_TYPE: 'assignment.validation.reasons.INCAPABLE_TRAILER_TYPE',
    ASSET_IS_NOT_CONTRACTED: 'assignment.validation.reasons.ASSET_IS_NOT_CONTRACTED',
    ASSET_CONTRACTED_INACTIVE: 'assignment.validation.reasons.ASSET_CONTRACTED_INACTIVE',
    PLATE_NUMBER_DO_NOT_MATCH: 'assignment.validation.reasons.PLATE_NUMBER_DO_NOT_MATCH',
    ASSET_BUSY_ON_TOUR_DATES: 'assignment.validation.reasons.ASSET_BUSY_ON_TOUR_DATES',
};
