import React from 'react';
import { RoutePointIdT, RoutePointTypeEnum } from 'broker-admin/store/order-edit/models';
import noop from 'lodash/noop';
import { BoundPointT } from 'common/components/maps/MapBound/MapBound';

export type MapRoutePointT = {
    id: RoutePointIdT;
    isNew: boolean;
    type: RoutePointTypeEnum;
    number: number;
    location: LocationT | null;
    isDraggable: boolean;
    hasError: boolean;
};

export type OnMapClickCallbackT = (location: LocationT | null) => void;
export type OnDragEndDnDCallbackT = (id: RoutePointIdT, location: LocationT | null) => void;
export type OnSetMapBoundingBoxCallbackT = (boundPoints: Array<BoundPointT> | null) => void;

export type SyncAssignmentFormMapStateContextValueT = {
    onSetMapBoundingBox: { callback: OnSetMapBoundingBoxCallbackT } | null;
    setOnSetMapBoundingBox: (params: { callback: OnSetMapBoundingBoxCallbackT }) => void;

    mapRoutePoints: Array<MapRoutePointT>;
    setMapRoutePoints: (routePoints: Array<MapRoutePointT>) => void;

    onMapClickCallback: { callback: OnMapClickCallbackT } | null;
    setOnMapClickCallback: (params: { callback: OnMapClickCallbackT }) => void;

    onDragEndDnDCallback: { callback: OnDragEndDnDCallbackT } | null;
    setOnDragEndDnDCallback: (params: { callback: OnDragEndDnDCallbackT }) => void;
};

const SyncRouteEditFormMapStateContext = React.createContext<SyncAssignmentFormMapStateContextValueT>({
    onSetMapBoundingBox: null,
    setOnSetMapBoundingBox: noop,

    mapRoutePoints: [],
    setMapRoutePoints: noop,

    onMapClickCallback: null,
    setOnMapClickCallback: noop,

    onDragEndDnDCallback: null,
    setOnDragEndDnDCallback: noop,
});

export { SyncRouteEditFormMapStateContext };
