import * as React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    className?: string;
    fillColor: StyleGuideColorsEnum;
};

const SplitIcon: React.FC<PropsT> = (props) => {
    const { fillColor, className } = props;

    return (
        <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" className={className}>
            <g fill="none" fillRule="evenodd">
                <path d="M1 1h18v18H1z" />
                <path
                    d="M16 4h-4.5l1.717 1.718-2.16 2.16 1.066 1.064 2.16-2.16L16 8.5V4zM4 4v4.5l1.718-1.718 3.532 3.525V16h1.5V9.693L6.782 5.718 8.5 4H4z"
                    fill={fillColor}
                />
            </g>
        </svg>
    );
};

SplitIcon.displayName = 'SplitIcon';

const storyProps: PropsT = {
    fillColor: StyleGuideColorsEnum.blazeOrange,
};

export { storyProps };
export default SplitIcon;
