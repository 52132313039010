import { ApiTourUpdateStopByRoutePointIdT, RoutePointIdByStopIndexT } from 'broker-admin/store/order-edit/models';
import isNumber from 'lodash/isNumber';
import { ApiEditOrderContextT } from 'broker-admin/utils/api/broker-tranziit/models';

export const prepareTourUpdateStopByRoutePointId = (
    context: ApiEditOrderContextT | null,
    routePointIdByStopIndex: RoutePointIdByStopIndexT,
): ApiTourUpdateStopByRoutePointIdT => {
    const result: ApiTourUpdateStopByRoutePointIdT = {};

    context?.tours?.forEach((tour) => {
        return tour?.stops?.forEach((stop) => {
            const { index } = stop;
            if (!isNumber(index)) {
                return;
            }

            const routePointId = routePointIdByStopIndex[index];
            if (!routePointId) {
                return;
            }

            result[routePointId] = stop;
        });
    });

    return result;
};
