import {
    ApiAdditionalServicesT,
    ApiBrokerAdditionalServiceT,
    ApiBrokerAdditionalServiceTypeT,
    ApiCarrierAdditionalServiceT,
    ApiCarrierAdditionalServiceTypeT,
} from 'common/utils/api/models';

export const checkIsCarrierAdditionalService = (
    value: NonNullable<ApiAdditionalServicesT['items']>[number] | null | undefined,
): value is ApiCarrierAdditionalServiceT => {
    return !value?.brokerService;
};

export const checkIsBrokerAdditionalService = (
    value: NonNullable<ApiAdditionalServicesT['items']>[number] | null | undefined,
): value is ApiBrokerAdditionalServiceT => {
    return !!value?.brokerService;
};

export const findAdditionalServiceByType = (
    additionalServices: ApiAdditionalServicesT | null | undefined,
    type: ApiCarrierAdditionalServiceTypeT | ApiBrokerAdditionalServiceTypeT,
) => {
    return additionalServices?.items?.find((additionalService) => {
        if (checkIsCarrierAdditionalService(additionalService)) {
            return additionalService.type === type;
        }

        if (checkIsBrokerAdditionalService(additionalService)) {
            return additionalService.type === type;
        }

        return false;
    });
};
