import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './CarrierDropdown.scss';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import DropdownSearchInput from 'design-system/components/dropdowns/DropdownSearchInput/DropdownSearchInput';
import CarrierWithUtilizationDropdownOption from './CarrierWithUtilizationDropdownOption/CarrierWithUtilizationDropdownOption';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectAvailableCarriers,
    selectAvailableCarriersRequest,
} from 'broker-admin/store/dispatch-assigment/selectors';
import { isNonNil } from 'common/utils';
import ControlLoaderIcon, { ControlLoaderIconProps } from 'common/icons/ControlLoaderIcon';
import { ALL_CARRIERS_ID } from 'common/constants';
import isEmpty from 'lodash/isEmpty';
import { fetchCarrierUtilization } from 'common/store/carriers-utilization/actions';
import { selectCarriersUtilizationByHash, selectFetchRequestStatus } from 'common/store/carriers-utilization/selectors';
import { getHash } from 'common/store/carriers-utilization/utils';
import { useTranslation } from 'react-i18next';
import { prepareSearchQuery } from 'common/utils/search';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
    dateForUtilizationRequest: string | null;
    carrierId: CarrierIdT;
    setCarrierId: (carrierId: CarrierIdT) => void;
};

export type CarrierOptionT = {
    name: string;
    utilizationPercent: number | null;
    id: CarrierIdT;
    isLoading: boolean;
};

const CarrierDropdown: React.FC<PropsT> = React.memo((props) => {
    const { className, dateForUtilizationRequest, carrierId, setCarrierId } = props;

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const carriersRequestStatus = useSelector(selectAvailableCarriersRequest);
    const carriers = useSelector(selectAvailableCarriers);

    React.useEffect(() => {
        const carrierIds = carriers.map((carrier) => carrier.id).filter(isNonNil);
        if (isEmpty(carrierIds) || !dateForUtilizationRequest) {
            return;
        }

        dispatch(fetchCarrierUtilization(carrierIds, dateForUtilizationRequest));
    }, [carriers, dateForUtilizationRequest]);

    const carriersUtilizationByHash = useSelector(selectCarriersUtilizationByHash);
    const carriersUtilizationFetchRequest = useSelector(selectFetchRequestStatus);

    const carriersOptions: CarrierOptionT[] = React.useMemo(() => {
        const options: CarrierOptionT[] = carriers
            .map((carrier) => {
                if (!carrier.id || !dateForUtilizationRequest) {
                    return null;
                }

                const hash = getHash(carrier.id, dateForUtilizationRequest);
                const carrierUtilization = carriersUtilizationByHash[hash];
                return {
                    name: carrier.name || '',
                    utilizationPercent: carrierUtilization ? carrierUtilization.utilizationPercents : null,
                    id: carrier.id || '',
                    isLoading: !carrierUtilization?.utilizationPercents && carriersUtilizationFetchRequest.loading,
                };
            })
            .filter(isNonNil);

        const allCarriersOption: CarrierOptionT = {
            name: t('assignment.search-form.all-carriers'),
            utilizationPercent: null,
            id: ALL_CARRIERS_ID,
            isLoading: false,
        };

        options.unshift(allCarriersOption);

        return options;
    }, [carriers, carriersUtilizationFetchRequest]);

    const [query, setQuery] = React.useState<string>('');

    const filteredCarriersOptions = React.useMemo(() => {
        const preparedQuery = prepareSearchQuery(query);
        return carriersOptions.filter((option) => {
            const label = option?.name || '';
            const preparedLabel = prepareSearchQuery(label);

            return preparedLabel.includes(preparedQuery);
        });
    }, [carriersOptions, query]);

    return (
        <DropdownSearchInput<CarrierOptionT, CarrierOptionT['id']>
            className={className}
            selectedValue={carrierId}
            options={filteredCarriersOptions}
            onSelect={setCarrierId}
            overlayPosition={DropdownOverlayPositionEnum.bottomRight}
            renderOption={(option) => {
                return (
                    <CarrierWithUtilizationDropdownOption
                        name={option.name}
                        isLoading={option.isLoading}
                        utilizationPercent={option.utilizationPercent}
                    />
                );
            }}
            renderTrigger={(option, placeholder) => {
                if (!option) {
                    return <span>{placeholder}</span>;
                }

                return (
                    <CarrierWithUtilizationDropdownOption
                        name={option.name}
                        isLoading={option.isLoading}
                        utilizationPercent={option.utilizationPercent}
                    />
                );
            }}
            renderRightIcon={() =>
                carriersRequestStatus.loading ? (
                    <ControlLoaderIcon {...ControlLoaderIconProps.getFetchDataProps()} />
                ) : null
            }
            onChangeQuery={setQuery}
            getOptionValue={(option) => option?.id}
        />
    );
});

export default CarrierDropdown;
