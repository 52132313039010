import * as React from 'react';
import { DictAdditionalServiceT } from 'common/store/additional-services-dict/models';
import AdditionalServiceLabel from 'common/components/AdditionalServiceLabel/AdditionalServiceLabel';
import { ApiBrokerAdditionalServiceTypeT, ApiCarrierAdditionalServiceTypeT } from 'common/utils/api/models';

type PropsT = {
    type?: DictAdditionalServiceT['enum'] | ApiCarrierAdditionalServiceTypeT | ApiBrokerAdditionalServiceTypeT;
};

const AdditionalServiceFormatter: React.FC<PropsT> = React.memo((props) => {
    const { type } = props;

    return <AdditionalServiceLabel type={type} />;
});

export default AdditionalServiceFormatter;
