import React from 'react';
import classNames from 'classnames/bind';

import styles from './StatisticFiltersSidebarContent.scss';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import { fetchTrailersDict } from 'common/store/trailers-dict/actions';
import { useDispatch } from 'react-redux';
import getQueryFilters from './get-query-filters';
import getInitialValues from './get-initial-values';
import { disableCompaniesDataSourceSet, FieldsEnum, FormValuesT } from './constants';
import SideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLayout';
import FooterSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/FooterSideBarLayout/FooterSideBarLayout';
import HeaderSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarLayout/HeaderSideBarLayout';
import Input from 'common/components/Input/Input';
import FormikField from 'common/components/forms/FormikField/FormikField';
import { isDeepEqual } from 'common/utils/deep-equal';
import HeaderSideBarContent from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarContent/HeaderSideBarContent';
import FieldGroup from 'common/components/FieldGroup/FieldGroup';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import ShipperSuggest from 'broker-admin/components/dropdowns/ShipperSuggest/ShipperSuggest';
import CarrierDropdown from 'broker-admin/components/dropdowns/CarrierDropdown/CarrierDropdown';
import CountryDropdown from 'common/components/dropdowns/CountryDropdown/CountryDropdown';
import DataSourceDropdown from 'broker-admin/layouts/StatisticsPage/DataSourceDropdown/DataSourceDropdown';
import { StatisticsFilterSidebarDataT } from './models';
import { SidebarContentPropsT } from 'common/layouts/SideBars/models';
import { BrokerSidebarDataT } from 'broker-admin/layouts/SideBars/models';
import { useQueryParam } from 'use-query-params';
import { createJsonParams } from 'common/utils/query';
import { QueryFiltersT, QueryKeysEnum } from 'broker-admin/layouts/StatisticsPage/query-models';
import SelectedFilters from 'broker-admin/layouts/StatisticsPage/SelectedFilters/SelectedFilters';
import { getOnlyQueryFilterChanges } from 'common/utils/query-filters';

const cx = classNames.bind(styles);

type PropsT = SidebarContentPropsT<StatisticsFilterSidebarDataT, BrokerSidebarDataT>;

const StatisticFiltersSidebarContent: React.FC<PropsT> = (props) => {
    const { onClose } = props;
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [queryFilters, setQueryFilters] = useQueryParam<QueryFiltersT>(
        QueryKeysEnum.statsFilters,
        createJsonParams<QueryFiltersT>({}),
    );

    React.useEffect(() => {
        dispatch(fetchTrailersDict());
    }, []);

    const [initialQueryFilters, setInitialQueryFilters] = React.useState<QueryFiltersT>(queryFilters);
    React.useEffect(() => {
        setInitialQueryFilters(queryFilters);
    }, [queryFilters]);

    const initialValues = React.useMemo(() => getInitialValues(queryFilters), [queryFilters]);

    const formik = useFormik<FormValuesT>({
        enableReinitialize: true,
        validateOnBlur: false,
        initialValues,
        onSubmit: (values, formikHelpers): void => {
            const queryFilters = getQueryFilters(values);

            const queryFilterChanges = getOnlyQueryFilterChanges(queryFilters, initialQueryFilters);

            setQueryFilters({
                ...initialQueryFilters,
                ...queryFilterChanges,
            });

            formikHelpers.setTouched({});

            if (onClose) {
                onClose();
            }
        },
    });

    const isSameValues = React.useMemo(() => {
        return isDeepEqual(initialValues, formik.values) && isDeepEqual(initialQueryFilters, queryFilters);
    }, [initialValues, formik.values, initialQueryFilters, queryFilters]);

    const selectedDataSource = formik.values[FieldsEnum.dataSource];
    const isDisableSelectCompanies = disableCompaniesDataSourceSet.has(selectedDataSource);
    React.useEffect(() => {
        if (disableCompaniesDataSourceSet.has(selectedDataSource)) {
            formik.setValues({
                ...formik.values,
                [FieldsEnum.carrierId]: null,
                [FieldsEnum.carrierName]: null,
                [FieldsEnum.shipperId]: null,
                [FieldsEnum.shipperName]: null,
            });
        }
    }, [selectedDataSource]);

    return (
        <form onSubmit={formik.handleSubmit} className={cx('form')}>
            <HeaderSideBarLayout>
                <HeaderSideBarContent title={t('statistics.filters.trigger')} onClose={onClose} />
            </HeaderSideBarLayout>
            <SideBarLayout>
                <FormikField
                    className={cx('field')}
                    name={FieldsEnum.dataSource}
                    error={formik.errors[FieldsEnum.dataSource]}
                    label={t('statistics.filters.fields.data-source.label')}
                    meta={formik.getFieldMeta(FieldsEnum.dataSource)}
                    setFieldValue={formik.setFieldValue}
                    setFieldTouched={formik.setFieldTouched}
                >
                    {(props) => (
                        <DataSourceDropdown
                            selectedValue={formik.values[FieldsEnum.dataSource]}
                            placeholder={t('statistics.filters.fields.data-source.placeholder')}
                            onSelect={props.onChange}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            hasError={props.hasError}
                            hasWarning={props.hasWarning}
                        />
                    )}
                </FormikField>
                <FieldGroup>
                    <FormikField
                        className={cx('field', 'field--from-country-code')}
                        name={FieldsEnum.fromCountryCode}
                        error={formik.errors[FieldsEnum.fromCountryCode]}
                        label={t('statistics.filters.fields.from-country-code.label')}
                        meta={formik.getFieldMeta(FieldsEnum.fromCountryCode)}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <CountryDropdown
                                placeholder={t('statistics.filters.fields.from-country-code.placeholder')}
                                value={formik.values[FieldsEnum.fromCountryCode]}
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                onReset={() => {
                                    props.onChange(null);
                                }}
                            />
                        )}
                    </FormikField>
                    <FormikField
                        className={cx('field', 'field--to-country-code')}
                        name={FieldsEnum.toCountryCode}
                        error={formik.errors[FieldsEnum.toCountryCode]}
                        label={t('statistics.filters.fields.to-country-code.label')}
                        meta={formik.getFieldMeta(FieldsEnum.toCountryCode)}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <CountryDropdown
                                placeholder={t('statistics.filters.fields.to-country-code.placeholder')}
                                value={formik.values[FieldsEnum.toCountryCode]}
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                onReset={() => {
                                    props.onChange(null);
                                }}
                            />
                        )}
                    </FormikField>
                </FieldGroup>
                <FieldGroup>
                    <FormikField
                        className={cx('field', 'field--from-zip-code')}
                        name={FieldsEnum.fromZipCode}
                        label={t('statistics.filters.fields.from-zip-code.label')}
                        error={formik.errors[FieldsEnum.fromZipCode]}
                        meta={formik.getFieldMeta(FieldsEnum.fromZipCode)}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <Input
                                name={FieldsEnum.fromZipCode}
                                value={formik.values[FieldsEnum.fromZipCode]}
                                placeholder={t('statistics.filters.fields.from-zip-code.placeholder')}
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                hasError={props.hasError}
                                hasWarning={props.hasWarning}
                                hasClearControl
                            />
                        )}
                    </FormikField>
                    <FormikField
                        className={cx('field', 'field--to-zip-code')}
                        name={FieldsEnum.toZipCode}
                        label={t('statistics.filters.fields.to-zip-code.label')}
                        error={formik.errors[FieldsEnum.toZipCode]}
                        meta={formik.getFieldMeta(FieldsEnum.toZipCode)}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <Input
                                name={FieldsEnum.toZipCode}
                                value={formik.values[FieldsEnum.toZipCode]}
                                placeholder={t('statistics.filters.fields.to-zip-code.placeholder')}
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                hasError={props.hasError}
                                hasWarning={props.hasWarning}
                                hasClearControl
                            />
                        )}
                    </FormikField>
                </FieldGroup>
                <FieldGroup>
                    <FormikField
                        className={cx('field', 'field--shipper')}
                        name={FieldsEnum.shipperId}
                        error={formik.errors[FieldsEnum.shipperId]}
                        meta={formik.getFieldMeta(FieldsEnum.shipperId)}
                        label={t('statistics.filters.fields.shipper.label')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <ShipperSuggest
                                value={formik.values[FieldsEnum.shipperId]}
                                initialValue={initialValues[FieldsEnum.shipperId]}
                                initialLabel={initialValues[FieldsEnum.shipperName] || ''}
                                hasClearControl
                                isDisabled={isDisableSelectCompanies}
                                onSelect={(shipperId, shipperName) => {
                                    formik.setFieldValue(FieldsEnum.shipperId, shipperId);
                                    formik.setFieldValue(FieldsEnum.shipperName, shipperName);
                                }}
                                hasWarning={props.hasWarning}
                                hasError={props.hasError}
                                overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
                                placeholder={t('statistics.filters.fields.shipper.placeholder')}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                            />
                        )}
                    </FormikField>
                    <FormikField
                        className={cx('field', 'field--carrier')}
                        name={FieldsEnum.carrierId}
                        error={formik.errors[FieldsEnum.carrierId]}
                        meta={formik.getFieldMeta(FieldsEnum.carrierId)}
                        label={t('statistics.filters.fields.carrier.label')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <CarrierDropdown
                                value={formik.values[FieldsEnum.carrierId]}
                                initialValue={initialValues[FieldsEnum.carrierId]}
                                initialLabel={initialValues[FieldsEnum.carrierName] || ''}
                                hasClearControl
                                isDisabled={isDisableSelectCompanies}
                                onSelect={(carrierId, carrierName) => {
                                    formik.setFieldValue(FieldsEnum.carrierId, carrierId);
                                    formik.setFieldValue(FieldsEnum.carrierName, carrierName);
                                }}
                                hasWarning={props.hasWarning}
                                hasError={props.hasError}
                                overlayPosition={DropdownOverlayPositionEnum.bottomRight}
                                placeholder={t('statistics.filters.fields.carrier.placeholder')}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                            />
                        )}
                    </FormikField>
                </FieldGroup>
            </SideBarLayout>
            <FooterSideBarLayout hasPaddings>
                <div className={cx('selected-filters')}>
                    <SelectedFilters queryFilters={initialQueryFilters} setQueryFilters={setInitialQueryFilters} />
                </div>
                <Button
                    theme={ButtonThemeEnum.primary}
                    isDisabled={isSameValues}
                    className={cx('button')}
                    type="submit"
                >
                    {t('statistics.filters.submit')}
                </Button>
            </FooterSideBarLayout>
        </form>
    );
};

export default StatisticFiltersSidebarContent;
