import * as React from 'react';
import { useMemo, useState } from 'react';

import classNames from 'classnames/bind';

import styles from './DispatchAssignmentPage.scss';

import { useParams } from 'react-router-dom';
import useGoogleMaps from 'common/utils/hooks/useGoogleMaps';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import history, { goBackIfHasRePath } from 'common/utils/history';
import GoogleMapContext from 'common/contexts/google-map-context';
import HorizontalColumnsLayout from 'common/layouts/HorizontalColumnsLayout/HorizontalColumnsLayout';
import HeaderSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarLayout/HeaderSideBarLayout';
import HeaderSideBarContent from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarContent/HeaderSideBarContent';
import SideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLayout';
import {
    selectDispatchDetails,
    selectDispatchDetailsRequestStatus,
} from 'broker-admin/store/dispatch-details/selectors';
import { fetchDispatchDetails } from 'broker-admin/store/dispatch-details/actions';
import AssignmentMap from './AssignmentMap/AssignmentMap';
import { initDispatchAssignment, resetDispatchAssignment } from 'broker-admin/store/dispatch-assigment/actions';
import { resetCarrierUtilization } from 'common/store/carriers-utilization/actions';
import {
    selectAssignmentRequestStatus,
    selectShowSelectedAssetSchedules,
} from 'broker-admin/store/dispatch-assigment/selectors';
import SideBarLayoutContextWrap from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLayoutContextWrap/SideBarLayoutContextWrap';
import HeaderSideBarLoader from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarLoader/HeaderSideBarLoader';
import EntitySidebarSkeleton from 'common/components/order-details/EntitySidebarSkeleton/EntitySidebarSkeleton';
import SideBarLoader from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLoader/SideBarLoader';
import PageTitle from 'common/components/PageTitle/PageTitle';
import RightSidebarLayout from 'common/layouts/RightSidebarLayout/RightSidebarLayout';
import AssignmentAssetSchedules from './AssignmentAssetSchedules/AssignmentAssetSchedules';
import SideBars from 'broker-admin/layouts/SideBars/SideBars';
import TimeZoneContext, { TimeZoneContextT, TimeZoneContextValueT, TimeZoneEnum } from './contexts/timezone-context';
import { urlFactory } from 'broker-admin/utils/urls';
import AssignmentForm from 'broker-admin/layouts/DispatchesPage/DispatchAssignmentPage/AssignmentForm/AssignmentForm';
import { fetchCountriesDict } from 'common/store/countries-dict/actions';
import { useChannelSubscribe } from 'common/utils/hooks/useChannelSubscribe';
import { stateMachineRefreshChannel } from 'common/store/state-machine/channels';
import AssetsSelectionContext, {
    AssetsSelectionContextT,
    AssetsSelectionContextValueT,
} from 'broker-admin/layouts/DispatchesPage/DispatchAssignmentPage/contexts/assets-selection-context';
import { SyncAssignmentFormMapStateContext } from './contexts/sync-map-state';
import { usySyncMapState } from 'broker-admin/layouts/DispatchesPage/DispatchAssignmentPage/hooks/usySyncMapState';
import { DispatchesRoutesEnum } from 'broker-admin/constants';
import { ExtractRouterRouteParamsT } from 'common/utils/extract-router-route-params-t';
import useOnlyChangesEffect from 'common/utils/hooks/useOnlyChangesEffect';

const cx = classNames.bind(styles);

type PropsT = {};

const DispatchAssignmentPage: React.FC<PropsT> = React.memo((props) => {
    const params = useParams<ExtractRouterRouteParamsT<DispatchesRoutesEnum.dispatchAssignment>>();
    const { dispatchId, tourId } = params;

    const mapStateContextValue = usySyncMapState();
    const [timeZoneContextValue, setTimeZoneContextValue] = useState<TimeZoneContextValueT>({
        utcOffsetMin: null,
        timeZone: TimeZoneEnum.accountTimezone,
    });

    const timeZoneContext = useMemo((): TimeZoneContextT => {
        return {
            value: timeZoneContextValue,
            setValue: setTimeZoneContextValue,
        };
    }, [timeZoneContextValue, setTimeZoneContextValue]);

    const [assetsSelectionContextValue, setAssetsSelectionContextValue] = useState<AssetsSelectionContextValueT>({
        isBlockedSelectTruck: false,
        isBlockedSelectTrailer: false,
    });

    const assetsSelectionContext = useMemo((): AssetsSelectionContextT => {
        return {
            value: assetsSelectionContextValue,
            setValue: setAssetsSelectionContextValue,
        };
    }, [assetsSelectionContextValue, setAssetsSelectionContextValue]);

    const googleMaps = useGoogleMaps();
    const googleMapsContextValue = React.useMemo(() => {
        return {
            googleMaps,
        };
    }, [googleMaps]);

    const { t } = useTranslation();

    const dispatchDetails = useSelector(selectDispatchDetails(dispatchId));
    const dispatchDetailsRequestStatus = useSelector(selectDispatchDetailsRequestStatus(dispatchId));

    const dispatch = useDispatch();

    React.useEffect(() => {
        if (dispatchDetails) {
            return;
        }

        if (dispatchId) {
            dispatch(fetchDispatchDetails(dispatchId));
        }
    }, [dispatchDetails, dispatchId]);

    const refreshDispatchHandler = React.useCallback(() => {
        if (dispatchId) {
            dispatch(fetchDispatchDetails(dispatchId));
        }
    }, [dispatchId]);
    useChannelSubscribe(stateMachineRefreshChannel, refreshDispatchHandler);

    const handleClose = () => {
        goBackIfHasRePath(() => {
            history.push(urlFactory.dispatchDetails(dispatchId || '-'));
        });
    };

    React.useEffect(() => {
        dispatch(initDispatchAssignment());
        dispatch(fetchCountriesDict());

        return () => {
            dispatch(resetCarrierUtilization());
            dispatch(resetDispatchAssignment());
        };
    }, []);

    const assignmentRequestStatus = useSelector(selectAssignmentRequestStatus);
    useOnlyChangesEffect(() => {
        if (assignmentRequestStatus.ok) {
            handleClose();
            dispatch(resetCarrierUtilization());
            dispatch(resetDispatchAssignment());
            // reset
        }
    }, [assignmentRequestStatus.ok]);

    const isShowSelectedAssetSchedules = useSelector(selectShowSelectedAssetSchedules);

    const isShowLineLoader = dispatchDetailsRequestStatus.loading && !!dispatchDetails;
    const isShowOverlayLoader = !dispatchDetailsRequestStatus.error && !dispatchDetails;

    return (
        <SyncAssignmentFormMapStateContext.Provider value={mapStateContextValue}>
            <AssetsSelectionContext.Provider value={assetsSelectionContext}>
                <TimeZoneContext.Provider value={timeZoneContext}>
                    <GoogleMapContext.Provider value={googleMapsContextValue}>
                        <PageTitle
                            title={t('page-titles.dispatch-assignment', {
                                number: dispatchDetails?.number || '',
                            })}
                        />
                        <HorizontalColumnsLayout
                            testSelector="dispatch-assignment"
                            className={cx('wrap')}
                            leftColumnTitle={t('dispatch-details.main-tabs.details')}
                            leftColumnNode={
                                <SideBarLayoutContextWrap>
                                    <HeaderSideBarLayout>
                                        <HeaderSideBarContent title={t('assignment.title')} onClose={handleClose} />
                                        <HeaderSideBarLoader isShow={isShowLineLoader} />
                                    </HeaderSideBarLayout>
                                    <SideBarLayout>
                                        {dispatchDetails ? (
                                            <AssignmentForm
                                                dispatchId={dispatchId}
                                                dispatchDetails={dispatchDetails}
                                                tourId={tourId}
                                                onCancel={handleClose}
                                            />
                                        ) : (
                                            <EntitySidebarSkeleton />
                                        )}
                                    </SideBarLayout>
                                    {isShowOverlayLoader && <SideBarLoader />}
                                </SideBarLayoutContextWrap>
                            }
                            rightColumnTitle={t('dispatch-details.main-tabs.info')}
                            rightColumnNode={
                                <div className={cx('tabs-wrap')}>
                                    <div className={cx('tab')}>
                                        <AssignmentMap
                                            key={dispatchId}
                                            dispatchId={dispatchId}
                                            dispatchDetails={dispatchDetails}
                                            tourId={tourId}
                                        />
                                    </div>
                                </div>
                            }
                        />
                    </GoogleMapContext.Provider>
                    {isShowSelectedAssetSchedules && (
                        <RightSidebarLayout>
                            <AssignmentAssetSchedules tourId={tourId} dispatchDetails={dispatchDetails} />
                        </RightSidebarLayout>
                    )}
                    <SideBars />
                </TimeZoneContext.Provider>
            </AssetsSelectionContext.Provider>
        </SyncAssignmentFormMapStateContext.Provider>
    );
});

export default DispatchAssignmentPage;
