import * as React from 'react';

import cs from 'classnames';
import classNames from 'classnames/bind';
import styles from './AssetPin.scss';
import { CurrencyEnum, DEFAULT_ICON_SIZE, StyleGuideColorsEnum, UnitTypeEnum } from 'common/constants';
import TrailerIcon, { getTrailerIconProps } from 'common/icons/TrailerIcon';
import TruckIcon, { getTruckIconProps } from 'common/icons/TruckIcon';
import MapTooltip from 'common/components/maps/MapTooltip/MapTooltip';
import { useTranslation } from 'react-i18next';
import isNil from 'lodash/isNil';

import ControlLoaderIcon from 'common/icons/ControlLoaderIcon';
import getUtilizationColor from 'common/utils/get-utilization-color';
import AssetLinkIcon, { getAssetLinkIconProps } from 'common/icons/AssetLinkIcon';
import { getAssignmentIconPreset } from '../utils/get-assignment-icon-preset';
import { PartnerT } from 'common/store/carrier-suggest/models';
import CarrierIcon from 'common/icons/CarrierIcon';
import UnitTypeCount from 'common/components/units/UnitTypeCount/UnitTypeCount';
import Money from 'common/components/Money/Money';
import isNumber from 'lodash/isNumber';
import { formatDateWithTimeZone, formatTimeZone } from 'common/utils/time';
import { MapPinThemeEnum } from 'common/components/maps/MapPin/MapPin';
import MapTooltipMenu, { MapTooltipMenuItemT } from 'common/components/maps/MapTooltipMenu/MapTooltipMenu';
import { BrokerAssetsSearchRequestInvalidReasonT } from 'common/utils/assignment';
import { reasonI18nMap } from 'broker-admin/layouts/DispatchesPage/DispatchAssignmentPage/AssignmentForm/constants';

const cx = classNames.bind(styles);

type PropsT = {
    lat: number;
    lng: number;
    className?: string;
    utcOffset?: number | null;
    arrivalDate?: string | null;
    plateNumber?: string | null;
    truckId?: string | null;
    isLoadingTruckUtilization: boolean;
    truckUtilization: number | null | undefined;
    truckRatePerKm?: number;
    truckInvalidReason: BrokerAssetsSearchRequestInvalidReasonT | null | undefined;
    isLoadingTruckCarrierUtilization: boolean;
    truckCarrierUtilizationPercent: number | null;
    truckCarrier: PartnerT | null;
    isSelectedTruck: boolean;
    trailerId?: string | null;
    isLoadingTrailerUtilization: boolean;
    trailerUtilization: number | null | undefined;
    trailerRatePerKm?: number;
    trailerInvalidReason: BrokerAssetsSearchRequestInvalidReasonT | null | undefined;
    isLoadingTrailerCarrierUtilization: boolean;
    trailerCarrierUtilizationPercent: number | null;
    trailerCarrier: PartnerT | null;
    isSelectedTrailer: boolean;
    onClick: (truckId: string | null | undefined, trailerId: string | null | undefined) => void;
    onHover: (truckId: string | null | undefined, trailerId: string | null | undefined) => void;
    canInTime: boolean;
    isBlockedSelectTruck?: boolean;
    isBlockedSelectTrailer?: boolean;
};

type UtilizationPropsT = {
    isLoading: boolean;
    utilization: number | null | undefined;
};

const Utilization: React.FC<UtilizationPropsT> = (props) => {
    const { isLoading, utilization } = props;

    if (isNil(utilization) && isLoading) {
        return <ControlLoaderIcon fillColor={StyleGuideColorsEnum.brandAccent} size={DEFAULT_ICON_SIZE} />;
    }

    if (!isNumber(utilization)) {
        return null;
    }

    return (
        <div
            className={cx('utilization')}
            style={{
                color: getUtilizationColor(utilization || 0),
            }}
        >
            {isNumber(utilization) ? `${utilization}%` : ''}
        </div>
    );
};

const getMapPinTheme = ({ canInTime, isSelected }: { canInTime: boolean; isSelected: boolean }): MapPinThemeEnum => {
    if (isSelected) {
        return MapPinThemeEnum.charcoal;
    }

    if (!canInTime) {
        return MapPinThemeEnum.light;
    }

    return MapPinThemeEnum.slate;
};

const AssetPin: React.FC<PropsT> = React.memo((props) => {
    const {
        lat,
        lng,
        className,
        truckId,
        trailerId,
        utcOffset,
        arrivalDate,
        plateNumber,
        onClick,
        onHover,
        isLoadingTrailerUtilization,
        trailerUtilization,
        trailerRatePerKm,
        trailerInvalidReason,
        isSelectedTrailer,
        isLoadingTruckUtilization,
        truckUtilization,
        truckRatePerKm,
        truckInvalidReason,
        isSelectedTruck,
        isLoadingTruckCarrierUtilization,
        truckCarrier,
        truckCarrierUtilizationPercent,
        isLoadingTrailerCarrierUtilization,
        trailerCarrier,
        trailerCarrierUtilizationPercent,
        canInTime,
        isBlockedSelectTruck,
        isBlockedSelectTrailer,
    } = props;

    const { t } = useTranslation();

    const [isShowAssetLinkMenu, setIsShowAssetLinkMenu] = React.useState(false);

    const linkAssetItems = React.useMemo((): Array<MapTooltipMenuItemT> => {
        const isDisabledTruck = isBlockedSelectTruck || !!truckInvalidReason;
        let truckTooltipContent: React.ReactNode = null;
        if (truckInvalidReason) {
            truckTooltipContent = t(reasonI18nMap[truckInvalidReason]);
        }
        if (isBlockedSelectTruck) {
            truckTooltipContent = t('assignment.actions.tooltips.blocked-assign-truck');
        }

        const isDisabledTrailer = isBlockedSelectTrailer || !!trailerInvalidReason;
        let trailerTooltipContent: React.ReactNode = null;
        if (trailerInvalidReason) {
            trailerTooltipContent = t(reasonI18nMap[trailerInvalidReason]);
        }
        if (isBlockedSelectTrailer) {
            trailerTooltipContent = t('assignment.actions.tooltips.blocked-assign-trailer');
        }

        const isDisabledTrain = isDisabledTruck || isDisabledTrailer;

        return [
            {
                label: t('assignment.actions.assign-full-train'),
                onSelect: () => {
                    setIsShowAssetLinkMenu(false);
                    onClick(truckId, trailerId);
                },
                isDisabled: isDisabledTrain,
                tooltipContent: isDisabledTrain ? t('assignment.actions.tooltips.blocked-assign-full-train') : null,
                testSelector: 'full-train',
            },
            {
                label: t('assignment.actions.select-truck-only'),
                onSelect: () => {
                    setIsShowAssetLinkMenu(false);
                    onClick(truckId, null);
                },
                isDisabled: isDisabledTruck,
                tooltipContent: isDisabledTruck ? truckTooltipContent : null,
                testSelector: 'only-truck',
            },
            {
                label: t('assignment.actions.select-trailer-only'),
                onSelect: () => {
                    setIsShowAssetLinkMenu(false);
                    onClick(null, trailerId);
                },
                isDisabled: isDisabledTrailer,
                tooltipContent: isDisabledTrailer ? trailerTooltipContent : null,
                testSelector: 'only-trailer',
            },
        ];
    }, [t, isBlockedSelectTruck, isBlockedSelectTrailer, onClick, truckId, trailerId, setIsShowAssetLinkMenu]);

    let formattedArrivalDate: React.ReactNode;
    if (arrivalDate) {
        formattedArrivalDate = t('assignment.asset-arrival', {
            date: formatDateWithTimeZone(arrivalDate, utcOffset, 'D MMM'),
            plateNumber,
        });
    } else if (arrivalDate === null) {
        formattedArrivalDate = t('assignment.asset-current-position', {
            plateNumber,
        });
    }

    const handleOutsideClick = () => {
        setIsShowAssetLinkMenu(false);
    };

    const handleClick = () => {
        const isLink = truckId && trailerId;
        if (isLink) {
            setIsShowAssetLinkMenu(true);
            return;
        }

        onClick(truckId, trailerId);
    };

    const [isHover, setHover] = React.useState<boolean>(false);
    const handleHover = (isHover: boolean) => {
        setHover(isHover);

        if (!isHover) {
            setIsShowAssetLinkMenu(false);
        }

        if (isHover) {
            onHover(truckId, trailerId);
        }
    };

    const truckIconPreset = getAssignmentIconPreset({
        isSelected: isSelectedTruck,
        canInTime,
    });

    const trailerIconPreset = getAssignmentIconPreset({
        isSelected: isSelectedTrailer,
        canInTime,
    });

    const linkIconPreset = getAssignmentIconPreset({
        isSelected: isSelectedTruck || isSelectedTrailer,
        canInTime,
    });

    const isSelected = isSelectedTruck || isSelectedTrailer;

    const mapPinTheme = getMapPinTheme({
        canInTime,
        isSelected,
    });

    const isSelectedTruckAndTrailers = truckId && trailerId;
    const isNotSameCarriers = truckCarrier?.id && trailerCarrier?.id && truckCarrier?.id !== trailerCarrier?.id;
    const isSameCarriers = truckCarrier?.id && trailerCarrier?.id && truckCarrier?.id === trailerCarrier?.id;

    const renderCarrierWithUtilization = (
        carrierName: string,
        isLoadingCarrierUtilization: boolean,
        carrierUtilizationPercent: number | null | undefined,
    ) => {
        return (
            <span className={cx('carrier')}>
                <CarrierIcon
                    size={DEFAULT_ICON_SIZE}
                    strokeColor={
                        mapPinTheme === MapPinThemeEnum.light ? StyleGuideColorsEnum.slate : StyleGuideColorsEnum.white
                    }
                />
                <span className={cx('carrier__name')}>{carrierName}</span>
                {isLoadingCarrierUtilization && (
                    <ControlLoaderIcon fillColor={StyleGuideColorsEnum.brandAccent} size={DEFAULT_ICON_SIZE} />
                )}
                {!isLoadingCarrierUtilization && !isNil(carrierUtilizationPercent) && (
                    <span
                        style={{
                            color: getUtilizationColor(carrierUtilizationPercent),
                        }}
                    >
                        <UnitTypeCount type={UnitTypeEnum.percentsAbbreviation} count={carrierUtilizationPercent} />
                    </span>
                )}
            </span>
        );
    };

    return (
        <MapTooltip
            hasPaddings
            mapLayers={{
                regular: isSelected ? 2 : 1,
                hovered: 3,
            }}
            className={cx('pin', { isSelected })}
            contentClassName={cs(
                cx('asset-tooltip', {
                    'asset-tooltip--isDarkText': mapPinTheme === MapPinThemeEnum.light,
                }),
                className,
            )}
            lat={lat}
            lng={lng}
            onClick={handleClick}
            isHover={isHover}
            onHover={handleHover}
            theme={mapPinTheme}
            footerNode={
                isShowAssetLinkMenu && isHover ? (
                    <MapTooltipMenu
                        className={cx('footer')}
                        items={linkAssetItems}
                        onOutsideClick={handleOutsideClick}
                    />
                ) : null
            }
        >
            {() => (
                <>
                    {isHover && !!formattedArrivalDate && (
                        <div className={cx('row')}>
                            <div className={cx('arrival')} title={formatTimeZone(utcOffset)}>
                                {formattedArrivalDate}
                            </div>
                        </div>
                    )}
                    {isHover && (
                        <div className={cx('row')}>
                            {!isSelectedTruckAndTrailers &&
                                !!truckId &&
                                renderCarrierWithUtilization(
                                    truckCarrier?.name || '',
                                    isLoadingTruckUtilization,
                                    truckUtilization,
                                )}
                            {!isSelectedTruckAndTrailers &&
                                !!trailerId &&
                                renderCarrierWithUtilization(
                                    trailerCarrier?.name || '',
                                    isLoadingTrailerCarrierUtilization,
                                    trailerUtilization,
                                )}
                            {isSelectedTruckAndTrailers &&
                                isSameCarriers &&
                                renderCarrierWithUtilization(
                                    truckCarrier?.name || '',
                                    isLoadingTruckUtilization,
                                    truckUtilization,
                                )}
                            {isSelectedTruckAndTrailers &&
                                isNotSameCarriers &&
                                renderCarrierWithUtilization(
                                    t('assignment.utilization.truck-carrier'),
                                    isLoadingTruckUtilization,
                                    truckUtilization,
                                )}
                            {isSelectedTruckAndTrailers &&
                                isNotSameCarriers &&
                                renderCarrierWithUtilization(
                                    t('assignment.utilization.trailer-carrier'),
                                    isLoadingTrailerCarrierUtilization,
                                    trailerUtilization,
                                )}
                        </div>
                    )}
                    <div className={cx('row')}>
                        {((!isHover && truckId && !trailerId) || (isHover && truckId)) && (
                            <>
                                <TruckIcon className={cx('icon')} {...getTruckIconProps(truckIconPreset)} />
                                {isHover && (
                                    <Utilization isLoading={isLoadingTruckUtilization} utilization={truckUtilization} />
                                )}
                            </>
                        )}
                        {isHover && truckId && isNumber(truckRatePerKm) && (
                            <Money amount={truckRatePerKm} currency={CurrencyEnum.EUR} className={cx('price')} />
                        )}
                        {((!isHover && !truckId && trailerId) || (isHover && trailerId)) && (
                            <>
                                <TrailerIcon className={cx('icon')} {...getTrailerIconProps(trailerIconPreset)} />
                                {isHover && (
                                    <Utilization
                                        isLoading={isLoadingTrailerUtilization}
                                        utilization={trailerUtilization}
                                    />
                                )}
                            </>
                        )}
                        {isHover && trailerId && isNumber(trailerRatePerKm) && (
                            <Money
                                amount={trailerRatePerKm}
                                currency={CurrencyEnum.EUR}
                                className={cx('price', 'price--isLast')}
                            />
                        )}
                        {!isHover && truckId && trailerId && (
                            <AssetLinkIcon className={cx('icon')} {...getAssetLinkIconProps(linkIconPreset)} />
                        )}
                    </div>
                </>
            )}
        </MapTooltip>
    );
});

export default AssetPin;
