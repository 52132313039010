import * as React from 'react';

import { useSelector } from 'react-redux';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import ControlLoaderIcon from 'common/icons/ControlLoaderIcon';
import { SEC_IN_HOUR, SEC_IN_MIN } from 'common/utils/time';
import { convertToKm, M_IN_KM } from 'common/utils/distance';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { formatThousandNumber } from 'common/utils/formatters';
import {
    selectCalculateCostRequest,
    selectAveragePriceOffer,
} from 'common/store/shipper-contract-lane-creation/selectors';

type PropsT = {
    className?: string;
};

const formatDistance = (t: TFunction, distanceM: number | undefined): React.ReactNode => {
    if (distanceM === undefined) {
        return null;
    }

    if (distanceM <= M_IN_KM) {
        return t('common:short-meters', {
            value: formatThousandNumber(Math.round(distanceM)),
        });
    }

    return t('common:short-kilometers', {
        value: formatThousandNumber(convertToKm(distanceM)),
    });
};

const formatDuration = (t: TFunction, durationSec: number | undefined): React.ReactNode => {
    if (durationSec === undefined) {
        return null;
    }

    if (durationSec < SEC_IN_HOUR) {
        const roundedDuration = Math.round(durationSec / SEC_IN_MIN);
        return `${roundedDuration} ${t('common:minutes', {
            postProcess: 'interval',
            count: roundedDuration,
        })}`;
    }

    const roundedDuration = Math.round(durationSec / SEC_IN_HOUR);
    return `${roundedDuration} ${t('common:hours', {
        postProcess: 'interval',
        count: Math.round(durationSec / SEC_IN_HOUR),
    })}`;
};

const Estimate: React.FC<PropsT> = React.memo((props) => {
    const { className } = props;

    const calculateCostRequest = useSelector(selectCalculateCostRequest);
    const averagePriceOffer = useSelector(selectAveragePriceOffer);

    const { t } = useTranslation();

    if (!calculateCostRequest.ok && !calculateCostRequest.loading) {
        return null;
    }

    return (
        <div className={className}>
            {calculateCostRequest.ok && (
                <span>
                    {formatDistance(t, averagePriceOffer?.distances?.totalDistance)}
                    {' / ~'}
                    {formatDuration(t, averagePriceOffer?.travelSeconds)}
                </span>
            )}
            {calculateCostRequest.loading && (
                <ControlLoaderIcon fillColor={StyleGuideColorsEnum.charcoal} size={DEFAULT_ICON_SIZE} />
            )}
        </div>
    );
});

export default Estimate;
