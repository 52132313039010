import { DispatchDetailsT } from 'broker-admin/store/dispatch-details/models';
import { findTourById } from 'broker-admin/store/dispatch-details/utils/find-tour-by-id';
import { StopTypeEnum } from 'common/utils/api/models';

export const getPickupDeliveryWaypoints = (
    dispatchDetails: DispatchDetailsT | null | undefined,
    tourId: TourIdT | null,
) => {
    const actualTour = findTourById(dispatchDetails, tourId);

    return (
        actualTour?.waypoints?.filter((waypoint) => {
            return (
                waypoint?.type === StopTypeEnum.pickupDeliveryShipment || waypoint?.type === StopTypeEnum.dropAndHook
            );
        }) || []
    );
};
