import { StateT } from '../models';

export const selectOrderEditCreateContextRequest = (state: StateT) => {
    return state.orderEdit.createContextRequestStatus;
};

export const selectOrderEditDeleteContextRequest = (state: StateT) => {
    return state.orderEdit.deleteContextRequestStatus;
};

export const selectOrderEditApplyContextRequest = (state: StateT) => {
    return state.orderEdit.applyContextRequestStatus;
};

export const selectOrderEditContext = (state: StateT) => {
    return state.orderEdit.context;
};

export const selectOrderEditContextTourUpdateStopByRoutePointId = (state: StateT) => {
    return state.orderEdit.contextTourUpdateStopByRoutePointId;
};

export const selectOrderEditDispatchDetails = (state: StateT) => {
    return state.orderEdit.dispatchDetails;
};

export const selectIsApplyingContextChanges = (state: StateT) => {
    return state.orderEdit.isApplyingContextChanges;
};

export const selectOrderEditFakeSessionId = (state: StateT) => {
    return state.orderEdit.fakeSessionId;
};

export const selectChangeContextError = (state: StateT) => {
    return state.orderEdit.changeContextError;
};
