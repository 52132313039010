import * as React from 'react';

import cs from 'classnames';
import classNames from 'classnames/bind';

import styles from './RuleImpact.scss';
import { RuleImpactStrategyEnum, UnitTypeEnum } from 'common/constants';
import UnitTypeCount from 'common/components/units/UnitTypeCount/UnitTypeCount';

type PropsT = {
    className?: string;
    value?: number;
    impactStrategy?: `${RuleImpactStrategyEnum}` | 'EXACT';
};

const cx = classNames.bind(styles);

const UNIT_BY_IMPACT_STRATEGY: Record<NonNullable<PropsT['impactStrategy']>, UnitTypeEnum> = {
    [RuleImpactStrategyEnum.percent]: UnitTypeEnum.percentsAbbreviation,
    [RuleImpactStrategyEnum.value]: UnitTypeEnum.euroAbbreviation,
    EXACT: UnitTypeEnum.euroAbbreviation, // TODO remove deprecated
};

const signedUnitTypesSet = new Set<NonNullable<PropsT['impactStrategy']>>([RuleImpactStrategyEnum.percent]);

const RuleImpact: React.FC<PropsT> = React.memo((props) => {
    const { className, value, impactStrategy } = props;

    if (value === undefined || impactStrategy === undefined) {
        return null;
    }

    const sign = value > 0 ? '+ ' : '- ';
    const absValue = Math.abs(value);
    const isShowSign = signedUnitTypesSet.has(impactStrategy);

    return (
        <span className={cs(cx('rule'), className)}>
            {isShowSign ? sign : null}
            <UnitTypeCount count={absValue} type={UNIT_BY_IMPACT_STRATEGY[impactStrategy]} />
        </span>
    );
});

export default RuleImpact;
