import * as React from 'react';
import { useContext, useMemo } from 'react';
import GoogleMapReact, { ClickEventValue } from 'google-map-react';
import { BOOTSTRAP_URL_KEYS, DEFAULT_CENTER, DEFAULT_ZOOM, MAP_OPTIONS } from 'common/store/constants';
import GoogleMapContext, { GoogleMapContextT } from 'common/contexts/google-map-context';
import isNumber from 'lodash/isNumber';
import { GoogleMapsGeocoderApi } from 'common/utils/google-maps-api/google-maps-geocoder-api';
import { GoogleMapsPlacesApi } from 'common/utils/google-maps-api/google-maps-places-api';
import { getGeoCodingLocation } from 'common/utils/google-places';
import { isNonNil } from 'common/utils';
import { useRouteGeometry } from 'common/utils/hooks/useRouteGeometry';
import {
    MapRoutePointT,
    OnSetMapBoundingBoxCallbackT,
    SyncRouteEditFormMapStateContext,
} from '../contexts/sync-map-state';
import { EditOrderContextT, RoutePointTypeEnum } from 'broker-admin/store/order-edit/models';
import MapLoader from 'common/components/maps/MapLoader/MapLoader';
import MapRoute, { MapRouteThemeEnum } from 'common/components/maps/MapRoute/MapRoute';
import { useSelector } from 'react-redux';
import { selectRoutingGeometryStates } from 'common/store/routing-geometry/selectors';
import { StyleGuideColorsEnum } from 'common/constants';
import NumberPinIcon from 'common/icons/NumberPinIcon';
import classNames from 'classnames/bind';

import styles from './RouteEditMap.scss';
import {
    selectIsApplyingContextChanges,
    selectOrderEditCreateContextRequest,
} from 'broker-admin/store/order-edit/selectors';
import keyBy from 'lodash/keyBy';

const KEY_SPREADER = '/';
const KEY_PREFIX = 'route-point';

const formatKey = (routePointId: string): string => {
    return [KEY_PREFIX, routePointId].join(KEY_SPREADER);
};

const parseKey = (key: string): string => {
    const [, routePointId] = key.split(KEY_SPREADER);
    return routePointId;
};

const cx = classNames.bind(styles);

type PropsT = {
    context: EditOrderContextT | null;
};

const geocodeByPoint = async (
    googleMapContext: GoogleMapContextT | null,
    point: GeoPointT,
): Promise<LocationT | null> => {
    const geocoderApi = new GoogleMapsGeocoderApi(googleMapContext?.googleMaps?.maps);
    const geocodeResult = await geocoderApi.geocode(point);
    if (!geocodeResult?.place_id) {
        return null;
    }

    const placesApi = new GoogleMapsPlacesApi(googleMapContext?.googleMaps?.map, googleMapContext?.googleMaps?.maps);
    const result = await placesApi.getPlaceDetails(geocodeResult?.place_id);
    if (!result) {
        return null;
    }

    const location = getGeoCodingLocation(result);

    return {
        address: location?.address || `${point?.lat}, ${point?.lng}`,
        point: location?.point || null,
        addressComponents: location?.addressComponents || null,
        utcOffsetMinutes: result.utc_offset_minutes,
    };
};

const tryGeocodeByPoint = async (googleMapContext: GoogleMapContextT | null, point: GeoPointT): Promise<LocationT> => {
    const geocodedLocation = await geocodeByPoint(googleMapContext, point);
    if (geocodedLocation) {
        return geocodedLocation;
    }

    return {
        point: {
            lat: point.lat,
            lng: point.lng,
        },
        address: `${point.lat}, ${point.lng}`,
        addressComponents: null,
        utcOffsetMinutes: null,
    };
};

const RouteEditMap: React.FC<PropsT> = React.memo((props) => {
    const { context } = props;

    const createContextRequest = useSelector(selectOrderEditCreateContextRequest);
    const isApplyingContextChanges = useSelector(selectIsApplyingContextChanges);

    const isLoading = isApplyingContextChanges || createContextRequest?.loading;

    const syncMapStateContext = useContext(SyncRouteEditFormMapStateContext);
    const { mapRoutePoints } = syncMapStateContext;

    const mapRoutePointsById = React.useMemo(() => {
        return keyBy(mapRoutePoints, 'id');
    }, [mapRoutePoints]);

    const draggablePinRef = React.useRef<string | null>(null);
    const [activeLocation, setActiveLocation] = React.useState<LocationT | null>(null);
    const [draggable, setDraggable] = React.useState(true);

    const handleDragMove = (childKey: string, childProps: any, mouse: GeoPointT) => {
        const routePointId = parseKey(childKey);
        const mapRoutePoint = mapRoutePointsById[routePointId] || null;
        if (!mapRoutePoint?.isDraggable) {
            return;
        }

        setDraggable(false);

        draggablePinRef.current = childKey;

        setActiveLocation({
            point: {
                lat: mouse.lat,
                lng: mouse.lng,
            },
            address: null,
            addressComponents: null,
            utcOffsetMinutes: null,
        });
    };

    React.useEffect(() => {
        setActiveLocation(null);
        draggablePinRef.current = null;
    }, [mapRoutePoints]);

    const handleDragEnd = async (childKey: string, childProps: any, mouse: GeoPointT) => {
        if (draggablePinRef.current !== childKey) {
            return;
        }

        setDraggable(true);

        const location = await tryGeocodeByPoint(googleMapContext, mouse);

        const routePointId = parseKey(childKey);

        syncMapStateContext.onDragEndDnDCallback?.callback?.(routePointId, location);
    };

    const handleMapClick = async (event: ClickEventValue) => {
        const location = await tryGeocodeByPoint(googleMapContext, event);
        syncMapStateContext.onMapClickCallback?.callback?.(location);
    };

    const [api, setApi] = React.useState<TODO>(null);

    const prevBoundingBoxHashRef = React.useRef<string>('');
    const onSetMapBoundingBoxCallback: OnSetMapBoundingBoxCallbackT = React.useCallback(
        (boundPoints) => {
            if (!boundPoints) {
                return;
            }

            const boundingBoxHashRef = JSON.stringify(boundPoints);
            if (prevBoundingBoxHashRef.current === boundingBoxHashRef) {
                return;
            }

            prevBoundingBoxHashRef.current = boundingBoxHashRef;

            if (!api) {
                return;
            }
            const { map, maps } = api;

            const bounds = new maps.LatLngBounds();

            boundPoints.forEach((boundPoint) => {
                const [latitude, longitude] = boundPoint;
                if (isNumber(latitude) && isNumber(longitude)) {
                    const latLng = new maps.LatLng(latitude, longitude);
                    bounds.extend(latLng);
                }
            });

            map.fitBounds(bounds);
        },
        [api, prevBoundingBoxHashRef],
    );

    React.useEffect(() => {
        syncMapStateContext.setOnSetMapBoundingBox({
            callback: onSetMapBoundingBoxCallback,
        });
    }, [onSetMapBoundingBoxCallback, syncMapStateContext.setOnSetMapBoundingBox]);

    const oldPolylineIds = useMemo(() => {
        return context?.oldTourData?.map((tour) => tour.polylineId).filter(isNonNil) || [];
    }, [context]);

    const oldPayloadGeometryStates = useSelector(selectRoutingGeometryStates(oldPolylineIds));

    useRouteGeometry(oldPolylineIds);

    const newPolylineIds = useMemo(() => {
        return context?.tours?.map((tour) => tour.polylineId).filter(isNonNil) || [];
    }, [context]);

    const newPayloadGeometryStates = useSelector(selectRoutingGeometryStates(newPolylineIds));

    useRouteGeometry(newPolylineIds);

    const googleMapContext = React.useContext(GoogleMapContext);

    const apiIsLoaded = (api: TODO) => {
        setApi(api);

        const { map, maps } = api;

        // eslint-disable-next-line no-unused-expressions
        googleMapContext.googleMaps?.set(maps, map, ['geometry']);
    };

    const renderRoutePointPin = (mapRoutePoint: MapRoutePointT): React.ReactNode => {
        const key = formatKey(mapRoutePoint.id);

        let latLngProps = {};
        if (draggablePinRef.current === key) {
            if (!activeLocation?.point) {
                return null;
            }

            latLngProps = {
                lat: activeLocation.point.lat,
                lng: activeLocation.point.lng,
            };
        } else {
            if (!mapRoutePoint?.location?.point) {
                return null;
            }

            latLngProps = {
                lat: mapRoutePoint.location.point?.lat,
                lng: mapRoutePoint.location.point?.lng,
            };
        }

        let fillColor = StyleGuideColorsEnum.charcoal;
        if (mapRoutePoint.isNew) {
            fillColor = StyleGuideColorsEnum.blazeOrange;
        } else if (mapRoutePoint.type === RoutePointTypeEnum.driveThrough) {
            fillColor = StyleGuideColorsEnum.gray;
        } else {
            fillColor = StyleGuideColorsEnum.charcoal;
        }

        if (mapRoutePoint.hasError) {
            fillColor = StyleGuideColorsEnum.tomatoRed;
        }

        return (
            <NumberPinIcon
                number={mapRoutePoint.number}
                key={key}
                className={cx('route-point-pin')}
                fillColor={fillColor}
                {...latLngProps}
            />
        );
    };

    const fixReRenderRouteKey = `${mapRoutePoints?.length || 0}`;

    return (
        <>
            {isLoading && <MapLoader />}
            <GoogleMapReact
                key="dispatch-route-edit"
                onGoogleApiLoaded={apiIsLoaded}
                yesIWantToUseGoogleMapApiInternals
                draggable={draggable}
                center={undefined}
                zoom={undefined}
                defaultCenter={DEFAULT_CENTER}
                defaultZoom={DEFAULT_ZOOM}
                bootstrapURLKeys={BOOTSTRAP_URL_KEYS}
                onClick={handleMapClick}
                options={MAP_OPTIONS}
                onChildMouseDown={handleDragMove}
                onChildMouseUp={handleDragEnd}
                onChildMouseMove={handleDragMove}
            >
                {mapRoutePoints.map((mapRoutePoint) => {
                    return renderRoutePointPin(mapRoutePoint);
                })}
            </GoogleMapReact>
            {!isLoading && (
                <>
                    {newPayloadGeometryStates.map((newPayloadGeometryState, index) => {
                        return (
                            <MapRoute
                                key={`new-route-${index}-${fixReRenderRouteKey}`}
                                map={api?.map}
                                maps={api?.maps}
                                geometryLibrary={googleMapContext?.googleMaps?.libraries?.geometry}
                                polylines={newPayloadGeometryState.data}
                                theme={MapRouteThemeEnum.editOrderNew}
                                zIndex={0}
                            />
                        );
                    })}
                </>
            )}
            {oldPayloadGeometryStates.map((oldPayloadGeometryState, index) => {
                return (
                    <MapRoute
                        key={`old-route-${index}-${fixReRenderRouteKey}`}
                        map={api?.map}
                        maps={api?.maps}
                        geometryLibrary={googleMapContext?.googleMaps?.libraries?.geometry}
                        polylines={oldPayloadGeometryState.data}
                        theme={MapRouteThemeEnum.editOrderOld}
                        zIndex={1}
                    />
                );
            })}
        </>
    );
});

export default RouteEditMap;
