import * as React from 'react';
import cs from 'classnames';
import classNames from 'classnames/bind';
import styles from './CarrierWithUtilizationDropdownOption.scss';
import CircleIcon from 'common/icons/CircleIcon';
import { useTranslation } from 'react-i18next';
import getUtilizationColor from 'common/utils/get-utilization-color';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import ControlLoaderIcon from 'common/icons/ControlLoaderIcon';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
    utilizationPercent: number | null;
    name: string;
    isLoading: boolean;
};

const CarrierWithUtilizationDropdownOption: React.FC<PropsT> = React.memo((props) => {
    const { className, name, utilizationPercent, isLoading } = props;

    const { t } = useTranslation();

    const isShowUtilization = utilizationPercent !== null;
    const isShowUtilizationLoader = utilizationPercent === null && isLoading;

    return (
        <div className={cs(cx('option'), className)}>
            <div className={cx('name')}>{name}</div>
            {(isShowUtilization || isShowUtilizationLoader) && <div className={cx('spacer')} />}
            {isShowUtilization && (
                <>
                    <CircleIcon className={cx('indicator')} borderColor={getUtilizationColor(utilizationPercent)} />
                    <div className={cx('utilization')}>
                        {t('assignment.asset-utilization', { percent: utilizationPercent })}
                    </div>
                </>
            )}
            {isShowUtilizationLoader && (
                <ControlLoaderIcon fillColor={StyleGuideColorsEnum.brandAccent} size={DEFAULT_ICON_SIZE} />
            )}
        </div>
    );
});

export default CarrierWithUtilizationDropdownOption;
