import { RoutePointIdT, RoutePointTimeWindowT, RoutePointTypeEnum } from 'broker-admin/store/order-edit/models';

export enum RoutePointFieldsEnum {
    id = 'id',
    type = 'type',
    location = 'location',
    timeWindow = 'timeWindow',
}

export type RoutePointFormValuesT = {
    id: RoutePointIdT;
    type: RoutePointTypeEnum;
    location: LocationT | null;
    timeWindow: RoutePointTimeWindowT | null;
};

export type RoutePointErrorsT = Partial<{
    id: FormErrorMessageT;
    type: FormErrorMessageT;
    location: FormErrorMessageT;
    timeWindow: FormErrorMessageT;
}>;

export enum FieldsEnum {
    route = 'route',
}

export type FormValuesT = {
    route: Array<RoutePointFormValuesT>;
};

export type FormErrorsT = Partial<{
    [FieldsEnum.route]: Array<RoutePointErrorsT>;
}>;

export type AsyncFormErrorsT = Partial<Record<FieldsEnum, string>>;
