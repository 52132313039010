import { ApiCountriesT, ApiLocationT } from 'common/utils/api/models';
import { ApiTourEditNewStopT } from 'broker-admin/utils/api/broker-tranziit/models';

export const convertToPointLocation = (location: ApiLocationT | null): ApiTourEditNewStopT['address'] | null => {
    if (!location) {
        return null;
    }

    const countyCode = location?.country;
    const latitude = location?.latitude;
    const longitude = location?.longitude;

    if (!latitude || !longitude || !countyCode) {
        return null;
    }

    return {
        latitude,
        longitude,
        country: countyCode as ApiCountriesT,
        city: location?.city || undefined,
        street1: location?.street1 || undefined,
        street2: location?.street2,
        zipCode: location?.zipCode || undefined,
    };
};
