import * as React from 'react';

import classNames from 'classnames/bind';

import styles from './DispatchStatusPill.scss';
import { useTranslation } from 'react-i18next';
import PillLabel, { colorCodingPillLabelTheme, PillLabelThemeEnum } from 'common/components/PillLabel/PillLabel';
import { ApiDispatchStatusT, DispatchStatusEnum } from 'common/utils/api/models';
import { DISPATCH_STATUS_T_MAP } from 'common/components/status/DispatchStatus/DispatchStatus';

type PropsT = {
    status?: ApiDispatchStatusT | null;
    isArchived?: boolean;
    isSymmetrical?: boolean;
};

const cx = classNames.bind(styles);

const PILL_THEME_BY_STATUS: Record<ApiDispatchStatusT, PillLabelThemeEnum> = {
    [DispatchStatusEnum.newDispatch]: colorCodingPillLabelTheme.attention,
    [DispatchStatusEnum.carrierUnassigned]: colorCodingPillLabelTheme.attention,
    [DispatchStatusEnum.carrierAssigned]: colorCodingPillLabelTheme.normal,
    [DispatchStatusEnum.inTransit]: colorCodingPillLabelTheme.normal,
    [DispatchStatusEnum.delivered]: colorCodingPillLabelTheme.success,
    [DispatchStatusEnum.canceled]: colorCodingPillLabelTheme.normal,
    [DispatchStatusEnum.unknown]: colorCodingPillLabelTheme.normal,
};

const DispatchStatusPill: React.FC<PropsT> = React.memo((props) => {
    const { status, isSymmetrical, isArchived } = props;

    const { t } = useTranslation();

    if (!status) {
        return null;
    }

    const theme = PILL_THEME_BY_STATUS[status] || PillLabelThemeEnum.charcoal;

    return (
        <>
            <PillLabel isNoWrap isSymmetrical={isSymmetrical} theme={theme} testSelector="dispatch-status">
                {t(DISPATCH_STATUS_T_MAP[status])}
            </PillLabel>
            {isArchived && (
                <PillLabel isNoWrap isSymmetrical={isSymmetrical} theme={theme} testSelector="dispatch-archived">
                    {t('common:dispatch-archived')}
                </PillLabel>
            )}
        </>
    );
});

export default DispatchStatusPill;
