import { ApiTourEditNewStopT } from 'broker-admin/utils/api/broker-tranziit/models';
import { RoutePointTypeEnum } from 'broker-admin/store/order-edit/models';

export const convertRoutePointType = (type: RoutePointTypeEnum | null): ApiTourEditNewStopT['type'] | null => {
    if (type === RoutePointTypeEnum.driveThrough) {
        return 'DRIVE_THROUGH';
    }

    if (type === RoutePointTypeEnum.dropAndHook) {
        return 'DROP_AND_HOOK';
    }

    return null;
};
