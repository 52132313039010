import { RequestStatusT } from 'common/utils/request-status';

export const SEND_TO_SPOT = 'broker/spot/SEND_TO_SPOT';
export const CANCEL_SPOT_REQUEST_BY_DISPATCH = 'broker/spot/CANCEL_SPOT_REQUEST_BY_DISPATCH';
export const CANCEL_SPOT_REQUEST = 'broker/spot/CANCEL_SPOT_REQUEST';
export const UPDATE_REQUEST_BEGIN = 'broker/spot/UPDATE_REQUEST_BEGIN';
export const UPDATE_REQUEST_ERROR = 'broker/spot/UPDATE_REQUEST_ERROR';
export const UPDATE_REQUEST_SUCCESS = 'broker/spot/UPDATE_REQUEST_SUCCESS';

export type SpotStateT = {
    requestStatus: RequestStatusT;
};

export type SendToSpotActionT = {
    type: typeof SEND_TO_SPOT;
    dispatchId: DispatchIdT;
    tourId: TourIdT;
    deadline: string;
    comment: string;
};

export type CancelSpotRequestByDispatchActionT = {
    type: typeof CANCEL_SPOT_REQUEST_BY_DISPATCH;
    dispatchId: DispatchIdT;
};

export type CancelSpotRequestActionT = {
    type: typeof CANCEL_SPOT_REQUEST;
    spotRequestId: SpotRequestIdT;
};

export type UpdateBeginActionT = {
    type: typeof UPDATE_REQUEST_BEGIN;
};

export type UpdateSuccessActionT = {
    type: typeof UPDATE_REQUEST_SUCCESS;
};

export type UpdateErrorActionT = {
    type: typeof UPDATE_REQUEST_ERROR;
    error: Error;
};

export type SpotActionT =
    | SendToSpotActionT
    | CancelSpotRequestByDispatchActionT
    | CancelSpotRequestActionT
    | UpdateBeginActionT
    | UpdateSuccessActionT
    | UpdateErrorActionT;
