import { CountryCodeT } from 'common/store/countries-dict/models';
import { DaysEnum, RuleImpactStrategyEnum } from 'common/constants';
import { RepeatStrategyTypeEnum, PriceComponentTypeEnum } from 'broker-admin/utils/api/broker-tranziit/models';
import { PriceSettingsRuleGroupEnum } from 'broker-admin/layouts/SettingsPage/PriceSettingsRules/constants';

export enum RepeatTypeEnum {
    days = 'WEEKDAYS',
    dates = 'DATES',
}

export enum FieldsEnum {
    id = 'id',

    name = 'name',
    group = 'group',

    shipperId = 'shipper-id',

    trailerTypeIds = 'trailer-type-ids',

    fromCountryCodes = 'from-country-codes',
    fromCountryZipCodes = 'from-country-zip-codes',

    toCountryCodes = 'to-country-codes',
    toCountryZipCodes = 'to-country-zip-codes',

    dates = 'dates',
    days = 'days',
    repeatType = 'repeat-type',
    repeatStrategy = 'repeat-strategy',

    validFrom = 'valid-from',
    validTill = 'valid-till',

    impactPriceComponent = 'impact-price-component',
    impactValue = 'impact-value',
    impactDiffValue = 'impact-diff-value',
    impactStrategy = 'impact-strategy',
}

export type FormValuesT = {
    [FieldsEnum.id]: number | null;
    [FieldsEnum.name]: string;
    [FieldsEnum.group]: PriceSettingsRuleGroupEnum | null;

    [FieldsEnum.shipperId]: ShipperIdT | null;

    [FieldsEnum.trailerTypeIds]: Array<TrailerDictIdT>;

    [FieldsEnum.fromCountryCodes]: Array<CountryCodeT>;
    [FieldsEnum.fromCountryZipCodes]: string;

    [FieldsEnum.toCountryCodes]: Array<CountryCodeT>;
    [FieldsEnum.toCountryZipCodes]: string;

    [FieldsEnum.dates]: DateRangeT | null;
    [FieldsEnum.days]: Array<DaysEnum>;
    [FieldsEnum.repeatType]: RepeatTypeEnum;
    [FieldsEnum.repeatStrategy]: RepeatStrategyTypeEnum;

    [FieldsEnum.validFrom]: Date | null;
    [FieldsEnum.validTill]: Date | null;

    [FieldsEnum.impactPriceComponent]: PriceComponentTypeEnum;
    [FieldsEnum.impactValue]: string;
    [FieldsEnum.impactDiffValue]: string;
    [FieldsEnum.impactStrategy]: RuleImpactStrategyEnum;
};

export type FormErrorsT = Partial<Record<FieldsEnum, string>>;

export const diffImpactStrategySet = new Set<RuleImpactStrategyEnum>([RuleImpactStrategyEnum.percent]);
