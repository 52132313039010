import * as React from 'react';
import { FieldsEnum, FormValuesT, RoutePointFieldsEnum } from '../constants';
import { useTranslation } from 'react-i18next';
import FormikConnectContextField from 'common/components/forms/FormikConnectContextField/FormikConnectContextField';
import GeoSuggest from 'common/components/GeoSuggest/GeoSuggest';
import classNames from 'classnames/bind';
import styles from './DriveThroughRoutePointPicker.scss';
import DeleteWaypointTrigger from '../DeleteWaypointTrigger/DeleteWaypointTrigger';
import WaypointNumberIcon from '../WaypointNumberIcon/WaypointNumberIcon';
import { getFieldFormValue } from 'common/utils/form-values';
import { useFormikContext } from 'formik';
import FieldValue from 'common/components/FieldValue/FieldValue';
import { OnChangeRoutePointAddressT, OnDeleteRoutePointReserveT } from '../models';
import { prepareLocation } from 'common/utils/prepare-location';
import { ApiTourUpdateStopDataT } from 'broker-admin/utils/api/broker-tranziit/models';
import { checkIsPointFakeId } from 'broker-admin/layouts/DispatchesPage/DispatchRouteEditPage/RouteEditForm/utils';

type PropsT = {
    className?: string;
    routePointIndex: number;
    tourUpdateStop: ApiTourUpdateStopDataT | null;
    oldTourUpdateStop: ApiTourUpdateStopDataT | null;
    onRemove: OnDeleteRoutePointReserveT;
    isAllowRemove: boolean;
    onManualSelectLocation: (location: LocationT | null, routePointIndex: number) => void;
    isAllowUpdateAddress: boolean;
    onChangeRoutePointAddress: OnChangeRoutePointAddressT;
    isShowSuggestLoading?: boolean;
};

const cx = classNames.bind(styles);

const DriveThroughRoutePointPicker: React.FC<PropsT> = React.memo((props) => {
    const {
        className,
        routePointIndex,
        onRemove,
        isShowSuggestLoading,
        isAllowRemove,
        onManualSelectLocation,
        onChangeRoutePointAddress,
        isAllowUpdateAddress,
    } = props;

    const { t } = useTranslation();

    const formik = useFormikContext<FormValuesT>();

    const locationFieldName = `${FieldsEnum.route}.${routePointIndex}.${RoutePointFieldsEnum.location}` as const;
    const idFieldName = `${FieldsEnum.route}.${routePointIndex}.${RoutePointFieldsEnum.id}` as const;
    const typeFieldName = `${FieldsEnum.route}.${routePointIndex}.${RoutePointFieldsEnum.type}` as const;

    const id = getFieldFormValue(formik.values, idFieldName);
    const type = getFieldFormValue(formik.values, typeFieldName);

    return (
        <>
            <FormikConnectContextField<FormValuesT, typeof locationFieldName>
                className={className}
                name={locationFieldName}
                label={t('dispatch-route-edit.fields.drive-through-point.label')}
            >
                {(props, extendsProps) => (
                    <div className={cx('wrap')} data-point-id={id}>
                        {isAllowUpdateAddress ? (
                            <GeoSuggest
                                className={cx('geo-suggest')}
                                name={extendsProps.name}
                                value={extendsProps.value}
                                placeholder={t('dispatch-route-edit.fields.drive-through-point.placeholder')}
                                renderLeftIcon={({ hasValue, hasError, hasWarning, isDisabled }) => (
                                    <WaypointNumberIcon
                                        routePointIndex={routePointIndex}
                                        hasValue={hasValue}
                                        hasError={hasError}
                                        hasWarning={hasWarning}
                                        isDisabled={isDisabled}
                                        isDriveThroughRoutePointPicker
                                        isNewRoutePoint={checkIsPointFakeId(id)}
                                    />
                                )}
                                onChange={(value) => {
                                    const location = prepareLocation(value);
                                    if (!location) {
                                        return;
                                    }

                                    props.onChange(value);

                                    onManualSelectLocation(value, routePointIndex);

                                    onChangeRoutePointAddress(routePointIndex, {
                                        address: location,
                                        id,
                                        routePointType: type,
                                    });
                                }}
                                hasError={props.hasError}
                                hasWarning={props.hasWarning}
                                onFocus={props.onFocus}
                                onBlur={props.onBlur}
                                isLoading={isShowSuggestLoading}
                                testSelector={extendsProps.name}
                            />
                        ) : (
                            <FieldValue
                                withoutErrorMessage
                                icon={
                                    <WaypointNumberIcon
                                        routePointIndex={routePointIndex}
                                        isDriveThroughRoutePointPicker
                                        isNewRoutePoint={checkIsPointFakeId(id)}
                                        hasValue={!!extendsProps.value}
                                    />
                                }
                                value={extendsProps.value?.address || ''}
                            />
                        )}
                        {isAllowRemove && (
                            <DeleteWaypointTrigger
                                onClick={() => {
                                    onRemove(routePointIndex, id);
                                }}
                            />
                        )}
                    </div>
                )}
            </FormikConnectContextField>
        </>
    );
});

export default DriveThroughRoutePointPicker;
