import { FieldsEnum, FormValuesT, RoutePointFormValuesT } from './constants';
import { ApiLocationT, ApiStopTypeT } from 'common/utils/api/models';
import { EditOrderContextT, RoutePointTypeEnum } from 'broker-admin/store/order-edit/models';
import { formatLocation } from 'common/utils/formatters';
import { generatePointFakeId } from './utils';

const getInitialValues = (context: EditOrderContextT | null): FormValuesT => {
    const stops =
        context?.tours?.flatMap((tour) => {
            const stops = tour?.stops || [];
            if (stops[0]?.type === 'DROP_AND_HOOK') {
                return stops.slice(1);
            }

            return stops;
        }) || [];

    const values: FormValuesT = {
        [FieldsEnum.route]:
            stops.map((stop): RoutePointFormValuesT => {
                const typeMap: Record<ApiStopTypeT, RoutePointTypeEnum> = {
                    PICKUP_TRAILER: RoutePointTypeEnum.unknown,
                    PICKUP_TRUCK: RoutePointTypeEnum.unknown,
                    PICKUP_ROAD_TRAIN: RoutePointTypeEnum.unknown,
                    PICKUP_DELIVERY_SHIPMENT: RoutePointTypeEnum.pickupOrDelivery,
                    DRIVE_THROUGH: RoutePointTypeEnum.driveThrough,
                    DROP_AND_HOOK: RoutePointTypeEnum.dropAndHook,
                    DROP_TRAILER: RoutePointTypeEnum.unknown,
                    DROP_TRUCK: RoutePointTypeEnum.unknown,
                    DROP_ROAD_TRAIN: RoutePointTypeEnum.unknown,
                };

                const { type, originalDateTimeFrom, originalDateTimeTo, correctedFrom, correctedTo } = stop;

                const timeWindowFrom = correctedFrom || originalDateTimeFrom || null;
                const timeWindowTo = correctedTo || originalDateTimeTo || null;

                const convertPointToLocation = (address: ApiLocationT | null): LocationT | null => {
                    if (!address) {
                        return null;
                    }

                    return {
                        address: formatLocation(address),
                        utcOffsetMinutes: null,
                        addressComponents: {
                            countryCode: address.country,
                            street1: address?.street1,
                            street2: address?.street2,
                            zipCode: address?.zipCode,
                            city: address?.city,
                        },
                        point: {
                            lat: address.latitude || 0,
                            lng: address.longitude || 0,
                        },
                    };
                };

                return {
                    id: stop.id || generatePointFakeId(),
                    type: type ? typeMap[type] : RoutePointTypeEnum.unknown,
                    location: convertPointToLocation(stop.address || null) || null,
                    timeWindow:
                        timeWindowFrom && timeWindowTo
                            ? {
                                  start: timeWindowFrom,
                                  end: timeWindowTo,
                              }
                            : null,
                };
            }) || [],
    };

    return values;
};

export default getInitialValues;
