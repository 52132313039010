import { TFunction } from 'i18next';
import { diffImpactStrategySet, FieldsEnum, FormErrorsT, FormValuesT, RepeatTypeEnum } from './constants';
import { checkMaxValue, checkMinValue, checkNotEmpty } from 'common/utils/form-validators';

const REQUIRED_FIELDS = [
    FieldsEnum.name,
    FieldsEnum.group,
    FieldsEnum.validFrom,
    FieldsEnum.shipperId,
    FieldsEnum.trailerTypeIds,
    FieldsEnum.fromCountryCodes,
    FieldsEnum.toCountryCodes,
];

const emptyImpactDiffValuesSet = new Set(['+', '-']);

const validate = (t: TFunction, values: FormValuesT): FormErrorsT => {
    const impactStrategy = values[FieldsEnum.impactStrategy];
    const isDiffImpactStrategy = diffImpactStrategySet.has(impactStrategy);

    let errors: FormErrorsT = {
        ...checkNotEmpty(t, REQUIRED_FIELDS, values),
    };

    if (isDiffImpactStrategy) {
        errors = {
            ...errors,
            ...checkNotEmpty(t, [FieldsEnum.impactDiffValue], values),
            ...checkMaxValue(t, [FieldsEnum.impactDiffValue], values, 100),
            ...checkMinValue(t, [FieldsEnum.impactDiffValue], values, -100),
        };
    } else {
        errors = {
            ...errors,
            ...checkNotEmpty(t, [FieldsEnum.impactValue], values),
        };
    }

    const impactDiffValue = (values[FieldsEnum.impactDiffValue] || '').trim();
    if (isDiffImpactStrategy && emptyImpactDiffValuesSet.has(impactDiffValue)) {
        errors[FieldsEnum.impactDiffValue] = t('common:form-errors.required');
    }

    const dates = values[FieldsEnum.dates];
    if (values[FieldsEnum.repeatType] === RepeatTypeEnum.dates && !dates?.from && !dates?.to) {
        errors[FieldsEnum.dates] = t('common:form-errors.required');
    }

    const days = values[FieldsEnum.days];
    if (values[FieldsEnum.repeatType] === RepeatTypeEnum.days && !days.length) {
        errors[FieldsEnum.days] = t('common:form-errors.required');
    }

    return errors;
};

export default validate;
