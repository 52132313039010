import React, { useCallback } from 'react';
import { PropsT as InputPropsT } from 'common/components/Input/Input';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import DropdownControlOptionLabel from 'design-system/components/dropdowns/option/DropdownControlOptionLabel/DropdownControlOptionLabel';
import FlagIcon from 'common/icons/FlagIcon/FlagIcon';
import ShipperIcon, { ShipperIconProps } from 'common/icons/ShipperIcon';
import DropdownSearchInput from 'design-system/components/dropdowns/DropdownSearchInput/DropdownSearchInput';
import {
    AsyncRequestFactoryResultT,
    AsyncRequestFactoryT,
    useAsyncOptionsRequest,
} from 'design-system/components/dropdowns/SuggestInput/hooks/use-async-options-request';
import commonTranziitApi from 'common/utils/api/tranziit/common-tranziit-api';
import { ApiOrderCreationShipperInfoT } from 'common/utils/api/models';
import { RequestStatusT } from 'common/utils/request-status';

type OptionT = ApiOrderCreationShipperInfoT;
type ValueT = OptionT['id'] | null;

export type PropsT = {
    className?: string;
    initialOptions: Array<OptionT>;
    initialRequestStatus: RequestStatusT;
    onSelect: (value: ValueT, option: OptionT | null) => void;
    value: ValueT;
    overlayPosition: DropdownOverlayPositionEnum;
    hasClearControl?: InputPropsT['hasClearControl'];
    hasError: InputPropsT['hasError'];
    hasWarning: InputPropsT['hasWarning'];
    placeholder: InputPropsT['placeholder'];
    onBlur: InputPropsT['onBlur'];
    onFocus: InputPropsT['onFocus'];
    isDisabled?: InputPropsT['isDisabled'];
    hasChanges?: boolean;
};

const OrderReservesShipperSuggest: React.FC<PropsT> = (props) => {
    const {
        className,
        initialOptions,
        initialRequestStatus,
        value,
        onSelect,
        hasWarning,
        hasError,
        hasClearControl,
        overlayPosition,
        isDisabled,
        placeholder,
        onBlur,
        onFocus,
        hasChanges,
    } = props;

    const handleReset = () => {
        onSelect(null, null);
    };

    const handleFocus: InputPropsT['onFocus'] = () => {
        if (onFocus) {
            onFocus();
        }
    };

    const renderOption = (option: OptionT | undefined, placeholder?: string): React.ReactNode => {
        if (!option) {
            return placeholder;
        }

        return (
            <DropdownControlOptionLabel
                withoutPaddings
                icon={option.country ? <FlagIcon countryCode={option.country} /> : null}
                label={option.name || ''}
                description={option.number || ''}
            />
        );
    };

    const getOptionValue = (option: OptionT): ValueT => option.id;

    const requestFactory: AsyncRequestFactoryT<OptionT> = useCallback((query) => {
        return commonTranziitApi
            .fetchOrderReservesShippers({
                name: query,
            })
            .then((response): AsyncRequestFactoryResultT<OptionT> => {
                const [error, result] = response;
                if (error) {
                    return [error, null];
                }

                return [null, result?.content || []];
            });
    }, []);

    const asyncOptionsRequest = useAsyncOptionsRequest<OptionT>({
        initialOptions,
        requestFactory,
        isDynamicInitialOptions: true,
    });

    const handleSelect = (value: ValueT) => {
        const option = asyncOptionsRequest.options.find((option) => {
            return getOptionValue(option) === value;
        });

        onSelect(value || null, option || null);
    };

    return (
        <DropdownSearchInput<OptionT, ValueT>
            className={className}
            selectedValue={value}
            placeholder={placeholder}
            options={asyncOptionsRequest.options}
            isDisabled={isDisabled || initialRequestStatus.loading}
            onSelect={handleSelect}
            hasError={hasError}
            hasWarning={hasWarning}
            onChangeQuery={asyncOptionsRequest.onChangeQuery}
            isLoading={asyncOptionsRequest.requestStatus.loading || initialRequestStatus.loading}
            renderOption={renderOption}
            renderTrigger={renderOption}
            getOptionValue={getOptionValue}
            hasChanges={hasChanges}
            renderLeftIcon={(iconMeta) => <ShipperIcon {...ShipperIconProps.getControlProps(iconMeta)} />}
            onBlur={onBlur}
            onFocus={handleFocus}
            overlayPosition={overlayPosition}
            onReset={hasClearControl ? handleReset : undefined}
        />
    );
};

export default OrderReservesShipperSuggest;
