import * as React from 'react';
import { useContext, useMemo, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './AssignmentMap.scss';
import GoogleMapReact, { ClickEventValue } from 'google-map-react';
import { BOOTSTRAP_URL_KEYS, DEFAULT_CENTER, DEFAULT_ZOOM, MAP_OPTIONS } from 'common/store/constants';
import GoogleMapContext, { GoogleMapContextT } from 'common/contexts/google-map-context';
import { ApiStopTypeT, StopTypeEnum } from 'common/utils/api/models';
import { AssetTypeEnum, StyleGuideColorsEnum } from 'common/constants';
import Supercluster from 'supercluster';
import AssetPin from './AssetPin/AssetPin';
import AssetClusterPin from './AssetClusterPin/AssetClusterPin';
import SearchForm from './SearchForm/SearchForm';
import {
    selectAvailableCarriersById,
    selectAvailableVehicles,
    selectAvailableVehiclesQuery,
    selectAvailableVehiclesRequest,
    selectFetchAssignDetailsRequest,
    selectScheduleAssetById,
    selectSelectedScheduleEventId,
    selectSelfCostPrediction,
    selectSelfCostPredictionRequestStatus,
    selectTrailerAssignmentInfoById,
    selectTruckAssignmentInfoById,
    selectUserSelection,
    selectVehicleLinks,
} from 'broker-admin/store/dispatch-assigment/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { createVehiclesGeoJson } from './utils/geo-json-factory';
import { useTranslation } from 'react-i18next';
import { selectCarriersUtilizationByHash } from 'common/store/carriers-utilization/selectors';
import { DispatchDetailsT } from 'broker-admin/store/dispatch-details/models';
import { changeUserSelection } from 'broker-admin/store/dispatch-assigment/actions';
import {
    PointToDropT,
    ScheduleEventIdT,
    ScheduleEventT,
    UserSelectionT,
} from 'broker-admin/store/dispatch-assigment/models';
import MapArea, { MapAreaThemeEnum } from 'common/components/maps/MapArea/MapArea';
import MapRoute, { MapRouteThemeEnum } from 'common/components/maps/MapRoute/MapRoute';
import CurrentAssetPositionPin from 'broker-admin/layouts/DispatchesPage/DispatchAssignmentPage/AssignmentMap/CurrentAssetPositionPin/CurrentAssetPositionPin';
import { CURRENT_ASSIGNMENT_EVENT_ID } from 'broker-admin/layouts/DispatchesPage/DispatchAssignmentPage/constants';
import AssetSchedulePin from 'broker-admin/layouts/DispatchesPage/DispatchAssignmentPage/AssignmentMap/AssetSchedulePin/AssetSchedulePin';
import isNumber from 'lodash/isNumber';
import { M_IN_KM } from 'common/utils/distance';
import { GoogleMapsGeocoderApi } from 'common/utils/google-maps-api/google-maps-geocoder-api';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import ControlLoaderLabel from 'common/components/ControlLoaderLabel/ControlLoaderLabel';
import { GoogleMapsPlacesApi } from 'common/utils/google-maps-api/google-maps-places-api';
import { getGeoCodingLocation } from 'common/utils/google-places';
import TimeZoneContext from 'broker-admin/layouts/DispatchesPage/DispatchAssignmentPage/contexts/timezone-context';
import { getHash } from 'common/store/carriers-utilization/utils';
import { isNonNil } from 'common/utils';
import { useRouteGeometry } from 'common/utils/hooks/useRouteGeometry';
import { selectRoutingGeometryState } from 'common/store/routing-geometry/selectors';
import NumberPinIcon from 'common/icons/NumberPinIcon';
import AssetDropOffLocationPin from 'common/components/maps/pins/AssetDropOffLocationPin/AssetDropOffLocationPin';
import { BoundPointT } from 'common/components/maps/MapBound/MapBound';
import { findTourById } from 'broker-admin/store/dispatch-details/utils/find-tour-by-id';

import { createInitPayloadDropPoint } from 'broker-admin/layouts/DispatchesPage/DispatchAssignmentPage/hooks/createInitPayloadDropPoint';
import AssetsSelectionContext from '../contexts/assets-selection-context';
import { SyncAssignmentFormMapStateContext } from 'broker-admin/layouts/DispatchesPage/DispatchAssignmentPage/contexts/sync-map-state';
import { findFirstPickupDeliveryWaypointDate } from 'broker-admin/store/dispatch-assigment/utils/find-first-pickup-delivery-waypoint-date';

const cx = classNames.bind(styles);

type LoaderPropsT = {
    title: string;
};

const Loader: React.FC<LoaderPropsT> = (props) => {
    const { title } = props;

    return <ControlLoaderLabel isInvert>{title}</ControlLoaderLabel>;
};

type PropsT = {
    dispatchId: DispatchIdT;
    dispatchDetails: DispatchDetailsT | null;
    tourId: TourIdT | null;
};

const FEATURES_LIMIT = 99999;
const DEFAULT_OFFSET = 0;

type AssetIdsT = {
    trailerId: TrailerIdT | null;
    truckId: TruckIdT | null;
};

const SUPERCLUSTER_SETTINGS = {
    radius: 100,
    maxZoom: 22,
};

type RenderMapClusterParamsT = {
    cluster: TODO;
    clusterFeatures: Array<Supercluster.PointFeature<TODO>>;
    selectedTruckId: TruckIdT | null;
    selectedTrailerId: TrailerIdT | null;
    firstPickupDeliveryWaypointDate: string | null;
    onClusterClick: (clusterId: number) => void;
    onSelectAssets: (truckId: TruckIdT | null | undefined, trailerId: TrailerIdT | null | undefined) => void;
    onHoverAssets: (truckId: TruckIdT | null | undefined, trailerId: TrailerIdT | null | undefined) => void;
    canInTime: boolean;
};

const geocodeByPoint = async (
    googleMapContext: GoogleMapContextT | null,
    point: GeoPointT,
): Promise<PointToDropT | null> => {
    const geocoderApi = new GoogleMapsGeocoderApi(googleMapContext?.googleMaps?.maps);
    const geocodeResult = await geocoderApi.geocode(point);
    if (!geocodeResult?.place_id) {
        return null;
    }

    const placesApi = new GoogleMapsPlacesApi(googleMapContext?.googleMaps?.map, googleMapContext?.googleMaps?.maps);
    const result = await placesApi.getPlaceDetails(geocodeResult?.place_id);
    if (!result) {
        return null;
    }

    const location = getGeoCodingLocation(result);

    return {
        address: location?.address || `${point?.lat}, ${point?.lng}`,
        point: location?.point || null,
        addressComponents: location?.addressComponents || null,
    };
};

const MAP_PIN_KEY = {
    pointToDropLink: 'point-to-drop-link',
    pointToDropTrailer: 'point-to-drop-trailer',
    pointToDropTruck: 'point-to-drop-truck',
};

const AVAILABLE_STOP_SET = new Set<ApiStopTypeT>([
    StopTypeEnum.pickupDeliveryShipment,
    StopTypeEnum.driveThrough,
    StopTypeEnum.dropAndHook,
]);

const AssignmentMap: React.FC<PropsT> = React.memo((props) => {
    const { dispatchId, tourId, dispatchDetails } = props;

    const { t } = useTranslation();

    const syncMapStateContext = useContext(SyncAssignmentFormMapStateContext);

    const [hoveredAssets, setHoveredAssets] = useState<AssetIdsT>({
        trailerId: null,
        truckId: null,
    });
    const hoveredTrailerId = hoveredAssets.trailerId;
    const hoveredTruckId = hoveredAssets.truckId;

    const isSkipFocusRef = React.useRef<boolean>(false);

    const assetsSelectionContext = useContext(AssetsSelectionContext);
    const timeZoneContext = useContext(TimeZoneContext);
    const userSelection = useSelector(selectUserSelection);
    const selectedTrailerId = userSelection?.trailerId || null;
    const selectedTruckId = userSelection?.truckId || null;
    const selectedPointToDropTrailer = userSelection?.pointToDropTrailer || null;
    const selectedPointToDropTruck = userSelection?.pointToDropTruck || null;

    const isBlockedSelectTrailer = !!assetsSelectionContext?.value?.isBlockedSelectTrailer;
    const isBlockedSelectTruck = !!assetsSelectionContext?.value?.isBlockedSelectTruck;

    const tour = React.useMemo(() => {
        return findTourById(dispatchDetails, tourId);
    }, [dispatchDetails, tourId]);

    const waypoints = React.useMemo(() => {
        return tour?.waypoints || [];
    }, [tour]);

    const dispatch = useDispatch();

    const firstPayloadWaypoint = React.useMemo(() => {
        if (!waypoints) {
            return null;
        }

        return (
            waypoints.find((waypoint) => {
                return (
                    waypoint?.type === StopTypeEnum.pickupDeliveryShipment ||
                    waypoint?.type === StopTypeEnum.driveThrough ||
                    waypoint?.type === StopTypeEnum.dropAndHook
                );
            }) || null
        );
    }, [waypoints]);

    const firstPickupDeliveryWaypointDate = React.useMemo(() => {
        return findFirstPickupDeliveryWaypointDate(dispatchDetails, tourId);
    }, [dispatchDetails, tourId]);

    const handleSelectAssets = (
        truckId: TruckIdT | null | undefined,
        trailerId: TrailerIdT | null | undefined,
    ): void => {
        isSkipFocusRef.current = true;

        const userSelection: Partial<UserSelectionT> = {};
        if (truckId) {
            userSelection.truckId = truckId;
        }
        if (trailerId) {
            userSelection.trailerId = trailerId;
        }
        dispatch(changeUserSelection(dispatchId, tourId, userSelection));
    };

    const handleHoverAssets = (
        truckId: TruckIdT | null | undefined,
        trailerId: TrailerIdT | null | undefined,
    ): void => {
        setHoveredAssets({
            truckId: truckId || null,
            trailerId: trailerId || null,
        });
    };

    const [api, setApi] = React.useState<TODO>(null);
    const [config, setConfig] = React.useState<TODO>(null);

    const selectedScheduleEventId = useSelector(selectSelectedScheduleEventId);

    const vehicles = useSelector(selectAvailableVehicles);
    const carriersById = useSelector(selectAvailableCarriersById);

    const carriersUtilizationByHash = useSelector(selectCarriersUtilizationByHash);
    const trailerAssignmentInfoById = useSelector(selectTrailerAssignmentInfoById);
    const truckAssignmentInfoById = useSelector(selectTruckAssignmentInfoById);
    const scheduleAssetById = useSelector(selectScheduleAssetById);

    const vehicleLinks = useSelector(selectVehicleLinks);
    const isSelectedLink = vehicleLinks.byTruckId[selectedTruckId as TruckIdT] === selectedTrailerId;

    const query = useSelector(selectAvailableVehiclesQuery);
    const availableVehiclesRequestStatus = useSelector(selectAvailableVehiclesRequest);

    const selfCostPrediction = useSelector(selectSelfCostPrediction);
    const selfCostPredictionRequestStatus = useSelector(selectSelfCostPredictionRequestStatus);

    const fetchAssignDetailsRequest = useSelector(selectFetchAssignDetailsRequest);

    const polylineIds = useMemo(() => {
        return [
            tour?.polylineId,
            selfCostPrediction?.trailerToPickupPolylineId,
            selfCostPrediction?.truckToTrailerPolylineId,
            selfCostPrediction?.payloadPolylineId,
            selfCostPrediction?.dropTrailerPolylineId,
            selfCostPrediction?.dropTruckPolylineId,
        ].filter(isNonNil);
    }, [
        tour?.polylineId,
        selfCostPrediction?.trailerToPickupPolylineId,
        selfCostPrediction?.truckToTrailerPolylineId,
        selfCostPrediction?.payloadPolylineId,
        selfCostPrediction?.dropTrailerPolylineId,
        selfCostPrediction?.dropTruckPolylineId,
    ]);

    useRouteGeometry(polylineIds);

    const payloadGeometryState = useSelector(selectRoutingGeometryState(tour?.polylineId));

    const deadheadRoutingGeometryState = useSelector(
        selectRoutingGeometryState(selfCostPrediction?.trailerToPickupPolylineId),
    );
    const truckToTrailerRoutingGeometryState = useSelector(
        selectRoutingGeometryState(selfCostPrediction?.truckToTrailerPolylineId),
    );

    const dropTrailerRoutingGeometryState = useSelector(
        selectRoutingGeometryState(selfCostPrediction?.dropTrailerPolylineId),
    );

    const dropTruckRoutingGeometryState = useSelector(
        selectRoutingGeometryState(selfCostPrediction?.dropTruckPolylineId),
    );

    const selectedTrailer = selectedTrailerId ? trailerAssignmentInfoById[selectedTrailerId] : null;
    const selectedTruck = selectedTruckId ? truckAssignmentInfoById[selectedTruckId] : null;

    const defaultBoundPoints = useMemo((): Array<BoundPointT> => {
        const boundPoints: Array<BoundPointT> = [];

        waypoints?.forEach((payloadWaypoint) => {
            boundPoints.push([payloadWaypoint?.address?.latitude, payloadWaypoint?.address?.longitude]);
        });

        const assets = [selectedTruck, selectedTrailer];
        assets.forEach((asset) => {
            if (userSelection?.isShowActualPlace && asset?.currentLatitude && asset?.currentLongitude) {
                boundPoints.push([asset.currentLatitude, asset.currentLongitude]);
            }

            if (asset?.expectedLatitude && asset?.expectedLongitude) {
                boundPoints.push([asset.expectedLatitude, asset.expectedLongitude]);
            }
        });

        return boundPoints;
    }, [waypoints, selectedTrailerId, selectedTruckId]);

    const defaultBoundPointsHash = useMemo(() => {
        return JSON.stringify(defaultBoundPoints);
    }, [defaultBoundPoints]);

    React.useEffect(() => {
        if (!api) {
            return;
        }

        if (isSkipFocusRef.current) {
            isSkipFocusRef.current = false;
            return;
        }

        if (!defaultBoundPoints?.length) {
            return;
        }

        const { map, maps } = api;

        const bounds = new maps.LatLngBounds();

        defaultBoundPoints.forEach((boundPoints) => {
            const [latitude, longitude] = boundPoints;
            if (isNumber(latitude) && isNumber(longitude)) {
                const latLng = new maps.LatLng(latitude, longitude);
                bounds.extend(latLng);
            }
        });

        map.fitBounds(bounds);
    }, [api, defaultBoundPointsHash]);

    const googleMapContext = React.useContext(GoogleMapContext);

    const apiIsLoaded = (api: TODO) => {
        setApi(api);

        const { map, maps } = api;

        // eslint-disable-next-line no-unused-expressions
        googleMapContext.googleMaps?.set(maps, map, ['geometry']);
    };

    const sourceNotInTimeVehiclesGeoJSONs = React.useMemo(() => {
        return createVehiclesGeoJson({
            vehicles,
            trailerById: trailerAssignmentInfoById,
            truckById: truckAssignmentInfoById,
            options: {
                showSelectedNotCanBeInTime: true,
                showNotCanBeInTime: userSelection?.isShowUnavailableAssets,
                showCanBeInTime: false,
                blockSelectTruck: isBlockedSelectTruck,
                blockSelectTrailer: isBlockedSelectTrailer,
            },
            selectedTrailerId,
            selectedTruckId,
            vehicleLinks,
        });
    }, [
        vehicles,
        trailerAssignmentInfoById,
        selectedTrailerId,
        selectedTruckId,
        hoveredTrailerId,
        hoveredTruckId,
        truckAssignmentInfoById,
        vehicleLinks,
        userSelection?.isShowUnavailableAssets,
        isBlockedSelectTrailer,
        isBlockedSelectTruck,
    ]);

    const sourceInTimeVehiclesGeoJSONs = React.useMemo(() => {
        return createVehiclesGeoJson({
            vehicles,
            trailerById: trailerAssignmentInfoById,
            truckById: truckAssignmentInfoById,
            options: {
                showSelectedNotCanBeInTime: false,
                showNotCanBeInTime: false,
                showCanBeInTime: true,
                blockSelectTruck: isBlockedSelectTruck,
                blockSelectTrailer: isBlockedSelectTrailer,
            },
            selectedTrailerId,
            selectedTruckId,
            vehicleLinks,
        });
    }, [
        vehicles,
        trailerAssignmentInfoById,
        selectedTrailerId,
        selectedTruckId,
        hoveredTrailerId,
        hoveredTruckId,
        truckAssignmentInfoById,
        vehicleLinks,
        isBlockedSelectTrailer,
        isBlockedSelectTruck,
    ]);

    const {
        inTimeVehiclesGeoJSONs,
        notInTimeVehiclesGeoJSONs,
        selectedTruckCurrentPosition,
        selectedTrailerCurrentPosition,
    } = React.useMemo(() => {
        const PROXIMITY = 0.0002;
        const getPositionHash = (lot: number, lat: number) => {
            return [lot, lat].map((coordinate) => (coordinate % PROXIMITY) * PROXIMITY).join('|');
        };

        const positionsHashSet = new Set<string>([]);

        let hash = '';

        let selectedTruckCurrentPosition = null;
        if (selectedTruck?.currentLongitude && selectedTruck?.currentLatitude) {
            selectedTruckCurrentPosition = {
                lng: selectedTruck.currentLongitude,
                lat: selectedTruck.currentLatitude,
            };

            hash = getPositionHash(selectedTruckCurrentPosition.lng, selectedTruckCurrentPosition.lat);
            while (positionsHashSet.has(hash)) {
                selectedTruckCurrentPosition = {
                    ...selectedTruckCurrentPosition,
                    lng: selectedTruckCurrentPosition.lng + PROXIMITY,
                };
                hash = getPositionHash(selectedTruckCurrentPosition.lng, selectedTruckCurrentPosition.lat);
            }

            positionsHashSet.add(hash);
        }

        let selectedTrailerCurrentPosition = null;
        if (selectedTrailer?.currentLongitude && selectedTrailer?.currentLatitude) {
            selectedTrailerCurrentPosition = {
                lng: selectedTrailer.currentLongitude,
                lat: selectedTrailer.currentLatitude,
            };

            hash = getPositionHash(selectedTrailerCurrentPosition.lng, selectedTrailerCurrentPosition.lat);
            while (positionsHashSet.has(hash)) {
                selectedTrailerCurrentPosition = {
                    ...selectedTrailerCurrentPosition,
                    lng: selectedTrailerCurrentPosition.lng + PROXIMITY,
                };
                hash = getPositionHash(selectedTrailerCurrentPosition.lng, selectedTrailerCurrentPosition.lat);
            }

            positionsHashSet.add(hash);
        }

        const notInTimeVehiclesGeoJSONs = sourceNotInTimeVehiclesGeoJSONs.map((sourceGeoJSON) => {
            let geoJSON = { ...sourceGeoJSON };

            hash = getPositionHash(geoJSON.geometry.coordinates[0], geoJSON.geometry.coordinates[1]);
            while (positionsHashSet.has(hash)) {
                geoJSON = {
                    ...geoJSON,
                    geometry: {
                        ...geoJSON.geometry,
                        coordinates: [geoJSON.geometry.coordinates[0] + PROXIMITY, geoJSON.geometry.coordinates[1]],
                    },
                };
                hash = getPositionHash(geoJSON.geometry.coordinates[0], geoJSON.geometry.coordinates[1]);
            }

            positionsHashSet.add(hash);
            return geoJSON;
        });

        const inTimeVehiclesGeoJSONs = sourceInTimeVehiclesGeoJSONs.map((sourceGeoJSON) => {
            let geoJSON = { ...sourceGeoJSON };

            hash = getPositionHash(geoJSON.geometry.coordinates[0], geoJSON.geometry.coordinates[1]);
            while (positionsHashSet.has(hash)) {
                geoJSON = {
                    ...geoJSON,
                    geometry: {
                        ...geoJSON.geometry,
                        coordinates: [geoJSON.geometry.coordinates[0] + PROXIMITY, geoJSON.geometry.coordinates[1]],
                    },
                };
                hash = getPositionHash(geoJSON.geometry.coordinates[0], geoJSON.geometry.coordinates[1]);
            }

            positionsHashSet.add(hash);
            return geoJSON;
        });

        return {
            inTimeVehiclesGeoJSONs,
            notInTimeVehiclesGeoJSONs,
            selectedTruckCurrentPosition,
            selectedTrailerCurrentPosition,
        };
    }, [sourceNotInTimeVehiclesGeoJSONs, sourceInTimeVehiclesGeoJSONs, selectedTruck, selectedTrailer]);

    const notInTimeClusterator = React.useMemo(() => {
        const clusterator = new Supercluster(SUPERCLUSTER_SETTINGS);
        clusterator.load(notInTimeVehiclesGeoJSONs);
        return clusterator;
    }, [notInTimeVehiclesGeoJSONs]);

    const notInTimeClusters = React.useMemo(() => {
        if (config) {
            const clusters = notInTimeClusterator.getClusters(config.bounds, config.zoom);
            return clusters;
        }

        return [];
    }, [config, notInTimeClusterator]);

    const inTimeClusterator = React.useMemo(() => {
        const clusterator = new Supercluster(SUPERCLUSTER_SETTINGS);
        clusterator.load(inTimeVehiclesGeoJSONs);
        return clusterator;
    }, [inTimeVehiclesGeoJSONs]);

    const inTimeClusters = React.useMemo(() => {
        if (config) {
            const clusters = inTimeClusterator.getClusters(config.bounds, config.zoom);
            return clusters;
        }

        return [];
    }, [config, inTimeClusterator]);

    const handleChange = () => {
        if (api) {
            const { zoom } = api.map;

            const bound = api.map.getBounds();

            const northEast = bound.getNorthEast();
            const southWest = bound.getSouthWest();

            setConfig({
                zoom,
                bounds: [southWest.lng(), southWest.lat(), northEast.lng(), northEast.lat()],
            });
        }
    };

    const handleImTimeClusterClick = React.useCallback(
        (clusterId: number) => {
            const { map, maps } = api;

            const clusterFeatures = inTimeClusterator.getLeaves(clusterId, FEATURES_LIMIT, DEFAULT_OFFSET);

            const bounds = new maps.LatLngBounds();

            clusterFeatures.forEach((feature: Supercluster.PointFeature<{}>) => {
                const point = new maps.LatLng(feature.geometry.coordinates[1], feature.geometry.coordinates[0]);

                bounds.extend(point);
            });

            map.fitBounds(bounds);
        },
        [api, inTimeClusterator],
    );

    const handleNotImTimeClusterClick = React.useCallback(
        (clusterId: number) => {
            const { map, maps } = api;

            const clusterFeatures = notInTimeClusterator.getLeaves(clusterId, FEATURES_LIMIT, DEFAULT_OFFSET);

            const bounds = new maps.LatLngBounds();

            clusterFeatures.forEach((feature: Supercluster.PointFeature<{}>) => {
                const point = new maps.LatLng(feature.geometry.coordinates[1], feature.geometry.coordinates[0]);

                bounds.extend(point);
            });

            map.fitBounds(bounds);
        },
        [api, notInTimeClusterator],
    );

    const isShowCalcCostPredictionLoader =
        selfCostPredictionRequestStatus.loading ||
        deadheadRoutingGeometryState.requestStatus?.loading ||
        truckToTrailerRoutingGeometryState.requestStatus?.loading ||
        dropTrailerRoutingGeometryState.requestStatus?.loading ||
        dropTruckRoutingGeometryState.requestStatus?.loading;

    const isShowFindVehiclesLoader = availableVehiclesRequestStatus.loading;

    const isShowFetchTrailerScheduleLoader = !!scheduleAssetById[selectedTrailerId as string]?.requestStatus?.loading;
    const isShowFetchTruckScheduleLoader = !!scheduleAssetById[selectedTruckId as string]?.requestStatus?.loading;
    const isShowFetchAssetScheduleLoader = isShowFetchTrailerScheduleLoader || isShowFetchTruckScheduleLoader;
    const isShowFetchAssignDetailsLoader = fetchAssignDetailsRequest?.loading;

    const trailerSchedule = scheduleAssetById[selectedTrailerId as TrailerIdT] || null;
    const truckSchedule = scheduleAssetById[selectedTruckId as TruckIdT] || null;

    const renderMapCluster = (params: RenderMapClusterParamsT): React.ReactNode => {
        const {
            cluster,
            clusterFeatures,
            selectedTruckId,
            selectedTrailerId,
            firstPickupDeliveryWaypointDate,
            onClusterClick,
            onSelectAssets,
            onHoverAssets,
            canInTime,
        } = params;

        const isCluster = cluster.properties?.cluster;
        if (isCluster) {
            const { cluster_id: clusterId } = cluster.properties || {};

            let trailersCount = 0;
            let hasSelectedTrailer = false;
            let truckCount = 0;
            let hasSelectedTruck = false;
            let linksCount = 0;
            let hasSelectedLink = false;

            clusterFeatures.forEach((clusterFeature) => {
                const { truckId, trailerId } = clusterFeature.properties;

                const isTruck = truckId && !trailerId;
                if (isTruck) {
                    truckCount += 1;
                }
                const isSelectedTruck = selectedTruckId && truckId === selectedTruckId;
                if (isTruck && isSelectedTruck) {
                    hasSelectedTruck = true;
                }

                const isTrailer = !truckId && trailerId;
                if (isTrailer) {
                    trailersCount += 1;
                }
                const isSelectedTrailer = selectedTrailerId && trailerId === selectedTrailerId;
                if (isTrailer && isSelectedTrailer) {
                    hasSelectedTrailer = true;
                }

                const isLink = truckId && trailerId;
                if (isLink) {
                    linksCount += 1;
                }

                if (isLink && (isSelectedTruck || isSelectedTrailer)) {
                    hasSelectedLink = true;
                }
            });

            return (
                <AssetClusterPin
                    key={clusterId}
                    clusterId={clusterId}
                    trucksCount={truckCount}
                    hasSelectedTruck={hasSelectedTruck}
                    trailersCount={trailersCount}
                    hasSelectedTrailer={hasSelectedTrailer}
                    linksCount={linksCount}
                    hasSelectedLink={hasSelectedLink}
                    lng={cluster.geometry.coordinates[0]}
                    lat={cluster.geometry.coordinates[1]}
                    onClick={onClusterClick}
                    canInTime={canInTime}
                />
            );
        }

        const truckId = cluster.properties?.truckId;
        const trailerId = cluster.properties?.trailerId;
        const isSelectedTruck = truckId && truckId === selectedTruckId;
        const isSelectedTrailer = trailerId && trailerId === selectedTrailerId;

        const trailerAssignmentInfo = trailerAssignmentInfoById[trailerId] || null;
        const truckAssignmentInfo = truckAssignmentInfoById[truckId] || null;
        const anyAssignmentInfo = trailerAssignmentInfo || truckAssignmentInfo;

        const truckCarrier = carriersById[truckAssignmentInfo?.carrierId as string] || null;
        const truckHash =
            truckAssignmentInfo?.carrierId && firstPickupDeliveryWaypointDate
                ? getHash(truckAssignmentInfo.carrierId, firstPickupDeliveryWaypointDate)
                : null;
        const truckCarrierUtilization = truckHash ? carriersUtilizationByHash[truckHash] : null;
        const truckCarrierUtilizationPercent = truckCarrierUtilization
            ? truckCarrierUtilization.utilizationPercents
            : null;

        const trailerCarrier = carriersById[trailerAssignmentInfo?.carrierId as string] || null;
        const trailerHash =
            trailerAssignmentInfo?.carrierId && firstPickupDeliveryWaypointDate
                ? getHash(trailerAssignmentInfo.carrierId, firstPickupDeliveryWaypointDate)
                : null;
        const trailerCarrierUtilization = trailerHash ? carriersUtilizationByHash[trailerHash] : null;
        const trailerCarrierUtilizationPercent = trailerCarrierUtilization
            ? trailerCarrierUtilization.utilizationPercents
            : null;

        return (
            <AssetPin
                key={cluster.properties?.id}
                lng={cluster.geometry.coordinates[0]}
                lat={cluster.geometry.coordinates[1]}
                utcOffset={timeZoneContext?.value?.utcOffsetMin}
                arrivalDate={anyAssignmentInfo?.expectedArrivalDate}
                plateNumber={anyAssignmentInfo?.plateNumber}
                truckCarrierUtilizationPercent={truckCarrierUtilizationPercent}
                isLoadingTruckCarrierUtilization={!isNumber(truckCarrierUtilization?.utilizationPercents)}
                truckCarrier={truckCarrier}
                truckId={truckId}
                isLoadingTruckUtilization={false}
                truckUtilization={truckAssignmentInfo?.utilization}
                truckRatePerKm={truckAssignmentInfo?.ratePerKm}
                truckInvalidReason={truckAssignmentInfo?.invalidReason}
                isSelectedTruck={isSelectedTruck}
                trailerId={trailerId}
                isLoadingTrailerUtilization={false}
                trailerUtilization={trailerAssignmentInfo?.utilization}
                trailerRatePerKm={trailerAssignmentInfo?.ratePerKm}
                trailerInvalidReason={trailerAssignmentInfo?.invalidReason}
                trailerCarrierUtilizationPercent={trailerCarrierUtilizationPercent}
                isLoadingTrailerCarrierUtilization={!isNumber(trailerCarrierUtilization?.utilizationPercents)}
                trailerCarrier={trailerCarrier}
                isSelectedTrailer={isSelectedTrailer}
                onClick={onSelectAssets}
                onHover={onHoverAssets}
                canInTime={canInTime}
                isBlockedSelectTrailer={assetsSelectionContext?.value?.isBlockedSelectTrailer}
                isBlockedSelectTruck={assetsSelectionContext?.value?.isBlockedSelectTruck}
            />
        );
    };

    const renderCurrentPosition = (): React.ReactNode[] => {
        const currentAssetPositions: React.ReactNode[] = [];

        if (!userSelection?.isShowActualPlace) {
            return currentAssetPositions;
        }

        if (isSelectedLink) {
            const currentLinkPosition = selectedTruckCurrentPosition;
            if (currentLinkPosition) {
                currentAssetPositions.push(
                    <CurrentAssetPositionPin isLink lat={currentLinkPosition.lat} lng={currentLinkPosition.lng} />,
                );
            }
        } else {
            const currentTruckPosition = selectedTruckCurrentPosition;
            if (currentTruckPosition) {
                currentAssetPositions.push(
                    <CurrentAssetPositionPin isTruck lat={currentTruckPosition.lat} lng={currentTruckPosition.lng} />,
                );
            }

            const currentTrailerPosition = selectedTrailerCurrentPosition;
            if (currentTrailerPosition) {
                currentAssetPositions.push(
                    <CurrentAssetPositionPin
                        isTrailer
                        lat={currentTrailerPosition.lat}
                        lng={currentTrailerPosition.lng}
                    />,
                );
            }
        }

        return currentAssetPositions;
    };

    const renderScheduleEvents = (
        assetType: AssetTypeEnum,
        scheduleEvents: Array<ScheduleEventT> | null,
    ): Array<React.ReactNode> => {
        const result: Array<React.ReactNode> = [];

        if (!userSelection?.isShowAssetSchedule) {
            return result;
        }

        scheduleEvents?.forEach((scheduleEvent, scheduleEventIndex) => {
            const origin = assetType === AssetTypeEnum.truck ? scheduleEvent.truckOrigin : scheduleEvent.trailerOrigin;
            if (origin && isNumber(origin.latitude) && isNumber(origin.longitude)) {
                result.push(
                    <AssetSchedulePin
                        assetType={assetType}
                        isSelected={scheduleEvent?.id === selectedScheduleEventId}
                        lat={origin.latitude}
                        lng={origin.longitude}
                        number={2 * scheduleEventIndex + 1}
                    />,
                );
            }

            const destination =
                assetType === AssetTypeEnum.truck ? scheduleEvent.truckDestination : scheduleEvent.trailerDestination;
            if (destination && isNumber(destination.latitude) && isNumber(destination.longitude)) {
                result.push(
                    <AssetSchedulePin
                        assetType={assetType}
                        isSelected={scheduleEvent?.id === selectedScheduleEventId}
                        lat={destination.latitude}
                        lng={destination.longitude}
                        number={2 * scheduleEventIndex + 2}
                    />,
                );
            }
        });

        return result;
    };

    const [draggablePin, setDraggablePin] = useState<{
        point: GeoPointT;
        childKey: string;
    } | null>(null);

    const handleDragMove = (childKey: string, childProps: any, mouse: GeoPointT) => {
        if (
            childKey !== MAP_PIN_KEY.pointToDropTrailer &&
            childKey !== MAP_PIN_KEY.pointToDropTruck &&
            childKey !== MAP_PIN_KEY.pointToDropLink
        ) {
            return;
        }

        setDraggablePin({
            childKey,
            point: {
                lat: mouse.lat,
                lng: mouse.lng,
            },
        });
    };

    const handleDragEnd = async (childKey: string, childProps: any, mouse: GeoPointT) => {
        const pointToDrop = await geocodeByPoint(googleMapContext, mouse);
        if (!pointToDrop) {
            return;
        }

        const changes: Partial<UserSelectionT> = {};

        if (childKey === MAP_PIN_KEY.pointToDropTrailer) {
            changes.pointToDropTrailer = pointToDrop;
        } else if (childKey === MAP_PIN_KEY.pointToDropTruck) {
            changes.pointToDropTruck = pointToDrop;
        } else if (childKey === MAP_PIN_KEY.pointToDropLink) {
            changes.pointToDropTrailer = pointToDrop;
            changes.pointToDropTruck = pointToDrop;
        }

        if (!isEmpty(changes)) {
            dispatch(changeUserSelection(dispatchId, tourId, changes));
        }

        setDraggablePin(null);
    };

    const handleMapClick = async (event: ClickEventValue) => {
        // TODO improve detect click on map, not pin!
        const isClickOnMap =
            !event?.event?.target?.className &&
            event?.event?.target?.style?.['z-index'] &&
            event?.event?.target?.tagName === 'DIV';
        if (!isClickOnMap) {
            return;
        }

        const pointToDrop = await geocodeByPoint(googleMapContext, event);
        if (!pointToDrop) {
            return;
        }

        syncMapStateContext.onMapClickCallback?.callback?.(pointToDrop);
    };

    const initPayloadDropPoint = useMemo(() => {
        return createInitPayloadDropPoint(dispatchDetails, tourId);
    }, [dispatchDetails, tourId]);

    const shouldShowDropTrailerPoint = useMemo(() => {
        return !isEqual(selectedPointToDropTrailer?.point, initPayloadDropPoint?.location?.point);
    }, [selectedPointToDropTrailer, initPayloadDropPoint]);

    const viewToDropTrailerPoint =
        draggablePin?.childKey === MAP_PIN_KEY.pointToDropTrailer
            ? draggablePin?.point
            : selectedPointToDropTrailer?.point;

    const viewToDropLinkPoint =
        draggablePin?.childKey === MAP_PIN_KEY.pointToDropLink
            ? draggablePin?.point
            : selectedPointToDropTrailer?.point;

    const shouldShowDropTruckPoint = useMemo(() => {
        return !isEqual(selectedPointToDropTruck?.point, initPayloadDropPoint?.location?.point);
    }, [selectedPointToDropTruck, initPayloadDropPoint]);

    const viewToDropTruckPoint =
        draggablePin?.childKey === MAP_PIN_KEY.pointToDropTruck ? draggablePin?.point : selectedPointToDropTruck?.point;

    const isSelectedSameDropOffPoints = useMemo(() => {
        return isEqual(viewToDropTrailerPoint, viewToDropTruckPoint);
    }, [viewToDropTrailerPoint, viewToDropTruckPoint]);

    return (
        <>
            <GoogleMapReact
                key="assignment"
                draggable={!draggablePin}
                defaultCenter={DEFAULT_CENTER}
                defaultZoom={DEFAULT_ZOOM}
                bootstrapURLKeys={BOOTSTRAP_URL_KEYS}
                options={MAP_OPTIONS}
                onGoogleApiLoaded={apiIsLoaded}
                onDragEnd={handleChange}
                onZoomAnimationEnd={handleChange}
                yesIWantToUseGoogleMapApiInternals
                onChildMouseDown={handleDragMove}
                onChildMouseUp={handleDragEnd}
                onChildMouseMove={handleDragMove}
                onClick={handleMapClick}
            >
                {waypoints.map((waypoint, index) => {
                    if (!waypoint) {
                        return null;
                    }

                    if (!AVAILABLE_STOP_SET.has(waypoint.type)) {
                        return null;
                    }

                    let fillColor = StyleGuideColorsEnum.charcoal;
                    if (waypoint.type === StopTypeEnum.driveThrough) {
                        fillColor = StyleGuideColorsEnum.gray;
                    }

                    return (
                        <NumberPinIcon
                            number={(waypoint.index || 0) + 1}
                            key={`route-point-${waypoint.index}-${index}`}
                            className={cx('route-point-pin')}
                            fillColor={fillColor}
                            lng={waypoint?.address?.longitude}
                            lat={waypoint?.address?.latitude}
                        />
                    );
                })}
                {viewToDropLinkPoint &&
                    shouldShowDropTruckPoint &&
                    shouldShowDropTrailerPoint &&
                    isSelectedSameDropOffPoints && (
                        <AssetDropOffLocationPin
                            assetType={null}
                            key={MAP_PIN_KEY.pointToDropLink}
                            className={cx('location')}
                            lat={viewToDropLinkPoint.lat}
                            lng={viewToDropLinkPoint.lng}
                            hasError={
                                !!syncMapStateContext?.hasDropTrailerPointError ||
                                !!syncMapStateContext?.hasDropTruckPointError
                            }
                        />
                    )}
                {viewToDropTrailerPoint && shouldShowDropTrailerPoint && !isSelectedSameDropOffPoints && (
                    <AssetDropOffLocationPin
                        assetType={AssetTypeEnum.trailer}
                        key={MAP_PIN_KEY.pointToDropTrailer}
                        className={cx('location')}
                        lat={viewToDropTrailerPoint.lat}
                        lng={viewToDropTrailerPoint.lng}
                        hasError={!!syncMapStateContext?.hasDropTrailerPointError}
                    />
                )}
                {viewToDropTruckPoint && shouldShowDropTruckPoint && !isSelectedSameDropOffPoints && (
                    <AssetDropOffLocationPin
                        assetType={AssetTypeEnum.truck}
                        key={MAP_PIN_KEY.pointToDropTruck}
                        className={cx('location')}
                        lat={viewToDropTruckPoint.lat}
                        lng={viewToDropTruckPoint.lng}
                        hasError={!!syncMapStateContext?.hasDropTruckPointError}
                    />
                )}
                {...renderCurrentPosition()}
                {notInTimeClusters.map((cluster) => {
                    const { cluster_id: clusterId } = cluster.properties || {};

                    const isCluster = cluster.properties?.cluster;

                    const clusterFeatures = isCluster
                        ? notInTimeClusterator.getLeaves(clusterId, FEATURES_LIMIT, DEFAULT_OFFSET)
                        : [];

                    return renderMapCluster({
                        cluster,
                        clusterFeatures,
                        selectedTruckId,
                        selectedTrailerId,
                        firstPickupDeliveryWaypointDate,
                        onClusterClick: handleNotImTimeClusterClick,
                        onSelectAssets: handleSelectAssets,
                        onHoverAssets: handleHoverAssets,
                        canInTime: false,
                    });
                })}
                {inTimeClusters.map((cluster) => {
                    const { cluster_id: clusterId } = cluster.properties || {};

                    const isCluster = cluster.properties?.cluster;

                    const clusterFeatures = isCluster
                        ? inTimeClusterator.getLeaves(clusterId, FEATURES_LIMIT, DEFAULT_OFFSET)
                        : [];

                    return renderMapCluster({
                        cluster,
                        clusterFeatures,
                        selectedTruckId,
                        selectedTrailerId,
                        firstPickupDeliveryWaypointDate,
                        onClusterClick: handleImTimeClusterClick,
                        onSelectAssets: handleSelectAssets,
                        onHoverAssets: handleHoverAssets,
                        canInTime: true,
                    });
                })}
                {...renderScheduleEvents(AssetTypeEnum.trailer, trailerSchedule?.events)}
                {...renderScheduleEvents(AssetTypeEnum.truck, truckSchedule?.events)}
            </GoogleMapReact>
            <MapArea
                map={api?.map}
                maps={api?.maps}
                theme={MapAreaThemeEnum.assignmentFindArea}
                centerLat={firstPayloadWaypoint?.address?.latitude}
                centerLon={firstPayloadWaypoint?.address?.longitude}
                radiusM={query?.radiusKm ? query.radiusKm * M_IN_KM : null}
            />
            {!!selfCostPrediction?.trailerToPickupPolylineId && (
                <MapRoute
                    key="deadheadCoordinates"
                    map={api?.map}
                    maps={api?.maps}
                    geometryLibrary={googleMapContext?.googleMaps?.libraries?.geometry}
                    polylines={deadheadRoutingGeometryState.data}
                    theme={MapRouteThemeEnum.assignmentAttention}
                    zIndex={1}
                />
            )}
            {!!selfCostPrediction?.truckToTrailerPolylineId && (
                <MapRoute
                    key="truckToTrailerCoordinates"
                    map={api?.map}
                    maps={api?.maps}
                    geometryLibrary={googleMapContext?.googleMaps?.libraries?.geometry}
                    polylines={truckToTrailerRoutingGeometryState.data}
                    theme={MapRouteThemeEnum.assignmentAttention}
                    zIndex={2}
                />
            )}
            {!!selfCostPrediction?.dropTrailerPolylineId && (
                <MapRoute
                    key="dropTrailersCoordinates"
                    map={api?.map}
                    maps={api?.maps}
                    geometryLibrary={googleMapContext?.googleMaps?.libraries?.geometry}
                    polylines={dropTrailerRoutingGeometryState.data}
                    theme={MapRouteThemeEnum.assignmentAttention}
                    zIndex={3}
                />
            )}
            {!!selfCostPrediction?.dropTruckPolylineId && (
                <MapRoute
                    key="dropTruckCoordinates"
                    map={api?.map}
                    maps={api?.maps}
                    geometryLibrary={googleMapContext?.googleMaps?.libraries?.geometry}
                    polylines={dropTruckRoutingGeometryState.data}
                    theme={MapRouteThemeEnum.assignmentAttention}
                    zIndex={4}
                />
            )}
            <MapRoute
                map={api?.map}
                maps={api?.maps}
                geometryLibrary={googleMapContext?.googleMaps?.libraries?.geometry}
                polylines={payloadGeometryState.data}
                key="route"
                theme={
                    selectedScheduleEventId === CURRENT_ASSIGNMENT_EVENT_ID
                        ? MapRouteThemeEnum.assignmentSelected
                        : MapRouteThemeEnum.assignmentRoute
                }
                zIndex={5}
            />
            {userSelection?.isShowAssetSchedule &&
                trailerSchedule?.events?.map((scheduleEvent) => {
                    const route = trailerSchedule?.routeById[scheduleEvent?.id as ScheduleEventIdT] || [];

                    return (
                        <MapRoute
                            key={`trailer-route-${scheduleEvent?.id}`}
                            map={api?.map}
                            maps={api?.maps}
                            geometryLibrary={googleMapContext?.googleMaps?.libraries?.geometry}
                            polylines={route}
                            theme={
                                selectedScheduleEventId === scheduleEvent?.id
                                    ? MapRouteThemeEnum.assignmentSelected
                                    : MapRouteThemeEnum.assignmentRoute
                            }
                            zIndex={6}
                        />
                    );
                })}
            {userSelection?.isShowAssetSchedule &&
                truckSchedule?.events?.map((scheduleEvent) => {
                    const route = truckSchedule?.routeById[scheduleEvent?.id as ScheduleEventIdT] || [];

                    return (
                        <MapRoute
                            key={`truck-route-${scheduleEvent?.id}`}
                            map={api?.map}
                            maps={api?.maps}
                            geometryLibrary={googleMapContext?.googleMaps?.libraries?.geometry}
                            polylines={route}
                            theme={
                                selectedScheduleEventId === scheduleEvent?.id
                                    ? MapRouteThemeEnum.assignmentSelected
                                    : MapRouteThemeEnum.assignmentRoute
                            }
                            zIndex={7}
                        />
                    );
                })}
            <div className={cx('control')}>
                <SearchForm dispatchId={dispatchId} dispatchDetails={dispatchDetails} tourId={tourId} />
                {(isShowCalcCostPredictionLoader || isShowFindVehiclesLoader || isShowFetchAssetScheduleLoader) && (
                    <div className={cx('loader__container')}>
                        {isShowCalcCostPredictionLoader && (
                            <div className={cx('loader')}>
                                <Loader title={t('assignment.wait-deadhead')} />
                            </div>
                        )}
                        {isShowFindVehiclesLoader && (
                            <div className={cx('loader')}>
                                <Loader title={t('assignment.wait-available-assets')} />
                            </div>
                        )}
                        {isShowFetchAssetScheduleLoader && (
                            <div className={cx('loader')}>
                                <Loader title={t('assignment.wait-asset-schedule')} />
                            </div>
                        )}
                        {isShowFetchAssignDetailsLoader && (
                            <div className={cx('loader')}>
                                <Loader title={t('assignment.wait-fetch-assign-details')} />
                            </div>
                        )}
                    </div>
                )}
            </div>
        </>
    );
});

export default AssignmentMap;
