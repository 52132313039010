import { ApiEditOrderContextT } from 'broker-admin/utils/api/broker-tranziit/models';
import { EditOrderQueryModifyChannelEventT, RoutePointIdByStopIndexT } from 'broker-admin/store/order-edit/models';
import isNumber from 'lodash/isNumber';

type CorrectedTimeWindowsT = NonNullable<EditOrderQueryModifyChannelEventT['correctedTimeWindows']>;

export const getCorrectedTimeWindows = (
    context: ApiEditOrderContextT | null,
    routePointIdByStopIndex: RoutePointIdByStopIndexT,
): CorrectedTimeWindowsT => {
    const stops =
        context?.tours?.flatMap((tour) => {
            return tour?.stops || [];
        }) || [];

    return stops.reduce<CorrectedTimeWindowsT>((acc, stop) => {
        const { index, correctedFrom, correctedTo } = stop;

        if (!isNumber(index)) {
            return acc;
        }

        const routePointId = routePointIdByStopIndex[index] || null;
        if (routePointId && correctedFrom && correctedTo) {
            acc[routePointId] = {
                start: correctedFrom,
                end: correctedTo,
            };
        }

        return acc;
    }, {});
};
