import React, { useContext } from 'react';
import classNames from 'classnames/bind';

import styles from './RouteEditForm.scss';
import { Trans, useTranslation } from 'react-i18next';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import { useDispatch, useSelector } from 'react-redux';

import { MS_IN_HOUR, MS_IN_SEC } from 'common/utils/time';

import { FieldsEnum, FormValuesT, RoutePointFieldsEnum, RoutePointFormValuesT } from './constants';
import validateForm from './validate-form';
import { FormikContext, useFormik } from 'formik';
import asyncValidations from './async-validations';
import ButtonTimer from 'common/components/ButtonTimer/ButtonTimer';
import ScrollToFirstError from 'common/components/ScrollToFirstError/ScrollToFirstError';
import FooterSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/FooterSideBarLayout/FooterSideBarLayout';

import { fetchCountrySettingsDict } from 'common/store/country-settings-dict/actions';
import { selectCountrySettingsByCountryCode } from 'common/store/country-settings-dict/selectors';
import { StyleGuideColorsEnum } from 'common/constants';
import Alert, { AlertSizeEnum, AlertThemeEnum } from 'common/components/Alert/Alert';
import Checkbox, { CheckboxThemeEnum } from 'design-system/components/Checkbox/Checkbox';
import TimeWindowIcon from 'common/icons/TimeWindowIcon';
import {
    ApiTourUpdateStopByRoutePointIdT,
    EditOrderContextT,
    RoutePointIdByStopIndexT,
    RoutePointTypeEnum,
} from 'broker-admin/store/order-edit/models';
import PriceDetails, { PriceDetailT } from 'design-system/components/PriceDetails/PriceDetails';
import EuroSymbolIcon from 'common/icons/EuroSymbolIcon';
import isNumber from 'lodash/isNumber';
import {
    selectChangeContextError,
    selectIsApplyingContextChanges,
    selectOrderEditApplyContextRequest,
    selectOrderEditContextTourUpdateStopByRoutePointId,
    selectOrderEditCreateContextRequest,
    selectOrderEditDeleteContextRequest,
    selectOrderEditFakeSessionId,
} from 'broker-admin/store/order-edit/selectors';
import PickupOrDeliveryRoutePointPicker from './PickupOrDeliveryRoutePointPicker/PickupOrDeliveryRoutePointPicker';
import DriveThroughRoutePointPicker from './DriveThroughRoutePointPicker/DriveThroughRoutePointPicker';
import { DividerWithDropdownControl } from 'design-system/components/DividerWithDropdownControl/DividerWithDropdownControl';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import DropdownControlOptionLabel from 'design-system/components/dropdowns/option/DropdownControlOptionLabel/DropdownControlOptionLabel';
import CircleIcon from 'common/icons/CircleIcon';
import FormikFieldArray from 'common/components/forms/FormikFieldArray/FormikFieldArray';
import SplitIcon from 'common/icons/SplitIcon';
import {
    checkIsAvailableRoutePoint,
    checkIsAllowToUpdateAddress,
    checkIsPointFakeId,
    generatePointFakeId,
    getRoutePointFieldName,
    checkIsAllowToDelete,
    checkIsAllowToUpdateTimeSlots,
    getRoutePointTimeWindowHash,
} from './utils';
import {
    applyOrderEditContextRequest,
    changeContext,
    reCreateOrderEditContextRequest,
    refreshOrderEditContextRequest,
    setContextTourUpdateStopByRoutePointId,
} from 'broker-admin/store/order-edit/slice';
import getInitialValues from './get-initial-values';
import keyBy from 'lodash/keyBy';
import { OnChangeRoutePointAddressT, OnChangeRoutePointTimeWindowT, OnDeleteRoutePointReserveT } from './models';
import DropAndHookRoutePointPicker from 'broker-admin/layouts/DispatchesPage/DispatchRouteEditPage/RouteEditForm/DropAndHookRoutePointPicker/DropAndHookRoutePointPicker';
import { useChannelSubscribe } from 'common/utils/hooks/useChannelSubscribe';
import { orderEditQueryModifyChannel } from 'broker-admin/store/order-edit/channels';
import { InferChannelEventT } from 'common/utils/view-event-channel';
import isEmpty from 'lodash/isEmpty';
import {
    MapRoutePointT,
    OnDragEndDnDCallbackT,
    OnMapClickCallbackT,
    SyncRouteEditFormMapStateContext,
} from 'broker-admin/layouts/DispatchesPage/DispatchRouteEditPage/contexts/sync-map-state';
import { BoundPointT } from 'common/components/maps/MapBound/MapBound';
import { getFieldFormValue } from 'common/utils/form-values';
import { prepareLocation } from 'common/utils/prepare-location';
import getBoundingBox from 'common/utils/get-bounding-box';
import { isNonNil } from 'common/utils';
import { TooltipPositionEnum } from 'design-system/components/Tooltip/Tooltip';
import useDocumentVisibilityChange from 'common/utils/hooks/useDocumentVisibilityChange';
import {
    checkIsTranziitApiRequestError,
    TranziitApiRequestErrorSubTypeEnum,
} from 'common/utils/api/tranziit/errors/tranziit-api-errors';
import InfoIcon from 'common/icons/InfoIcon';
import get from 'lodash/get';

const cx = classNames.bind(styles);

type PropsT = {
    dispatchId: DispatchIdT | null;
    context: EditOrderContextT | null;
    onCancel: () => void;
};

const RouteEditForm: React.FC<PropsT> = (props) => {
    const {
        onCancel,
        dispatchId,
        context, // use context only for initialization!
    } = props;

    const fakeSessionId = useSelector(selectOrderEditFakeSessionId);

    const createContextRequest = useSelector(selectOrderEditCreateContextRequest);
    const deleteContextRequest = useSelector(selectOrderEditDeleteContextRequest);
    const applyContextRequest = useSelector(selectOrderEditApplyContextRequest);
    const isApplyingContextChanges = useSelector(selectIsApplyingContextChanges);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [isConfirmedSubmit, setIsConfirmedSubmitSubmit] = React.useState<boolean>(false);
    const toggleIsConfirmedSubmit = React.useCallback(() => {
        setIsConfirmedSubmitSubmit((isConfirmed) => !isConfirmed);
    }, []);

    const countriesSettingsByCode = useSelector(selectCountrySettingsByCountryCode);
    React.useEffect(() => {
        dispatch(fetchCountrySettingsDict());
    }, []);

    const validate = React.useMemo(() => {
        return (values: FormValuesT) =>
            validateForm(t, values, {
                countriesSettingsByCode,
            });
    }, [t, countriesSettingsByCode]);

    const contextId = context?.contextId || null;
    const hasContext = !!context;

    const [initialValues, initialErrors] = React.useMemo(() => {
        const values = getInitialValues(context);
        const errors = validateForm(t, values, { countriesSettingsByCode });

        return [values, errors];
    }, [hasContext, contextId, fakeSessionId]);

    const [isShowExpirationAlert, setIsShowExpirationAlert] = React.useState<boolean>(false);
    const handleCloseExpirationAlert = React.useCallback(() => {
        setIsShowExpirationAlert(false);
    }, []);

    const changeContextError = useSelector(selectChangeContextError);
    const applyContextRequestError = applyContextRequest?.error || null;
    React.useLayoutEffect(() => {
        const error = changeContextError || applyContextRequestError || null;
        if (
            error &&
            checkIsTranziitApiRequestError(error) &&
            error?.subType === TranziitApiRequestErrorSubTypeEnum.dispatchOrderEdit_ExpirationContext
        ) {
            setIsShowExpirationAlert(true);
        }
    }, [changeContextError, applyContextRequestError]);

    const oldTourUpdateStopById = React.useMemo(() => {
        const allStops =
            context?.oldTourData?.flatMap((tour) => {
                return tour?.stops || [];
            }) || [];

        return keyBy(allStops, 'id');
    }, [context]);

    const contextTourUpdateStopByRoutePointId = useSelector(selectOrderEditContextTourUpdateStopByRoutePointId);
    React.useEffect(() => {
        if (contextTourUpdateStopByRoutePointId) {
            return;
        }

        const initialRoute = initialValues?.[FieldsEnum.route] || [];
        if (!context || !initialRoute?.length) {
            return;
        }

        const initTourUpdateStopByRoutePointId: ApiTourUpdateStopByRoutePointIdT = {};

        const allStops =
            context?.tours?.flatMap((tour) => {
                return tour?.stops || [];
            }) || [];

        const stopByIndex = keyBy(allStops, 'index');

        initialRoute?.forEach((routePointValues, index) => {
            const id = routePointValues[RoutePointFieldsEnum.id];

            const tourUpdateStop = stopByIndex[index] || null;
            if (tourUpdateStop) {
                initTourUpdateStopByRoutePointId[id] = tourUpdateStop;
            }
        });

        dispatch(
            setContextTourUpdateStopByRoutePointId({
                contextTourUpdateStopByRoutePointId: initTourUpdateStopByRoutePointId,
            }),
        );
    }, [contextTourUpdateStopByRoutePointId, context, initialValues]);

    const formik = useFormik<FormValuesT>({
        enableReinitialize: true,
        validateOnBlur: false,
        initialErrors,
        initialValues,
        validate,
        onSubmit: (values, formikHelpers): void => {
            dispatch(applyOrderEditContextRequest({}));

            formikHelpers.setTouched({});
        },
    });

    const route = formik.values[FieldsEnum.route];
    const routePointIdByStopIndex = React.useMemo(() => {
        return route.reduce<RoutePointIdByStopIndexT>((acc, routePointValues, index) => {
            acc[index] = routePointValues[RoutePointFieldsEnum.id];

            return acc;
        }, {});
    }, [route]);

    const documentVisibilityChangeHandler = React.useCallback(() => {
        dispatch(refreshOrderEditContextRequest({ dispatchId, routePointIdByStopIndex }));
    }, [dispatchId, routePointIdByStopIndex]);
    useDocumentVisibilityChange(documentVisibilityChangeHandler);

    const syncMapStateContext = useContext(SyncRouteEditFormMapStateContext);
    React.useEffect(() => {
        const routePoints = route.map((routePointValues, index): MapRoutePointT => {
            const id = routePointValues[RoutePointFieldsEnum.id];
            const type = routePointValues[RoutePointFieldsEnum.type];
            const location = routePointValues[RoutePointFieldsEnum.location];

            const tourUpdateStop = contextTourUpdateStopByRoutePointId?.[id] || null;
            const isAllowUpdateAddress = checkIsAllowToUpdateAddress(tourUpdateStop);

            const locationFieldName = `${FieldsEnum.route}.${index}.${RoutePointFieldsEnum.location}` as const;
            const hasError = !!get(formik.errors, locationFieldName);

            return {
                id,
                isNew: checkIsPointFakeId(id),
                type,
                number: index + 1,
                location,
                isDraggable: isAllowUpdateAddress,
                hasError,
            };
        });

        syncMapStateContext.setMapRoutePoints(routePoints);
    }, [route, contextTourUpdateStopByRoutePointId, formik.errors]);

    React.useEffect(() => {
        const boundPoints: Array<BoundPointT> = [];

        const initialRoute = initialValues[FieldsEnum.route] || [];

        initialRoute?.forEach((mapRoutePoint) => {
            boundPoints.push([mapRoutePoint?.location?.point?.lat, mapRoutePoint?.location?.point?.lng]);
        });

        syncMapStateContext?.onSetMapBoundingBox?.callback?.(boundPoints);
    }, [initialValues, syncMapStateContext?.onSetMapBoundingBox]);

    const getRouteStopIndex = React.useCallback(
        (routePointIndex: number) => {
            const limitRoute = route.slice(0, routePointIndex);
            return limitRoute.filter(checkIsAvailableRoutePoint)?.length;
        },
        [route],
    );

    const { setFieldValue, values } = formik;

    const onMapClickCallback: OnMapClickCallbackT = React.useCallback(
        (location) => {
            const route = values[FieldsEnum.route] || [];

            const firstEmptyLocationRoutePointIndex = route.findIndex((routePoint) => {
                return !checkIsAvailableRoutePoint(routePoint);
            });

            if (firstEmptyLocationRoutePointIndex === -1) {
                return;
            }

            const locationFieldName =
                `${FieldsEnum.route}.${firstEmptyLocationRoutePointIndex}.${RoutePointFieldsEnum.location}` as const;
            setFieldValue(locationFieldName, location);

            const idFieldName =
                `${FieldsEnum.route}.${firstEmptyLocationRoutePointIndex}.${RoutePointFieldsEnum.id}` as const;
            const routePointId = getFieldFormValue(values, idFieldName);

            const typeFieldName =
                `${FieldsEnum.route}.${firstEmptyLocationRoutePointIndex}.${RoutePointFieldsEnum.type}` as const;
            const routePointType = getFieldFormValue(values, typeFieldName);

            const preparedLocation = prepareLocation(location);
            if (!preparedLocation) {
                return;
            }

            dispatch(
                changeContext({
                    type: 'change-route-point-location',
                    routePointId,
                    routePointIdByStopIndex,
                    routeStopIndex: getRouteStopIndex(firstEmptyLocationRoutePointIndex),
                    routePointType,
                    address: preparedLocation,
                }),
            );
        },
        [values, getRouteStopIndex, routePointIdByStopIndex, setFieldValue],
    );

    React.useEffect(() => {
        syncMapStateContext?.setOnMapClickCallback({ callback: onMapClickCallback });
    }, [onMapClickCallback, syncMapStateContext?.setOnMapClickCallback]);

    const onDragEndDnDCallback: OnDragEndDnDCallbackT = React.useCallback(
        (id, location) => {
            const route = values[FieldsEnum.route] || [];

            const index = route?.findIndex((routePointValues) => {
                return id === routePointValues[RoutePointFieldsEnum.id];
            });

            const locationFieldName = `${FieldsEnum.route}.${index}.${RoutePointFieldsEnum.location}` as const;
            setFieldValue(locationFieldName, location);

            const idFieldName = `${FieldsEnum.route}.${index}.${RoutePointFieldsEnum.id}` as const;
            const routePointId = getFieldFormValue(values, idFieldName);

            const typeFieldName = `${FieldsEnum.route}.${index}.${RoutePointFieldsEnum.type}` as const;
            const routePointType = getFieldFormValue(values, typeFieldName);

            const preparedLocation = prepareLocation(location);
            if (!preparedLocation) {
                return;
            }

            dispatch(
                changeContext({
                    type: 'change-route-point-location',
                    routePointId,
                    routePointIdByStopIndex,
                    routeStopIndex: getRouteStopIndex(index),
                    routePointType,
                    address: preparedLocation,
                }),
            );
        },
        [values, getRouteStopIndex, setFieldValue],
    );

    React.useEffect(() => {
        syncMapStateContext?.setOnDragEndDnDCallback({ callback: onDragEndDnDCallback });
    }, [onDragEndDnDCallback, syncMapStateContext?.setOnDragEndDnDCallback]);

    const asyncErrors = React.useMemo(() => {
        return asyncValidations(t, formik.values, {});
    }, [t, formik.values]);

    const refreshOrderEditModifyHandler = React.useCallback(
        (event: InferChannelEventT<typeof orderEditQueryModifyChannel>) => {
            const formValuesChanges: Partial<FormValuesT> = {};

            if (!isEmpty(event?.correctedTimeWindows)) {
                const routeFormValues = formik.values[FieldsEnum.route];

                formValuesChanges[FieldsEnum.route] = routeFormValues.map((routePointFormValues) => {
                    const id = routePointFormValues[RoutePointFieldsEnum.id];
                    const timeWindow = routePointFormValues[RoutePointFieldsEnum.timeWindow];
                    const correctedTimeWindow = event?.correctedTimeWindows?.[id];

                    if (correctedTimeWindow) {
                        return {
                            ...routePointFormValues,
                            [RoutePointFieldsEnum.timeWindow]: correctedTimeWindow || timeWindow,
                        };
                    }

                    return routePointFormValues;
                });
            }

            if (!isEmpty(formValuesChanges)) {
                formik.setValues((prevValues) => ({
                    ...prevValues,
                    ...formValuesChanges,
                }));
            }
        },
        [formik],
    );
    useChannelSubscribe(orderEditQueryModifyChannel, refreshOrderEditModifyHandler);

    const handleExpire = React.useCallback(() => {
        setIsShowExpirationAlert(true);

        dispatch(reCreateOrderEditContextRequest({ dispatchId }));
    }, [dispatchId]);

    const oldTotalCost = context?.oldTourData?.[0]?.carrierEstimate?.totalCost;
    const oldPricesList: Array<PriceDetailT | null> = React.useMemo(() => {
        return [
            {
                iconNode: <EuroSymbolIcon strokeColor={StyleGuideColorsEnum.gray} />,
                title: t('dispatch-route-edit.footer.prices.original-carrier-cost'),
                price: oldTotalCost,
            },
        ];
    }, [oldTotalCost]);

    const newTotalCost = context?.newTotalCarrierEstimate?.totalCost;
    const newPricesList: Array<PriceDetailT | null> = React.useMemo(() => {
        return [
            {
                iconNode: <EuroSymbolIcon strokeColor={StyleGuideColorsEnum.gray} />,
                title: t('dispatch-route-edit.footer.prices.new-carrier-cost'),
                price: context?.newTotalCarrierEstimate?.totalCost,
                priceDiff: isNumber(newTotalCost) && isNumber(oldTotalCost) ? newTotalCost - oldTotalCost : null,
                isLoading: isApplyingContextChanges,
            },
        ];
    }, [oldTotalCost, newTotalCost, isApplyingContextChanges]);

    const isChangedShipperTimeSlots = React.useMemo(() => {
        const allStops =
            context?.oldTourData?.flatMap((tour) => {
                return tour?.stops || [];
            }) || [];
        const oldTourUpdateStopById = keyBy(allStops, 'id');

        const route = values[FieldsEnum.route] || [];
        return route.some((routePointValues) => {
            const id = routePointValues[RoutePointFieldsEnum.id];
            if (checkIsPointFakeId(id) || !id) {
                return false;
            }

            const oldTourUpdateStop = oldTourUpdateStopById?.[id] || null;
            if (!oldTourUpdateStop) {
                return false;
            }

            return (
                getRoutePointTimeWindowHash(
                    oldTourUpdateStop?.originalDateTimeFrom,
                    oldTourUpdateStop?.originalDateTimeTo,
                ) !==
                getRoutePointTimeWindowHash(
                    routePointValues[RoutePointFieldsEnum.timeWindow]?.start,
                    routePointValues[RoutePointFieldsEnum.timeWindow]?.end,
                )
            );
        });
    }, [values, context]);

    const isLoadingSubmit = createContextRequest.loading || applyContextRequest.loading || deleteContextRequest.loading;
    const isDisabledSubmit =
        isLoadingSubmit || isApplyingContextChanges || (isChangedShipperTimeSlots ? !isConfirmedSubmit : false);

    const delayedFocusOnLocationInput = (pointIndex: number, id: string) => {
        setTimeout(async () => {
            const locationName = getRoutePointFieldName(pointIndex, RoutePointFieldsEnum.location);
            const inputNodeSelector = `div[data-point-id="${id}"] input[name="${locationName}"]`;

            let inputNode = null;
            while (!inputNode) {
                inputNode = document.querySelector(inputNodeSelector);

                // @ts-expect-error
                if (inputNode?.focus) {
                    // @ts-expect-error
                    inputNode?.focus();
                }

                const DELAY = MS_IN_SEC;
                await new Promise<void>((resolve) => {
                    setTimeout(() => {
                        resolve();
                    }, DELAY);
                });
            }
        }, 0);
    };

    const handleManualSelectLocation = (location: LocationT | null, routePointIndex: number) => {
        if (!location) {
            return;
        }

        const route = formik.values[FieldsEnum.route] || [];

        const currentLocations = route.map((routePointValues) => routePointValues[RoutePointFieldsEnum.location]);
        currentLocations[routePointIndex] = location;

        const points = currentLocations.map((location) => location?.point).filter(isNonNil);

        const boundingBox = getBoundingBox(points);
        syncMapStateContext?.onSetMapBoundingBox?.callback?.(boundingBox);
    };

    const handleChangeRoutePointAddress: OnChangeRoutePointAddressT = (routePointIndex, changes) => {
        dispatch(
            changeContext({
                type: 'change-route-point-location',
                routePointId: changes.id,
                routePointIdByStopIndex,
                routeStopIndex: getRouteStopIndex(routePointIndex),
                routePointType: changes.routePointType,
                address: changes.address,
            }),
        );
    };
    const handleChangeRoutePointTimeWindow: OnChangeRoutePointTimeWindowT = (routePointIndex, changes) => {
        dispatch(
            changeContext({
                type: 'change-route-point-time-window',
                routePointId: changes.id,
                routePointIdByStopIndex,
                routeStopIndex: getRouteStopIndex(routePointIndex),
                timeWindow: changes.timeWindow,
                address: changes.address,
            }),
        );
    };

    const handleRemoveRoutePointReserve: OnDeleteRoutePointReserveT = (routePointIndex, routePointId) => {
        dispatch(
            changeContext({
                type: 'delete-route-point',
                routePointId,
                routePointIdByStopIndex,
                routeStopIndex: getRouteStopIndex(routePointIndex),
            }),
        );
    };

    const footerTopNode = React.useMemo(() => {
        if (isShowExpirationAlert) {
            return (
                <Alert
                    theme={AlertThemeEnum.orange}
                    className={cx('context-expiration-alert')}
                    icon={<InfoIcon fillColor={StyleGuideColorsEnum.white} />}
                    size={AlertSizeEnum.medium}
                    onClose={handleCloseExpirationAlert}
                >
                    <Trans i18nKey="dispatch-route-edit.validate-messages.context-expiration" components={{}} />
                </Alert>
            );
        }

        return null;
    }, [isShowExpirationAlert, handleCloseExpirationAlert]);

    return (
        <div className={cx('wrap')}>
            <FormikContext.Provider value={formik}>
                <form onSubmit={formik.handleSubmit}>
                    <div className={cx('form')}>
                        <FormikFieldArray<FormValuesT, typeof FieldsEnum.route> name={FieldsEnum.route}>
                            {(values, { remove, insert }) => {
                                return (
                                    <>
                                        {values.map((value, index) => {
                                            const isFirst = index === 0;
                                            const isLast = index === values.length - 1;

                                            const routePointType = value[RoutePointFieldsEnum.type];
                                            const routePointId = value[RoutePointFieldsEnum.id];

                                            const tourUpdateStop =
                                                contextTourUpdateStopByRoutePointId?.[routePointId] || null;
                                            const oldTourUpdateStop = oldTourUpdateStopById?.[routePointId] || null;

                                            const isAllowUpdateTimeWindows =
                                                checkIsAllowToUpdateTimeSlots(tourUpdateStop);
                                            const isAllowUpdateAddress = checkIsAllowToUpdateAddress(tourUpdateStop);
                                            const isAllowRemove = checkIsAllowToDelete(tourUpdateStop);

                                            return (
                                                <>
                                                    {routePointType === RoutePointTypeEnum.pickupOrDelivery && (
                                                        <PickupOrDeliveryRoutePointPicker
                                                            key={`point-${routePointId}`}
                                                            className={cx('route-point-fields')}
                                                            isFirstRoutePointIndex={isFirst}
                                                            isLastRoutePointIndex={isLast}
                                                            routePointIndex={index}
                                                            tourUpdateStop={tourUpdateStop}
                                                            oldTourUpdateStop={oldTourUpdateStop}
                                                            isAllowUpdateAddress={isAllowUpdateAddress}
                                                            isAllowUpdateTimeWindows={isAllowUpdateTimeWindows}
                                                            onChangeRoutePointAddress={handleChangeRoutePointAddress}
                                                            onChangeRoutePointTimeWindow={
                                                                handleChangeRoutePointTimeWindow
                                                            }
                                                            isAllowRemove={isAllowRemove}
                                                            onRemove={(routePointIndex, routePointId) => {
                                                                handleRemoveRoutePointReserve(
                                                                    routePointIndex,
                                                                    routePointId,
                                                                );

                                                                remove(index);
                                                            }}
                                                            onManualSelectLocation={handleManualSelectLocation}
                                                        />
                                                    )}
                                                    {routePointType === RoutePointTypeEnum.dropAndHook && (
                                                        <DropAndHookRoutePointPicker
                                                            key={`point-${routePointId}`}
                                                            className={cx('route-point-fields')}
                                                            isFirstRoutePointIndex={isFirst}
                                                            isLastRoutePointIndex={isLast}
                                                            routePointIndex={index}
                                                            tourUpdateStop={tourUpdateStop}
                                                            oldTourUpdateStop={oldTourUpdateStop}
                                                            isAllowUpdateAddress={isAllowUpdateAddress}
                                                            isAllowUpdateTimeWindows={isAllowUpdateTimeWindows}
                                                            onChangeRoutePointAddress={handleChangeRoutePointAddress}
                                                            onChangeRoutePointTimeWindow={
                                                                handleChangeRoutePointTimeWindow
                                                            }
                                                            isAllowRemove={isAllowRemove}
                                                            onRemove={(routePointIndex, routePointId) => {
                                                                handleRemoveRoutePointReserve(
                                                                    routePointIndex,
                                                                    routePointId,
                                                                );

                                                                remove(index);
                                                            }}
                                                            onManualSelectLocation={handleManualSelectLocation}
                                                        />
                                                    )}
                                                    {routePointType === RoutePointTypeEnum.driveThrough && (
                                                        <DriveThroughRoutePointPicker
                                                            key={`point-${routePointId}`}
                                                            className={cx('route-point-fields')}
                                                            routePointIndex={index}
                                                            tourUpdateStop={tourUpdateStop}
                                                            oldTourUpdateStop={oldTourUpdateStop}
                                                            isAllowUpdateAddress={isAllowUpdateAddress}
                                                            onChangeRoutePointAddress={handleChangeRoutePointAddress}
                                                            onManualSelectLocation={handleManualSelectLocation}
                                                            isAllowRemove={isAllowRemove}
                                                            onRemove={(routePointIndex, routePointId) => {
                                                                handleRemoveRoutePointReserve(
                                                                    routePointIndex,
                                                                    routePointId,
                                                                );
                                                                remove(routePointIndex);
                                                            }}
                                                        />
                                                    )}
                                                    {!isLast && (
                                                        <DividerWithDropdownControl
                                                            key={`divider-with-dropdown-${index}`}
                                                            className={cx('route-points-divider')}
                                                            overlayPosition={DropdownOverlayPositionEnum.bottomCenter}
                                                            tooltipNode={t(
                                                                'dispatch-route-edit.waypoints-control.add-point',
                                                            )}
                                                            options={[
                                                                {
                                                                    label: (
                                                                        <DropdownControlOptionLabel
                                                                            icon={
                                                                                <CircleIcon
                                                                                    borderColor={
                                                                                        StyleGuideColorsEnum.charcoal
                                                                                    }
                                                                                />
                                                                            }
                                                                            label={t(
                                                                                'dispatch-route-edit.waypoints-control.options.add-driver-through-point',
                                                                            )}
                                                                        />
                                                                    ),
                                                                    onSelect: () => {
                                                                        const fakeId = generatePointFakeId();

                                                                        const newRoutePoint: RoutePointFormValuesT = {
                                                                            [RoutePointFieldsEnum.id]: fakeId,
                                                                            [RoutePointFieldsEnum.type]:
                                                                                RoutePointTypeEnum.driveThrough,
                                                                            [RoutePointFieldsEnum.location]: null,
                                                                            [RoutePointFieldsEnum.timeWindow]: null,
                                                                        };

                                                                        insert(index + 1, newRoutePoint);

                                                                        delayedFocusOnLocationInput(index + 1, fakeId);
                                                                    },
                                                                },
                                                                {
                                                                    label: (
                                                                        <DropdownControlOptionLabel
                                                                            icon={
                                                                                <SplitIcon
                                                                                    fillColor={
                                                                                        StyleGuideColorsEnum.blazeOrange
                                                                                    }
                                                                                />
                                                                            }
                                                                            label={t(
                                                                                'dispatch-route-edit.waypoints-control.options.add-drop-and-hook-point',
                                                                            )}
                                                                        />
                                                                    ),
                                                                    onSelect: () => {
                                                                        const fakeId = generatePointFakeId();

                                                                        const newRoutePoint: RoutePointFormValuesT = {
                                                                            [RoutePointFieldsEnum.id]: fakeId,
                                                                            [RoutePointFieldsEnum.type]:
                                                                                RoutePointTypeEnum.dropAndHook,
                                                                            [RoutePointFieldsEnum.location]: null,
                                                                            [RoutePointFieldsEnum.timeWindow]: null,
                                                                        };

                                                                        insert(index + 1, newRoutePoint);

                                                                        delayedFocusOnLocationInput(index + 1, fakeId);
                                                                    },
                                                                },
                                                            ]}
                                                        />
                                                    )}
                                                </>
                                            );
                                        })}
                                    </>
                                );
                            }}
                        </FormikFieldArray>
                    </div>
                    <ScrollToFirstError submitCount={formik.submitCount} errors={formik.errors} />
                    <FooterSideBarLayout hasPaddings hasShadow topNode={footerTopNode}>
                        <div className={cx('footer-content')}>
                            {isChangedShipperTimeSlots && (
                                <>
                                    <Alert
                                        icon={
                                            <TimeWindowIcon
                                                fillColor={StyleGuideColorsEnum.white}
                                                fillOpacity={0}
                                                strokeColor={StyleGuideColorsEnum.white}
                                            />
                                        }
                                        size={AlertSizeEnum.small}
                                        theme={AlertThemeEnum.orange}
                                        className={cx('change-shipper-time-windows-alert')}
                                    >
                                        {t('dispatch-route-edit.footer.alert')}
                                    </Alert>
                                    <Checkbox
                                        className={cx('submit-confirmation')}
                                        theme={CheckboxThemeEnum.orange}
                                        checked={isConfirmedSubmit}
                                        label={t('dispatch-route-edit.footer.submit-confirm')}
                                        onChange={toggleIsConfirmedSubmit}
                                    />
                                </>
                            )}
                            <PriceDetails className={cx('old-prices')} list={oldPricesList} />
                            <PriceDetails className={cx('new-prices')} list={newPricesList} />
                            <div className={cx('actions')}>
                                <Button
                                    isFullWidth
                                    theme={ButtonThemeEnum.primary}
                                    isDisabled={isDisabledSubmit}
                                    className={cx('action', 'action--assign')}
                                    isLoading={isLoadingSubmit}
                                    type="submit"
                                    rightIcon={
                                        <ButtonTimer
                                            showTime={MS_IN_HOUR}
                                            expireTime={context?.expireTime || null}
                                            tooltipPosition={TooltipPositionEnum.topCenter}
                                            tooltipMessage={t('dispatch-route-edit.time-left-to-accept-cost')}
                                            onExpire={handleExpire}
                                        />
                                    }
                                >
                                    {t('common:form-actions.save-and-close')}
                                </Button>
                                <Button
                                    theme={ButtonThemeEnum.secondary}
                                    className={cx('action', 'action--cancel')}
                                    onClick={onCancel}
                                >
                                    {t('common:form-actions.reset-changes')}
                                </Button>
                            </div>
                        </div>
                    </FooterSideBarLayout>
                </form>
            </FormikContext.Provider>
        </div>
    );
};

export default RouteEditForm;
