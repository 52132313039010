import {
    AsyncRequestFactoryResultT,
    AsyncRequestFactoryT,
    useAsyncInitOptionsRequest,
} from 'design-system/components/dropdowns/SuggestInput/hooks/use-async-init-options-request';
import { ApiOrderCreationShipperInfoT } from 'common/utils/api/models';
import { useCallback, useEffect, useMemo } from 'react';
import commonTranziitApi from 'common/utils/api/tranziit/common-tranziit-api';

type OptionT = ApiOrderCreationShipperInfoT;

export const useOrderReservesShipperSuggestInitOptions = (
    initShipperId: ShipperIdT | null,
    onChangeShipperDetails: (shipperDetails: OptionT | null) => void,
) => {
    const initRequestFactory: AsyncRequestFactoryT<OptionT> = useCallback(() => {
        if (!initShipperId) {
            return Promise.resolve([null, null]);
        }

        return commonTranziitApi
            .fetchOrderReservesShippers({
                id: initShipperId,
            })
            .then((response): AsyncRequestFactoryResultT<OptionT> => {
                const [error, result] = response;
                if (error) {
                    return [error, null];
                }

                return [null, result?.content || []];
            });
    }, [initShipperId]);

    const { initOptions, initRequestStatus } = useAsyncInitOptionsRequest<OptionT>({
        initRequestFactory,
    });

    const selectedShipper = useMemo(() => {
        return initOptions.find((option) => option.id === initShipperId) || null;
    }, [initOptions, initShipperId]);

    useEffect(() => {
        onChangeShipperDetails(selectedShipper);
    }, [onChangeShipperDetails, selectedShipper]);

    return {
        initOptions,
        initRequestStatus,
    };
};
