import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';

import classNames from 'classnames/bind';
import styles from './DispatchRouteMap.scss';
import GoogleMapReact from 'google-map-react';
import { BOOTSTRAP_URL_KEYS, DEFAULT_CENTER, DEFAULT_ZOOM, MAP_OPTIONS } from 'common/store/constants';
import GoogleMapContext from 'common/contexts/google-map-context';
import { StopTypeEnum } from 'common/utils/api/models';
import last from 'lodash/last';
import { AssetTypeEnum } from 'common/constants';
import MapRoute, { MapRouteThemeEnum } from 'common/components/maps/MapRoute/MapRoute';
import MapBound, { BoundPointT } from 'common/components/maps/MapBound/MapBound';
import { DispatchDetailsT } from 'broker-admin/store/dispatch-details/models';
import { useDispatch, useSelector } from 'react-redux';
import { selectRoutingGeometryStates } from 'common/store/routing-geometry/selectors';
import RouteSwitchDropdown, { RouteTypeEnum } from '../RouteSwitchDropdown/RouteSwitchDropdown';
import { useRouteGeometry } from 'common/utils/hooks/useRouteGeometry';
import { isNonNil } from 'common/utils';
import MapLoader from 'common/components/maps/MapLoader/MapLoader';
import { getAssetTrackHashByTransportOrderId } from 'common/store/asset-track/utils';
import { selectAssetTrackSliceState } from 'common/store/asset-track/selectors';
import AssetDropOffLocationPin from 'common/components/maps/pins/AssetDropOffLocationPin/AssetDropOffLocationPin';
import AssetPickUpLocationPin from 'common/components/maps/pins/AssetPickUpLocationPin/AssetPickUpLocationPin';
import { fetchTracksByTransportOrders } from 'common/store/asset-track/actions';
import { findActualTransportOrders } from 'broker-admin/store/dispatch-details/utils/find-actual-transport-order';
import useDocumentVisibilityChange from 'common/utils/hooks/useDocumentVisibilityChange';
import Supercluster from 'supercluster';
import { PointGeoJsonPropertiesT } from 'broker-admin/layouts/DispatchesPage/DispatchDetailsPage/DispatchRouteMap/models';
import isNumber from 'lodash/isNumber';
import PointStackClusterPin from 'broker-admin/layouts/DispatchesPage/DispatchDetailsPage/DispatchRouteMap/PointStackClusterPin/PointStackClusterPin';
import WaypointNumberPin from 'common/components/maps/pins/WaypointNumberPin/WaypointNumberPin';
import { MapPinThemeEnum } from 'common/components/maps/MapPin/MapPin';
import uniq from 'lodash/uniq';
import { TrackPointT } from 'common/store/asset-track/models';
import { checkIsActualTour } from 'broker-admin/store/dispatch-details/utils/check-is-actual-tour';
import LastAssetWithNumberTrackPointPin from 'common/components/maps/pins/LastAssetWithNumberTrackPointPin/LastAssetWithNumberTrackPointPin';

const cx = classNames.bind(styles);

type PropsT = {
    dispatchDetails: DispatchDetailsT | null;
};

const FEATURES_LIMIT = 99999;
const DEFAULT_OFFSET = 0;

const SUPERCLUSTER_SETTINGS: Supercluster.Options<PointGeoJsonPropertiesT, any> = {
    radius: 100,
    maxZoom: 22,
};

type LastTruckTrackPointT = {
    truckId: TruckIdT;
    truckNumber: number;
    lastPoint: TrackPointT;
};

type LastTrailerTrackPointT = {
    trailerId: TrailerIdT;
    trailerNumber: number;
    lastPoint: TrackPointT;
};

const DispatchRouteMap: React.FC<PropsT> = React.memo((props) => {
    const { dispatchDetails } = props;

    const dispatch = useDispatch();

    const tours = useMemo(() => {
        return dispatchDetails?.tours || [];
    }, [dispatchDetails]);

    const allWaypoints = useMemo(() => {
        return tours.flatMap((tour) => {
            return tour?.waypoints || [];
        });
    }, [tours]);

    const hasActualTours = useMemo(() => {
        return !!dispatchDetails?.tours?.some(checkIsActualTour);
    }, [dispatchDetails]);

    const tourAssets = useMemo(() => {
        return (
            dispatchDetails?.tours?.map((tour, tourIndex) => {
                const { trailerTransportOrder, truckTransportOrder } = findActualTransportOrders(
                    tour?.transportationOrders,
                );

                const trailerTransportOrderId = trailerTransportOrder?.id || null;
                const truckTransportOrderId = truckTransportOrder?.id || null;

                return {
                    trailerId: trailerTransportOrder?.trailer?.id || null,
                    trailerTransportOrderId,
                    trailerNumber: tourIndex + 1,
                    trailer: trailerTransportOrder?.trailer || null,

                    truckId: truckTransportOrder?.truck?.id || null,
                    truckTransportOrderId,
                    truckNumber: tourIndex + 1,
                    truck: truckTransportOrder?.truck || null,

                    isLinked:
                        !!trailerTransportOrderId &&
                        !!truckTransportOrderId &&
                        trailerTransportOrderId === truckTransportOrderId,
                };
            }) || []
        );
    }, [dispatchDetails]);

    const trackTransportOrderIds = useMemo(() => {
        const transportOrderIds: Array<TransportOrderIdT | null> = [];

        tourAssets.forEach((tourAsset) => {
            transportOrderIds.push(tourAsset.truckTransportOrderId);
            transportOrderIds.push(tourAsset.trailerTransportOrderId);
        });

        return uniq(transportOrderIds).filter(isNonNil);
    }, [tourAssets]);

    React.useEffect(() => {
        dispatch(fetchTracksByTransportOrders(trackTransportOrderIds));
    }, [trackTransportOrderIds]);

    const documentVisibilityChangeHandler = React.useCallback(() => {
        dispatch(fetchTracksByTransportOrders(trackTransportOrderIds));
    }, [trackTransportOrderIds]);
    useDocumentVisibilityChange(documentVisibilityChangeHandler);

    const assetTrackSliceState = useSelector(selectAssetTrackSliceState);
    const tourAssetTracks = useMemo(() => {
        return tourAssets.map((tourAssets) => {
            const { truckTransportOrderId, truckId, trailerTransportOrderId, trailerId } = tourAssets;

            const trailerHash = getAssetTrackHashByTransportOrderId(truckTransportOrderId, trailerId);
            const truckHash = getAssetTrackHashByTransportOrderId(trailerTransportOrderId, truckId);

            return {
                trailer: assetTrackSliceState[trailerHash] || null,
                truck: assetTrackSliceState[truckHash] || null,
            };
        });
    }, [tourAssets, assetTrackSliceState]);

    const hasCarrierRoute = tourAssets.some((tourAssets) => {
        return !!tourAssets?.trailerTransportOrderId || !!tourAssets?.truckTransportOrderId;
    });

    const defaultRouteType = hasCarrierRoute ? RouteTypeEnum.carrierRoute : RouteTypeEnum.estimateCarrierRoute;
    const [selectedRouteType, setSelectedRouteType] = useState<RouteTypeEnum | null>(null);
    const routeType = selectedRouteType || defaultRouteType;

    useEffect(() => {
        setSelectedRouteType(hasCarrierRoute ? RouteTypeEnum.carrierRoute : RouteTypeEnum.estimateCarrierRoute);
    }, [hasCarrierRoute]);

    const googleMapContext = React.useContext(GoogleMapContext);

    const routePolylineIds = useMemo((): Array<PolylineIdT> => {
        switch (routeType) {
            case RouteTypeEnum.carrierRoute: {
                return tours.flatMap((tour) => {
                    return [
                        tour?.polylineId,
                        tour?.trailerToPickUpPolylineId,
                        tour?.truckToTrailerPolylineId,
                        tour?.dropTrailerPolylineId,
                        tour?.dropTruckPolylineId,
                    ].filter(isNonNil);
                });
            }
            case RouteTypeEnum.estimateCarrierRoute: {
                return tours.flatMap((tour) => {
                    return [tour?.polylineId].filter(isNonNil);
                });
            }
            case RouteTypeEnum.shipperRoute: {
                return [dispatchDetails?.order?.polylineId].filter(isNonNil);
            }
            default: {
                return [];
            }
        }
    }, [tours, routeType]);

    useRouteGeometry(routePolylineIds);

    const geometryStates = useSelector(selectRoutingGeometryStates(routePolylineIds));

    const isLoadingPolylines = useMemo(() => {
        return geometryStates.some((geometryState) => {
            return geometryState?.requestStatus && geometryState?.requestStatus?.loading;
        });
    }, [geometryStates]);

    const boundMapPoints = React.useMemo((): Array<BoundPointT> => {
        return (
            allWaypoints.map((waypoint): BoundPointT => {
                return [waypoint?.address?.latitude, waypoint?.address?.longitude];
            }) || []
        );
    }, [allWaypoints]);

    const apiIsLoaded: OnGoogleApiLoadedT = (api) => {
        setApi(api);

        const { map, maps } = api;

        googleMapContext.googleMaps?.set(maps, map, ['geometry']);
    };

    const isLoadingSomeTrucks = useMemo(() => {
        return tourAssetTracks?.some((state) => {
            return state?.truck?.requestStatus?.loading || state?.trailer?.requestStatus?.loading;
        });
    }, [tourAssetTracks]);
    const isLoading = isLoadingPolylines || isLoadingSomeTrucks;

    const lastTruckTrackPoints = useMemo(() => {
        return tourAssets.reduce<Array<LastTruckTrackPointT>>((acc, tourAssets, index) => {
            const { truckId, truckNumber } = tourAssets;

            const tracks = tourAssetTracks[index];

            const lastPoint = last(tracks?.truck?.points);
            if (truckId && lastPoint) {
                acc.push({
                    truckId,
                    truckNumber,
                    lastPoint,
                });
            }

            return acc;
        }, []);
    }, [tourAssetTracks, tourAssets]);

    const lastTrailerTrackPoints = useMemo(() => {
        return tourAssets.reduce<Array<LastTrailerTrackPointT>>((acc, tourAssets, index) => {
            const { trailerId, trailerNumber, isLinked } = tourAssets;

            // not show last point of linked trailer
            if (isLinked) {
                return acc;
            }

            const tracks = tourAssetTracks[index];

            const lastPoint = last(tracks?.trailer?.points);
            if (trailerId && lastPoint) {
                acc.push({
                    trailerId,
                    trailerNumber,
                    lastPoint,
                });
            }

            return acc;
        }, []);
    }, [tourAssetTracks, tourAssets]);

    const allGeoJSONs = React.useMemo(() => {
        const geoJSONs: Array<Supercluster.PointFeature<PointGeoJsonPropertiesT>> = [];

        allWaypoints.forEach((waypoint) => {
            if (
                (waypoint.type === StopTypeEnum.pickupTruck ||
                    waypoint.type === StopTypeEnum.pickupTrailer ||
                    waypoint.type === StopTypeEnum.pickupRoadTrain ||
                    waypoint.type === StopTypeEnum.dropTruck ||
                    waypoint.type === StopTypeEnum.dropTrailer ||
                    waypoint.type === StopTypeEnum.dropRoadTrain) &&
                routeType !== RouteTypeEnum.carrierRoute
            ) {
                return;
            }

            const longitude = waypoint?.address?.longitude;
            const latitude = waypoint?.address?.latitude;
            if (isNumber(latitude) && isNumber(longitude)) {
                const geoJson: Supercluster.PointFeature<PointGeoJsonPropertiesT> = {
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [longitude, latitude],
                    },
                    properties: {
                        type: 'waypoint-point',
                        waypoint,
                    },
                };

                geoJSONs.push(geoJson);
            }
        });

        lastTruckTrackPoints.forEach((lastTruckTrackPoint) => {
            const { truckNumber, truckId, lastPoint } = lastTruckTrackPoint;

            geoJSONs.push({
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [lastPoint.lng, lastPoint.lat],
                },
                properties: {
                    type: 'truck-last-point',
                    point: lastPoint,
                    truckId,
                    truckNumber,
                },
            });
        });

        lastTrailerTrackPoints.forEach((lastTrailerTrackPoint) => {
            const { trailerNumber, trailerId, lastPoint } = lastTrailerTrackPoint;

            geoJSONs.push({
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [lastPoint.lng, lastPoint.lat],
                },
                properties: {
                    type: 'trailer-last-point',
                    point: lastPoint,
                    trailerId,
                    trailerNumber,
                },
            });
        });

        return geoJSONs;
    }, [allWaypoints, lastTruckTrackPoints, lastTrailerTrackPoints, routeType]);

    const allGeoJSONsClusterator = React.useMemo(() => {
        const clusterator = new Supercluster<PointGeoJsonPropertiesT>(SUPERCLUSTER_SETTINGS);
        clusterator.load(allGeoJSONs || []);
        return clusterator;
    }, [allGeoJSONs]);

    const [config, setConfig] = React.useState<TODO>(null);

    const allGeoJSONsClusters = React.useMemo(() => {
        if (config) {
            const clusters = allGeoJSONsClusterator.getClusters(config.bounds, config.zoom);
            return clusters;
        }

        return [];
    }, [config, allGeoJSONsClusterator]);

    const [api, setApi] = React.useState<TODO>(null);

    const handleChange = () => {
        if (api) {
            const { zoom } = api.map;

            const bound = api.map.getBounds();

            const northEast = bound.getNorthEast();
            const southWest = bound.getSouthWest();

            setConfig({
                zoom,
                bounds: [southWest.lng(), southWest.lat(), northEast.lng(), northEast.lat()],
            });
        }
    };

    const renderSinglePointFeature = (properties: PointGeoJsonPropertiesT) => {
        switch (properties.type) {
            case 'trailer-last-point': {
                return (
                    <LastAssetWithNumberTrackPointPin
                        key="trailer-last-point"
                        iconType={AssetTypeEnum.trailer}
                        lat={properties.point.lat}
                        lng={properties.point.lng}
                        timestamp={properties.point.timestamp}
                        number={properties.trailerNumber}
                    />
                );
            }
            case 'truck-last-point': {
                return (
                    <LastAssetWithNumberTrackPointPin
                        key="truck-last-point"
                        iconType={AssetTypeEnum.truck}
                        lat={properties.point.lat}
                        lng={properties.point.lng}
                        timestamp={properties.point.timestamp}
                        number={properties.truckNumber}
                    />
                );
            }
            case 'waypoint-point': {
                const { waypoint } = properties;

                switch (waypoint.type) {
                    case StopTypeEnum.pickupTruck: {
                        return (
                            <AssetPickUpLocationPin
                                assetType={AssetTypeEnum.truck}
                                key={`pickupTruck-${waypoint.id}`}
                                className={cx('route-point-pin')}
                                lat={waypoint.address?.latitude}
                                lng={waypoint.address?.longitude}
                            />
                        );
                    }
                    case StopTypeEnum.pickupTrailer: {
                        return (
                            <AssetPickUpLocationPin
                                assetType={AssetTypeEnum.trailer}
                                key={`pickupTrailer-${waypoint.id}`}
                                className={cx('route-point-pin')}
                                lat={waypoint.address?.latitude}
                                lng={waypoint.address?.longitude}
                            />
                        );
                    }
                    case StopTypeEnum.pickupRoadTrain: {
                        return (
                            <AssetPickUpLocationPin
                                assetType={null}
                                key={`pickupRoadTrain-${waypoint.id}`}
                                className={cx('route-point-pin')}
                                lat={waypoint.address?.latitude}
                                lng={waypoint.address?.longitude}
                            />
                        );
                    }
                    case StopTypeEnum.driveThrough:
                    case StopTypeEnum.dropAndHook:
                    case StopTypeEnum.pickupDeliveryShipment: {
                        let theme = MapPinThemeEnum.charcoal;
                        if (waypoint.type === StopTypeEnum.driveThrough) {
                            theme = MapPinThemeEnum.gray;
                        }

                        const isVisited = !!waypoint?.driverLeftTimeStamp;
                        if (isVisited) {
                            theme = MapPinThemeEnum.brandDark;
                        }

                        return (
                            <WaypointNumberPin
                                key={`route-point-${waypoint.id}`}
                                className={cx('route-point-pin')}
                                theme={theme}
                                lng={waypoint?.address?.longitude}
                                lat={waypoint?.address?.latitude}
                            >
                                {(waypoint.index || 0) + 1}
                            </WaypointNumberPin>
                        );
                    }
                    case StopTypeEnum.dropTrailer: {
                        return (
                            <AssetDropOffLocationPin
                                assetType={AssetTypeEnum.trailer}
                                key={`dropTrailer-${waypoint.id}`}
                                className={cx('route-point-pin')}
                                lng={waypoint?.address?.longitude}
                                lat={waypoint?.address?.latitude}
                            />
                        );
                    }
                    case StopTypeEnum.dropTruck: {
                        return (
                            <AssetDropOffLocationPin
                                assetType={AssetTypeEnum.truck}
                                key={`dropTruckStop-${waypoint.id}`}
                                className={cx('route-point-pin')}
                                lng={waypoint?.address?.longitude}
                                lat={waypoint?.address?.latitude}
                            />
                        );
                    }
                    case StopTypeEnum.dropRoadTrain: {
                        return (
                            <AssetDropOffLocationPin
                                assetType={null}
                                key={`link-dropoff-${waypoint.id}`}
                                className={cx('route-point-pin')}
                                lng={waypoint?.address?.longitude}
                                lat={waypoint?.address?.latitude}
                            />
                        );
                    }
                    default: {
                        return null;
                    }
                }
            }
            default: {
                return null;
            }
        }
    };

    const handleClusterClick = React.useCallback(
        (clusterId: number) => {
            const { map, maps } = api;

            const clusterFeatures = allGeoJSONsClusterator.getLeaves(clusterId, FEATURES_LIMIT, DEFAULT_OFFSET);

            const bounds = new maps.LatLngBounds();

            clusterFeatures.forEach((feature: Supercluster.PointFeature<{}>) => {
                const point = new maps.LatLng(feature.geometry.coordinates[1], feature.geometry.coordinates[0]);

                bounds.extend(point);
            });

            map.fitBounds(bounds);
        },
        [api, allGeoJSONsClusterator],
    );

    return (
        <>
            {isLoading && <MapLoader className={cx('loader')} />}
            <GoogleMapReact
                defaultCenter={DEFAULT_CENTER}
                defaultZoom={DEFAULT_ZOOM}
                bootstrapURLKeys={BOOTSTRAP_URL_KEYS}
                options={MAP_OPTIONS}
                onGoogleApiLoaded={apiIsLoaded}
                onDragEnd={handleChange}
                onZoomAnimationEnd={handleChange}
                yesIWantToUseGoogleMapApiInternals
            >
                {allGeoJSONsClusters.map((cluster) => {
                    if ('cluster' in cluster.properties) {
                        const pointsFeatures = allGeoJSONsClusterator.getLeaves(
                            cluster.properties.cluster_id,
                            FEATURES_LIMIT,
                            DEFAULT_OFFSET,
                        );

                        const clusterProperties = pointsFeatures.map((pointFeatures) => {
                            return pointFeatures.properties;
                        });

                        return (
                            <PointStackClusterPin
                                key={`cluster-${cluster.properties.cluster_id}`}
                                clusterId={cluster.properties.cluster_id}
                                lng={cluster.geometry.coordinates[0]}
                                lat={cluster.geometry.coordinates[1]}
                                clusterProperties={clusterProperties}
                                onClusterClick={handleClusterClick}
                                withoutArrow={config.zoom < 20}
                            />
                        );
                    }
                    return renderSinglePointFeature(cluster.properties as PointGeoJsonPropertiesT);
                })}
            </GoogleMapReact>
            {geometryStates?.map((geometryState, index) => (
                <MapRoute
                    key={`geometry-${index}`}
                    map={googleMapContext.googleMaps?.map}
                    maps={googleMapContext.googleMaps?.maps}
                    geometryLibrary={googleMapContext.googleMaps?.libraries?.geometry}
                    polylines={geometryState.data}
                    theme={MapRouteThemeEnum.trackFuture}
                    zIndex={0}
                />
            ))}
            {tourAssetTracks.map((tourAssetTracks, index) => {
                const assets = tourAssets[index];
                const { trailer, truck } = tourAssetTracks;

                return (
                    <>
                        {!!truck?.points && (
                            <MapRoute
                                map={googleMapContext.googleMaps?.map}
                                maps={googleMapContext.googleMaps?.maps}
                                route={truck.points}
                                theme={MapRouteThemeEnum.trackPast}
                                zIndex={1}
                            />
                        )}
                        {!assets?.isLinked && !!trailer?.points && (
                            <MapRoute
                                map={googleMapContext.googleMaps?.map}
                                maps={googleMapContext.googleMaps?.maps}
                                route={trailer.points}
                                theme={MapRouteThemeEnum.trackPast}
                                zIndex={1}
                            />
                        )}
                    </>
                );
            })}
            <MapBound
                map={googleMapContext.googleMaps?.map}
                maps={googleMapContext.googleMaps?.maps}
                allMapPoints={boundMapPoints}
            />
            {!!routePolylineIds?.length && (
                <RouteSwitchDropdown
                    className={cx('route-type-switcher')}
                    value={routeType}
                    onChange={setSelectedRouteType}
                    isDisabledEstimateCarrierRoute={!hasActualTours}
                    isDisabledShipperRoute={!dispatchDetails}
                    isDisabledCarrierRoute={!hasCarrierRoute}
                />
            )}
        </>
    );
});

export default DispatchRouteMap;
