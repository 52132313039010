import { useMemo } from 'react';
import { ApiStopTypeT, StopTypeEnum } from 'common/utils/api/models';
import { DispatchWaypointT } from 'broker-admin/store/dispatch-details/models';

const useDispatchPayloadWaypoints = (dispatchWaypoints: Array<DispatchWaypointT> | null | undefined) => {
    return useMemo(() => {
        if (!dispatchWaypoints) {
            return {
                firstPayloadWaypoint: null,
                lastPayloadWaypoint: null,
            };
        }

        const payloadWaypointStopTypeSet = new Set<ApiStopTypeT>([
            StopTypeEnum.pickupDeliveryShipment,
            StopTypeEnum.driveThrough,
        ]);

        const payloadWaypoints = dispatchWaypoints?.filter((dispatchWaypoint) => {
            return payloadWaypointStopTypeSet.has(dispatchWaypoint.type);
        });

        return {
            firstPayloadWaypoint: payloadWaypoints[0] || null,
            lastPayloadWaypoint: payloadWaypoints[payloadWaypoints.length - 1] || null,
        };
    }, [dispatchWaypoints]);
};

export { useDispatchPayloadWaypoints };
