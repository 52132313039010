import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './AssignOptionLabel.scss';
import CircleIcon from 'common/icons/CircleIcon';
import getUtilizationColor from 'common/utils/get-utilization-color';
import { useTranslation } from 'react-i18next';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import ControlLoaderIcon from 'common/icons/ControlLoaderIcon';
import isNil from 'lodash/isNil';
import AssetLinkIcon from 'common/icons/AssetLinkIcon';
import Link, { LinkSizeEnum, LinkThemeEnum } from 'common/components/Link/Link';
import { AssetAssignmentInfoT } from 'common/utils/assignment';

const cx = classNames.bind(styles);

type PropsT = {
    assetId: string;
    model?: string;
    plateNumber?: string;
    price?: string;
    assetUtilizationPercent: number | null | undefined;
    isAssetUtilizationLoading: boolean;
    carrierUtilizationPercent: number | null;
    isCarrierUtilizationLoading: boolean;
    isInvalid?: boolean;
    linkedAsset: AssetAssignmentInfoT | null;
    onSelectLink: (assetId: AssetIdT) => void;
    onCloseOverlay?: () => void;
};

const AssignOptionLabel: React.FC<PropsT> = React.memo((props) => {
    const {
        assetId,
        model,
        plateNumber,
        price,
        assetUtilizationPercent,
        isAssetUtilizationLoading,
        carrierUtilizationPercent,
        isCarrierUtilizationLoading,
        isInvalid,
        linkedAsset,
        onSelectLink,
        onCloseOverlay,
    } = props;

    const { t } = useTranslation();

    return (
        <div
            className={cx('option', {
                'option--is-invalid': !!isInvalid,
            })}
        >
            <div className={cx('asset')}>
                <div className={cx('asset-column')}>
                    <div className={cx('model-and-plate-number')}>
                        {[plateNumber, model].filter(Boolean).join(' / ')}
                    </div>
                    <div className={cx('price')}>{price || ''}</div>
                </div>
                <div className={cx('asset-column')}>
                    <div className={cx('utilization')}>
                        {!isNil(assetUtilizationPercent) && (
                            <>
                                <CircleIcon borderColor={getUtilizationColor(assetUtilizationPercent)} />
                                <div className={cx('utilization__label')}>
                                    {t('assignment.asset-utilization', { percent: assetUtilizationPercent })}
                                </div>
                            </>
                        )}
                        {isNil(assetUtilizationPercent) && isAssetUtilizationLoading && (
                            <ControlLoaderIcon fillColor={StyleGuideColorsEnum.brandAccent} size={DEFAULT_ICON_SIZE} />
                        )}
                    </div>
                    <div className={cx('utilization')}>
                        {carrierUtilizationPercent !== null && (
                            <>
                                <CircleIcon borderColor={getUtilizationColor(carrierUtilizationPercent)} />
                                <div className={cx('utilization__label')}>
                                    {t('assignment.carrier-utilization', { percent: carrierUtilizationPercent })}
                                </div>
                            </>
                        )}
                        {carrierUtilizationPercent === null && isCarrierUtilizationLoading && (
                            <ControlLoaderIcon fillColor={StyleGuideColorsEnum.brandAccent} size={DEFAULT_ICON_SIZE} />
                        )}
                    </div>
                </div>
            </div>
            {linkedAsset && (
                <div className={cx('link')}>
                    <div className={cx('link__inner')}>
                        <AssetLinkIcon
                            strokeColor={StyleGuideColorsEnum.orange}
                            size={DEFAULT_ICON_SIZE}
                            className={cx('link__icon')}
                        />
                        <div className={cx('link__text')}>
                            {[linkedAsset.plateNumber, linkedAsset.model].filter(Boolean).join(' / ')}
                        </div>
                        <Link
                            className={cx('link__trigger')}
                            size={LinkSizeEnum.extraSmall}
                            isUppercase
                            onClick={(event) => {
                                event.stopPropagation();

                                onSelectLink(assetId);

                                if (onCloseOverlay) {
                                    onCloseOverlay();
                                }
                            }}
                            theme={LinkThemeEnum.boldBrandDark}
                        >
                            {t('assignment.assign-full-train')}
                        </Link>
                        {!isNil(linkedAsset.utilization) && (
                            <div className={cx('utilization')}>
                                <CircleIcon borderColor={getUtilizationColor(linkedAsset.utilization)} />
                                <div className={cx('utilization__label')}>
                                    {t('assignment.asset-utilization', { percent: linkedAsset.utilization })}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
});

export default AssignOptionLabel;
