import * as React from 'react';
import cs from 'classnames';
import classNames from 'classnames/bind';
import styles from './TourTypeTabs.scss';
import Money from 'common/components/Money/Money';
import { CurrencyEnum, StyleGuideColorsEnum, UNIT, UnitTypeEnum } from 'common/constants';
import { useTranslation } from 'react-i18next';
import { TourTypeTabsEnum } from 'broker-admin/layouts/DispatchesPage/DispatchAssignmentPage/AssignmentForm/TourTypeTabs/constants';
import MoneyDiff from 'common/components/MoneyDiff/MoneyDiff';
import ColoredDiff from 'common/components/ColoredDiff/ColoredDiff';
import PillLabel, { PillLabelThemeEnum } from 'common/components/PillLabel/PillLabel';
import UnitTypeCount from 'common/components/units/UnitTypeCount/UnitTypeCount';
import isNumber from 'lodash/isNumber';
import { DispatchDetailsT } from 'broker-admin/store/dispatch-details/models';
import { SelfCostPredictionT } from 'broker-admin/store/dispatch-assigment/models';
import { getDiffPercent, getValuesDiff } from 'common/utils/percents';
import { moneyOperations } from 'common/utils/money';
import Tooltip, { TooltipPositionEnum, TooltipThemeEnum } from 'design-system/components/Tooltip/Tooltip';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import TooltipIconTrigger from 'design-system/components/Tooltip/TooltipIconTrigger/TooltipIconTrigger';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
    isLoading?: boolean;
    dispatchDetails: DispatchDetailsT | null;
    selfCostPrediction?: SelfCostPredictionT | null;
    selectedTourTypeTab: TourTypeTabsEnum;
    onSelectTourTypeTab: (tourTab: TourTypeTabsEnum) => void;
};

const summTotalCostIfNeed = (
    truckTransportOrderId: TransportOrderIdT | undefined,
    truckCostDetailsTotalCost: number | undefined,
    trailerTransportOrderId: TransportOrderIdT | undefined,
    trailerCostDetailsTotalCost: number | undefined,
) => {
    if (truckTransportOrderId && trailerTransportOrderId && truckTransportOrderId === trailerTransportOrderId) {
        return truckCostDetailsTotalCost || 0;
    }

    return moneyOperations.notStrictPlus(
        truckCostDetailsTotalCost || 0,
        trailerCostDetailsTotalCost || 0,
        CurrencyEnum.EUR,
    );
};

const TourTypeTabs: React.FC<PropsT> = React.memo((props) => {
    const { className, isLoading, selectedTourTypeTab, onSelectTourTypeTab, dispatchDetails, selfCostPrediction } =
        props;

    const estimateCost = dispatchDetails?.priceOffer?.totalPrice;
    const actualCost = selfCostPrediction?.calculatedSumCost?.totalCost;

    const nextCost = summTotalCostIfNeed(
        selfCostPrediction?.truckNextTransportationOrderInfo?.id,
        selfCostPrediction?.truckNextTourOldCostDetails?.totalCost,
        selfCostPrediction?.trailerNextTransportationOrderInfo?.id,
        selfCostPrediction?.trailerNextTourOldCostDetails?.totalCost,
    );

    const actualNextCost = summTotalCostIfNeed(
        selfCostPrediction?.truckNextTransportationOrderInfo?.id,
        selfCostPrediction?.truckNextTourNewCostDetails?.totalCost,
        selfCostPrediction?.trailerNextTransportationOrderInfo?.id,
        selfCostPrediction?.trailerNextTourNewCostDetails?.totalCost,
    );

    const costDiff = getValuesDiff(actualNextCost, nextCost);
    const actualNextPercentDiff = getDiffPercent(costDiff, nextCost);

    const { t } = useTranslation();

    const renderSafetyMoney = (amount: number | null | undefined) => {
        if (!isNumber(amount)) {
            return t('common:not-applicable');
        }

        return <Money amount={amount} currency={CurrencyEnum.EUR} />;
    };

    const isPositiveDiff = isNumber(costDiff) && costDiff < 0;

    const isShowNextTour = Boolean(nextCost) || Boolean(actualNextCost);
    React.useEffect(() => {
        if (!isShowNextTour && selectedTourTypeTab === TourTypeTabsEnum.next) {
            onSelectTourTypeTab(TourTypeTabsEnum.current);
        }
    }, [isShowNextTour]);

    let diffPercentPillTheme = isPositiveDiff ? PillLabelThemeEnum.brandDark : PillLabelThemeEnum.orange;
    if (isLoading) {
        diffPercentPillTheme = PillLabelThemeEnum.light;
    }

    return (
        <div className={cs(cx('wrap'), className)}>
            <div className={cx('columns')}>
                <div
                    className={cx('tab', {
                        'tab--isActive': selectedTourTypeTab === TourTypeTabsEnum.current,
                    })}
                    onClick={() => {
                        onSelectTourTypeTab(TourTypeTabsEnum.current);
                    }}
                >
                    <div className={cx('head')}>{t('assignment.tout-type-tabs.current-tour')}</div>
                    <MoneyDiff value={estimateCost} baseValue={estimateCost}>
                        {(diff) => (
                            <ColoredDiff isInverted diff={diff} customNegativeColor={StyleGuideColorsEnum.orange}>
                                <div className={cx('row', {})}>
                                    <div>{t('assignment.tout-type-tabs.target-tour-cost')}</div>
                                    <div>{renderSafetyMoney(estimateCost)}</div>
                                </div>
                            </ColoredDiff>
                        )}
                    </MoneyDiff>
                    <MoneyDiff value={actualCost} baseValue={estimateCost}>
                        {(diff) => (
                            <ColoredDiff
                                isNotActual={isLoading}
                                isInverted
                                diff={diff}
                                customNegativeColor={StyleGuideColorsEnum.orange}
                            >
                                <div className={cx('row')}>
                                    <div>{t('assignment.tout-type-tabs.actual-tour-cost')}</div>
                                    <div>{renderSafetyMoney(actualCost)}</div>
                                </div>
                            </ColoredDiff>
                        )}
                    </MoneyDiff>
                </div>
                {isShowNextTour && (
                    <div
                        className={cx('tab', {
                            'tab--isActive': selectedTourTypeTab === TourTypeTabsEnum.next,
                        })}
                        onClick={() => {
                            onSelectTourTypeTab(TourTypeTabsEnum.next);
                        }}
                    >
                        <div className={cx('head')}>
                            {t('assignment.tout-type-tabs.next-tour')}
                            <Tooltip
                                position={TooltipPositionEnum.topCenter}
                                theme={TooltipThemeEnum.black}
                                isInline
                                tooltipNode={
                                    <TooltipContent width={50 * UNIT} theme={TooltipContentThemeEnum.black}>
                                        {t('assignment.tout-type-tabs.next-tour-tooltip-note')}
                                    </TooltipContent>
                                }
                            >
                                {(isShow) => <TooltipIconTrigger isShow={isShow} />}
                            </Tooltip>
                        </div>
                        <MoneyDiff value={nextCost} baseValue={nextCost}>
                            {(diff) => (
                                <ColoredDiff
                                    isNotActual={isLoading}
                                    isInverted
                                    diff={diff}
                                    customNotNumberColor={StyleGuideColorsEnum.light}
                                    customNegativeColor={StyleGuideColorsEnum.orange}
                                >
                                    <div className={cx('row')}>
                                        <div>{t('assignment.tout-type-tabs.planned-next-tour-cost')}</div>
                                        <div>{renderSafetyMoney(nextCost || null)}</div>
                                    </div>
                                </ColoredDiff>
                            )}
                        </MoneyDiff>
                        <MoneyDiff value={actualNextCost} baseValue={nextCost}>
                            {(diff) => (
                                <ColoredDiff
                                    isNotActual={isLoading}
                                    isInverted
                                    diff={diff}
                                    customNotNumberColor={StyleGuideColorsEnum.light}
                                    customNegativeColor={StyleGuideColorsEnum.orange}
                                >
                                    <div className={cx('row')}>
                                        <div>{t('assignment.tout-type-tabs.updated-next-tour-cost')}</div>
                                        <div>{renderSafetyMoney(actualNextCost || null)}</div>
                                        {isNumber(actualNextPercentDiff) && Boolean(actualNextPercentDiff) && (
                                            <div className={cx('diff-wrap')}>
                                                <PillLabel
                                                    isSymmetrical
                                                    className={cx('diff')}
                                                    theme={diffPercentPillTheme}
                                                >
                                                    {isPositiveDiff ? '-' : '+'}
                                                    <UnitTypeCount
                                                        type={UnitTypeEnum.percentsAbbreviation}
                                                        count={actualNextPercentDiff}
                                                    />
                                                </PillLabel>
                                            </div>
                                        )}
                                    </div>
                                </ColoredDiff>
                            )}
                        </MoneyDiff>
                    </div>
                )}
            </div>
        </div>
    );
});

export default TourTypeTabs;
