import { TFunction } from 'i18next';

import { FieldsEnum, FormErrorsT, FormValuesT } from './constants';
import { AssetAssignmentInfoT } from 'common/utils/assignment';
import { CountrySettingsByCodeT } from 'common/store/country-settings-dict/models';
import { checkIsAvailableCountryForDropAsset } from 'common/store/country-settings-dict/utils/check-location';

type MetaT = {
    trailerById: Record<TrailerIdT, AssetAssignmentInfoT>;
    truckById: Record<TruckIdT, AssetAssignmentInfoT>;
    countriesSettingsByCode: CountrySettingsByCodeT;
};

const validate = (t: TFunction, values: FormValuesT, meta: MetaT): FormErrorsT => {
    const { trailerById, truckById, countriesSettingsByCode } = meta;

    const errors: FormErrorsT = {};

    const selectedTrailerId = values[FieldsEnum.trailerId];
    const selectedTrailer = selectedTrailerId ? trailerById[selectedTrailerId] : null;

    const selectedTruckId = values[FieldsEnum.truckId];
    const selectedTruck = selectedTruckId ? truckById[selectedTruckId] : null;

    if (!selectedTruck && !selectedTrailer) {
        errors[FieldsEnum.truckId] = t('assignment.validation.required-asset');
        errors[FieldsEnum.trailerId] = t('assignment.validation.required-asset');
    }

    const locationToDropTrailer = values[FieldsEnum.locationToDropTrailer];
    if (!checkIsAvailableCountryForDropAsset(locationToDropTrailer, countriesSettingsByCode)) {
        errors[FieldsEnum.locationToDropTrailer] = t('assignment.validation.unsupported-country');
    }

    const locationToDropTruck = values[FieldsEnum.locationToDropTruck];
    if (!checkIsAvailableCountryForDropAsset(locationToDropTruck, countriesSettingsByCode)) {
        errors[FieldsEnum.locationToDropTruck] = t('assignment.validation.unsupported-country');
    }

    return errors;
};

export default validate;
