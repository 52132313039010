import * as React from 'react';

import classNames from 'classnames/bind';

import styles from './DispatchRouteEditPage.scss';

import { useParams } from 'react-router-dom';
import useGoogleMaps from 'common/utils/hooks/useGoogleMaps';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import history, { goBackIfHasRePath } from 'common/utils/history';
import GoogleMapContext from 'common/contexts/google-map-context';
import HorizontalColumnsLayout from 'common/layouts/HorizontalColumnsLayout/HorizontalColumnsLayout';
import HeaderSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarLayout/HeaderSideBarLayout';
import HeaderSideBarContent from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarContent/HeaderSideBarContent';
import SideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLayout';
import RouteEditMap from './RouteEditMap/RouteEditMap';
import SideBarLayoutContextWrap from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLayoutContextWrap/SideBarLayoutContextWrap';
import HeaderSideBarLoader from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarLoader/HeaderSideBarLoader';
import SideBarLoader from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLoader/SideBarLoader';
import PageTitle from 'common/components/PageTitle/PageTitle';
import SideBars from 'broker-admin/layouts/SideBars/SideBars';
import { urlFactory } from 'broker-admin/utils/urls';
import RouteEditForm from './RouteEditForm/RouteEditForm';
import { fetchCountriesDict } from 'common/store/countries-dict/actions';

import { SyncRouteEditFormMapStateContext } from './contexts/sync-map-state';
import { usySyncMapStateContextValue } from './hooks/usySyncMapStateContextValue';
import { finishOrderEdit, initOrderEdit } from 'broker-admin/store/order-edit/slice';
import {
    selectOrderEditApplyContextRequest,
    selectOrderEditContext,
    selectOrderEditCreateContextRequest,
    selectOrderEditDeleteContextRequest,
    selectOrderEditDispatchDetails,
} from 'broker-admin/store/order-edit/selectors';
import useOnlyChangesEffect from 'common/utils/hooks/useOnlyChangesEffect';
import RouteSidebarSkeleton from 'broker-admin/layouts/DispatchesPage/DispatchRouteEditPage/RouteSidebarSkeleton/RouteSidebarSkeleton';

const cx = classNames.bind(styles);

type ParamsT = {
    dispatchId: string;
};

type PropsT = {};

const DispatchRouteEditPage: React.FC<PropsT> = React.memo((props) => {
    const params = useParams<ParamsT>();
    const { dispatchId } = params;

    const mapStateContextValue = usySyncMapStateContextValue();

    const googleMaps = useGoogleMaps();
    const googleMapsContextValue = React.useMemo(() => {
        return {
            googleMaps,
        };
    }, [googleMaps]);

    const { t } = useTranslation();

    const deleteContextRequest = useSelector(selectOrderEditDeleteContextRequest);
    const applyContextRequest = useSelector(selectOrderEditApplyContextRequest);
    const createContextRequest = useSelector(selectOrderEditCreateContextRequest);
    const dispatchDetails = useSelector(selectOrderEditDispatchDetails);
    const context = useSelector(selectOrderEditContext);

    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(initOrderEdit({ dispatchId }));
    }, [dispatchId]);

    const handleClose = () => {
        goBackIfHasRePath(() => {
            history.push(urlFactory.dispatchDetails(dispatchId || '-'));
        });
    };

    React.useEffect(() => {
        dispatch(fetchCountriesDict());

        return () => {
            dispatch(finishOrderEdit({ dispatchId }));
        };
    }, []);

    useOnlyChangesEffect(() => {
        if (deleteContextRequest.ok) {
            handleClose();
        }
    }, [deleteContextRequest.ok]);

    useOnlyChangesEffect(() => {
        if (applyContextRequest.ok) {
            handleClose();
        }
    }, [applyContextRequest.ok]);

    const isShowLineLoader = createContextRequest.loading && !!dispatchDetails;
    const isShowOverlayLoader = !createContextRequest.error && !dispatchDetails;

    return (
        <SyncRouteEditFormMapStateContext.Provider value={mapStateContextValue}>
            <GoogleMapContext.Provider value={googleMapsContextValue}>
                <PageTitle
                    title={t('page-titles.route-edit', {
                        number: dispatchDetails?.number || '',
                    })}
                />
                <HorizontalColumnsLayout
                    testSelector="route-edit"
                    className={cx('wrap')}
                    leftColumnTitle={t('dispatch-route-edit.main-tabs.details')}
                    leftColumnNode={
                        <SideBarLayoutContextWrap>
                            <HeaderSideBarLayout>
                                <HeaderSideBarContent
                                    title={t('dispatch-route-edit.title', {
                                        number: dispatchDetails?.number || '',
                                    })}
                                    onClose={handleClose}
                                />
                                <HeaderSideBarLoader isShow={isShowLineLoader} />
                            </HeaderSideBarLayout>
                            <SideBarLayout>
                                {dispatchDetails ? (
                                    <RouteEditForm dispatchId={dispatchId} context={context} onCancel={handleClose} />
                                ) : (
                                    <RouteSidebarSkeleton />
                                )}
                            </SideBarLayout>
                            {isShowOverlayLoader && <SideBarLoader />}
                        </SideBarLayoutContextWrap>
                    }
                    rightColumnTitle={t('dispatch-route-edit.main-tabs.info')}
                    rightColumnNode={
                        <div className={cx('tabs-wrap')}>
                            <div className={cx('tab')}>
                                <RouteEditMap key={dispatchId} context={context} />
                            </div>
                        </div>
                    }
                />
            </GoogleMapContext.Provider>
            <SideBars />
        </SyncRouteEditFormMapStateContext.Provider>
    );
});

export default DispatchRouteEditPage;
