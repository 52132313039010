import { ApiMultiPointPriceOfferT } from 'common/utils/api/models';
import { PriceOfferT } from '../models';
import { checkIsPastDate, getDateFromISO, parseDateString } from 'common/utils/time';
import { convertToKm } from 'common/utils/distance';
import map from 'lodash/map';
import { isNonNil } from 'common/utils';
import { findAdditionalServiceByType } from 'common/utils/additional-services';
import { BROKER_ADDITIONAL_SERVICES_TYPE } from 'common/constants';

export default (apiPriceOffer: ApiMultiPointPriceOfferT): PriceOfferT => {
    const expirationMs = parseDateString(apiPriceOffer.expiration);

    const firstPoint = apiPriceOffer?.points?.[0];
    const lastPoint = apiPriceOffer?.points ? apiPriceOffer.points[apiPriceOffer.points.length - 1] : null;

    return {
        id: apiPriceOffer.id || '',
        dropOffDockingHoursFrom: lastPoint?.from || '',
        dropOffDockingHoursTo: lastPoint?.to || '',
        pickupDockingHoursFrom: firstPoint?.from || '',
        pickupDockingHoursTo: firstPoint?.to || '',
        dropOffDate: getDateFromISO(lastPoint?.from),
        pickUpDate: getDateFromISO(firstPoint?.from),
        isGreenTruck: !!findAdditionalServiceByType(
            apiPriceOffer?.additionalServices,
            BROKER_ADDITIONAL_SERVICES_TYPE.LOW_EMISSIONS,
        ),
        isUrgentShipment: !!findAdditionalServiceByType(
            apiPriceOffer?.additionalServices,
            BROKER_ADDITIONAL_SERVICES_TYPE.URGENT_PICKUP,
        ),
        isTeamDrive: apiPriceOffer.teamDriveCost !== null,
        hasLayover: Boolean(apiPriceOffer.layover?.totalSeconds),
        layoverSeconds: apiPriceOffer.layover?.totalSeconds || 0,
        distanceKm: convertToKm(apiPriceOffer.distances?.totalDistance) || 0,
        co2: apiPriceOffer.co2 || 0,
        emissionClass: apiPriceOffer.emissionClass,
        travelSeconds: apiPriceOffer.travelSeconds || 0,
        price: apiPriceOffer.totalPrice,
        expiration: apiPriceOffer.expiration || null,
        expirationMs: parseDateString(apiPriceOffer.expiration),
        isExpired: checkIsPastDate(expirationMs),
        brokerInfo: apiPriceOffer.brokerInfo
            ? {
                  ...apiPriceOffer.brokerInfo,
                  appliedRules: map(
                      apiPriceOffer.brokerInfo?.appliedRules,
                      (rule): NonNullable<PriceOfferT['brokerInfo']>['appliedRules'][0] | null => {
                          if (!rule.valueType) {
                              return null;
                          }

                          return {
                              name: rule.name,
                              target: rule.target,
                              value: rule.value,
                              valueType: rule.valueType,
                          };
                      },
                  ).filter(isNonNil),
              }
            : undefined,
        priceComponents: {
            lineHaul: apiPriceOffer.lineHaul,
            toll: apiPriceOffer.toll?.totalCost,
            fuel: apiPriceOffer.fuel?.totalCost,
            fuelByCountry: apiPriceOffer.fuel?.byCountry,
            additionalServicesTotalCost: apiPriceOffer.additionalServices?.totalCost,
            additionalServicesItems: apiPriceOffer.additionalServices?.items,
            tranziitServiceFee: apiPriceOffer?.serviceFee,
            layover: apiPriceOffer.layover?.totalCost,
            teamDriveCost: apiPriceOffer.teamDriveCost,
            tollByRoadType: apiPriceOffer.toll?.byRoadType,
            totalRate: apiPriceOffer.totalRate,
            costOfOperation: apiPriceOffer.costOfOperation,
            teamDriveHours: apiPriceOffer.teamDriveHours,
        },
    };
};
