import * as React from 'react';
import AdditionalServiceLabel from 'common/components/AdditionalServiceLabel/AdditionalServiceLabel';
import { DictAdditionalServiceT } from 'common/store/additional-services-dict/models';
import { ApiBrokerAdditionalServiceTypeT, ApiCarrierAdditionalServiceTypeT } from 'common/utils/api/models';

type PropsT = {
    types: Array<
        | DictAdditionalServiceT['enum']
        | ApiCarrierAdditionalServiceTypeT
        | ApiBrokerAdditionalServiceTypeT
        | null
        | undefined
    >;
};

const EmissionStandartAdditionalServicesFormatter: React.FC<PropsT> = React.memo((props) => {
    const { types } = props;

    return (
        <span>
            {types.map((type, index) => {
                if (!type) {
                    return null;
                }

                return (
                    <>
                        <AdditionalServiceLabel type={type} />
                        {!!types[index + 1] && <span>{', '}</span>}
                    </>
                );
            })}
        </span>
    );
});

export default EmissionStandartAdditionalServicesFormatter;
