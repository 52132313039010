import { authRoutesEnum, CommonRoutesEnum } from 'common/constants';

export const routesEnum = {
    home: authRoutesEnum.home,
    signIn: authRoutesEnum.signIn,
    signUp: authRoutesEnum.signUp,
    continueSignUp: authRoutesEnum.continueSignUp,
    forgotPassword: authRoutesEnum.forgotPassword,
    changePassword: authRoutesEnum.changePassword,
    confirmEmail: authRoutesEnum.confirmEmail,
    selfCreateCompany: authRoutesEnum.selfCreateCompany,

    notFound: CommonRoutesEnum.notFound,
    account: CommonRoutesEnum.account,
    accessDenied: CommonRoutesEnum.accessDenied,

    spotRequests: '/spot',
    orders: '/orders',
    dispatches: '/dispatches',
    settings: '/settings',
    statistics: '/statistics',
    partners: '/partners',
    invoices: '/invoices',
    partner: '/partners/:partnerType/:partnerId/partner',
    assetDetails: '/partners/:partnerType/:partnerId/asset',
    shipperContractDetails: '/partners/:partnerType/:partnerId/shipper-contracts/:contractId',
    shipperLaneDetails: '/partners/:partnerType/:partnerId/shipper-contract-lanes/:contractId',
};

export enum ShipperContractDetailsEnum {
    details = '/partners/:partnerType/:partnerId/shipper-contracts/:contractId/details',
    lanes = '/partners/:partnerType/:partnerId/shipper-contracts/:contractId/lanes',
}

export enum PartnersRoutesEnum {
    partnerDetails = '/partners/:partnerType/:partnerId/partner/details',
    partnerDocuments = '/partners/:partnerType/:partnerId/partner/documents',
    partnerMembers = '/partners/:partnerType/:partnerId/partner/members',
    partnerOrders = '/partners/:partnerType/:partnerId/partner/orders',
    partnerInvoices = '/partners/:partnerType/:partnerId/partner/invoices',
    partnerTrailers = '/partners/:partnerType/:partnerId/partner/trailers',
    partnerTrucks = '/partners/:partnerType/:partnerId/partner/trucks',
    partnerDrivers = '/partners/:partnerType/:partnerId/partner/drivers',
    partnerTransportOrders = '/partners/:partnerType/:partnerId/partner/transport-orders',
    partnerTransportOrderDetails = '/partners/:partnerType/:partnerId/partner/transport-order/:transportOrderId',
}

export enum PartnersDocumentsRoutesEnum {
    requiredPartnerDocuments = '/partners/:partnerType/:partnerId/partner/documents/required',
    optionalPartnerDocuments = '/partners/:partnerType/:partnerId/partner/documents/optional',
    partnerCarrierContracts = '/partners/:partnerType/:partnerId/partner/documents/carrier-contracts',
    partnerShipperContracts = '/partners/:partnerType/:partnerId/partner/documents/shipper-contracts',
}

export enum OMSRoutesEnum {
    orderDetails = '/orders/:orderId',
    newOrder = '/orders/new',
}

export enum ShipperContractLaneRoutesEnum {
    newLane = '/partners/:partnerType/:partnerId/shipper-contract-lanes/:contractId/new',
    laneDetails = '/partners/:partnerType/:partnerId/shipper-contract-lanes/:contractId/lane/:laneId',
}

export enum SpotRoutesEnum {
    list = '/spot',
    spotRequestDetails = '/spot/:spotRequestId',
}

export enum DispatchesRoutesEnum {
    dispatchRouteEdit = '/dispatches/:dispatchId/route-edit',
    dispatchAssignment = '/dispatches/:dispatchId/tours/:tourId/assignment',
    dispatchDetails = '/dispatches/:dispatchId',
}

export enum SettingsRoutesEnum {
    rateMatrix = '/settings/rate-matrix',
    rules = '/settings/rules',
    systemSettings = '/settings/system',
    additionalServices = '/settings/additional-services',
}

export enum AssetRoutesEnum {
    truckDetails = '/partners/:partnerType/:partnerId/asset/truck/:truckId',
    trailerDetails = '/partners/:partnerType/:partnerId/asset/trailer/:trailerId',
}

export default {};
