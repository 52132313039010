import { DispatchDetailsT } from 'broker-admin/store/dispatch-details/models';
import { findTourById } from 'broker-admin/store/dispatch-details/utils/find-tour-by-id';
import { getDateFromISO } from 'common/utils/time';
import { StopTypeEnum } from 'common/utils/api/models';

export const findFirstPickupDeliveryWaypointDate = (
    dispatchDetails: DispatchDetailsT | null,
    tourId: TourIdT | null,
): string | null => {
    const tour = findTourById(dispatchDetails, tourId);
    const waypoints = tour?.waypoints || [];

    const firstPickupDeliveryWaypoint =
        waypoints.find((waypoint) => {
            return (
                waypoint?.type === StopTypeEnum.pickupDeliveryShipment || waypoint?.type === StopTypeEnum.dropAndHook
            );
        }) || null;

    const dateTimeFrom =
        firstPickupDeliveryWaypoint?.correctedDateTimeFrom || firstPickupDeliveryWaypoint?.originalDateTimeFrom || null;
    if (!dateTimeFrom) {
        return null;
    }

    return getDateFromISO(dateTimeFrom);
};
