import RouteIcon from 'common/icons/RouteIcon';
import { BROKER_ADDITIONAL_SERVICES_TYPE, DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import * as React from 'react';
import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import TeamDriveIcon from 'common/icons/TeamDriveIcon';
import OptionsIcon from 'common/icons/OptionsIcon';
import TollIcon from 'common/icons/TollIcon';
import FuelIcon from 'common/icons/FuelIcon';
import TimeWindowIcon from 'common/icons/TimeWindowIcon';
import UrgentIcon from 'common/icons/UrgentIcon';
import EcoIcon from 'common/icons/EcoIcon';
import FeeIcon from 'common/icons/FeeIcon';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import isNumber from 'lodash/isNumber';
import { convertSecToHours } from 'common/utils/time';
import {
    ALL_TOLL_TYPES,
    ApiBrokerAdditionalServiceTypeT,
    ApiCarrierAdditionalServiceTypeT,
    APIFuelByCountryT,
    ApiTollByRoadTypeT,
    CountryCodeT,
} from 'common/utils/api/models';
import { PriceDetailT } from 'design-system/components/PriceDetails/PriceDetails';
import map from 'lodash/map';
import keys from 'lodash/keys';
import FlagIcon from 'common/icons/FlagIcon/FlagIcon';
import AsyncCountryFormatter from 'design-system/components/InfoTable/formatters/AsyncCountryFormatter/AsyncCountryFormatter';
import { TOLL_MAP_T, TOLL_TOOLTIP_MAP_T } from 'common/components/TollLabel/TollLabel';
import { isNonNil } from 'common/utils';
import EmissionUnitValue from 'common/components/EmissionUnitValue/EmissionUnitValue';
import AdditionalServiceFormatter from 'design-system/components/InfoTable/formatters/AdditionalServiceFormatter/AdditionalServiceFormatter';
import { DictAdditionalServiceT } from 'common/store/additional-services-dict/models';

export const useCommonPriceDetails = () => {
    const { t } = useTranslation();

    return useMemo(() => {
        return {
            lineHaul: {
                iconNode: <RouteIcon fillColor={StyleGuideColorsEnum.gray} />,
                title: t('common:price-details.line-haul.title'),
                tooltipNode: (
                    <TooltipContent theme={TooltipContentThemeEnum.black} width={150}>
                        {t('common:price-details.line-haul.tooltip')}
                    </TooltipContent>
                ),
            },
            otherCosts: {
                iconNode: null,
                title: t('common:price-details.other-costs.title'),
            },
            teamDrive: {
                iconNode: (
                    <TeamDriveIcon
                        size={DEFAULT_ICON_SIZE}
                        fillColor={StyleGuideColorsEnum.light}
                        strokeColor={StyleGuideColorsEnum.gray}
                    />
                ),
                tooltipNode: (
                    <TooltipContent theme={TooltipContentThemeEnum.black} width={150}>
                        {t('common:price-details.team-drive.tooltip')}
                    </TooltipContent>
                ),
                title: t('common:price-details.team-drive.title'),
            },
            getRoadTaxes: (co2: number | null | undefined) => {
                return {
                    iconNode: (
                        <TollIcon
                            size={DEFAULT_ICON_SIZE}
                            fillColor={StyleGuideColorsEnum.light}
                            strokeColor={StyleGuideColorsEnum.gray}
                        />
                    ),
                    title: t('common:price-details.road-taxes.title'),
                    pillLabelNode: isNumber(co2) ? (
                        <Trans
                            i18nKey="common:price-details.road-taxes.co2"
                            components={{
                                value: <EmissionUnitValue co2={co2} />,
                            }}
                        />
                    ) : null,
                };
            },
            getRoadTaxesList: (tollByRoadType: ApiTollByRoadTypeT | undefined) => {
                if (!tollByRoadType) {
                    return [];
                }

                const tollList = tollByRoadType
                    ? ALL_TOLL_TYPES.map((tollType): PriceDetailT | null => {
                          const toll = tollByRoadType?.[tollType];
                          if (!toll) {
                              return null;
                          }

                          const { cost, byCountry } = toll;
                          if (!cost && cost !== 0) {
                              return null;
                          }

                          const list = map(keys(byCountry), (countryCode): PriceDetailT => {
                              const countryCost = byCountry?.[countryCode];

                              return {
                                  iconNode: <FlagIcon countryCode={countryCode} />,
                                  title:
                                      !countryCode || countryCode === 'null' ? (
                                          t('common:tolls.unspecified')
                                      ) : (
                                          <AsyncCountryFormatter countryCode={countryCode as CountryCodeT} />
                                      ),
                                  price: countryCost,
                              };
                          });

                          const tollTooltip = TOLL_TOOLTIP_MAP_T[tollType];

                          return {
                              iconNode: null,
                              title: t(TOLL_MAP_T[tollType]),
                              tooltipNode: tollTooltip ? (
                                  <TooltipContent theme={TooltipContentThemeEnum.black} width={150} isCenter>
                                      {t(tollTooltip)}
                                  </TooltipContent>
                              ) : null,
                              price: cost,
                              list,
                          };
                      }).filter(isNonNil)
                    : [];

                return tollList;
            },
            getLineHaulList: ({
                deadheadLineHaulCost,
                payloadLineHaulCost,
            }: {
                deadheadLineHaulCost?: number;
                payloadLineHaulCost?: number;
            }) => {
                const result = [
                    isNumber(deadheadLineHaulCost)
                        ? {
                              iconNode: null,
                              title: t('common:price-details.line-haul-deadhead.title'),
                              price: deadheadLineHaulCost,
                          }
                        : null,
                    isNumber(payloadLineHaulCost)
                        ? {
                              iconNode: null,
                              title: t('common:price-details.line-haul-payload.title'),
                              price: payloadLineHaulCost,
                          }
                        : null,
                ].filter(isNonNil);

                if (!result?.length) {
                    return undefined;
                }

                return result;
            },
            fuelCost: {
                iconNode: <FuelIcon strokeColor={StyleGuideColorsEnum.gray} fillColor={StyleGuideColorsEnum.light} />,
                title: t('common:price-details.fuel-price.title'),
            },
            getFuelCostsByCountryList: (fuelByCountry: APIFuelByCountryT | undefined) => {
                return (
                    fuelByCountry?.map(({ country, cost }) => {
                        // @ts-expect-error This comparison appears to be unintentional because the types 'Countries' and '"null"' have no overlap.
                        const isUnspecified = country === 'null';

                        return {
                            iconNode: <FlagIcon countryCode={country} />,
                            title:
                                !country || isUnspecified ? (
                                    t('common:tolls.unspecified')
                                ) : (
                                    <AsyncCountryFormatter countryCode={country} />
                                ),
                            price: cost,
                        };
                    }) || []
                );
            },
            additionalServices: {
                iconNode: (
                    <OptionsIcon fillColor={StyleGuideColorsEnum.light} strokeColor={StyleGuideColorsEnum.gray} />
                ),
                title: t('common:price-details.additional-services.title'),
            },

            getLayoverCost: (layoverSeconds: number | null | undefined) => {
                const layoverHours = isNumber(layoverSeconds) ? convertSecToHours(layoverSeconds) : null;

                return {
                    iconNode: (
                        <TimeWindowIcon
                            fillColor={StyleGuideColorsEnum.light}
                            strokeColor={StyleGuideColorsEnum.gray}
                        />
                    ),
                    tooltipNode: (
                        <TooltipContent theme={TooltipContentThemeEnum.black} width={150}>
                            {t('common:price-details.layover.tooltip')}
                        </TooltipContent>
                    ),
                    title: t('common:price-details.layover.title'),
                    pillLabelNode: isNumber(layoverHours)
                        ? `${layoverHours} ${t('common:hours', {
                              postProcess: 'interval',
                              count: layoverHours,
                          })}`
                        : null,
                };
            },

            urgentOverprice: {
                iconNode: <UrgentIcon size={DEFAULT_ICON_SIZE} fillColor={StyleGuideColorsEnum.squash} />,
                title: t('common:price-details.express-surcharge.title'),
                tooltipNode: (
                    <TooltipContent theme={TooltipContentThemeEnum.black} width={150}>
                        {t('common:price-details.express-surcharge.tooltip')}
                    </TooltipContent>
                ),
            },

            greenSurcharge: {
                iconNode: <EcoIcon size={DEFAULT_ICON_SIZE} />,
                title: t('common:price-details.green-surcharge.title'),
                tooltipNode: (
                    <TooltipContent theme={TooltipContentThemeEnum.black} width={150}>
                        {t('common:price-details.green-surcharge.tooltip')}
                    </TooltipContent>
                ),
            },

            serviceFee: {
                iconNode: <FeeIcon strokeColor={StyleGuideColorsEnum.slate} />,
                title: t('common:price-details.service-fee.title'),
                tooltipNode: (
                    <TooltipContent theme={TooltipContentThemeEnum.black} width={150}>
                        {t('common:price-details.service-fee.tooltip')}
                    </TooltipContent>
                ),
            },

            tranziitServiceFee: {
                iconNode: <FeeIcon strokeColor={StyleGuideColorsEnum.slate} />,
                title: t('common:price-details.tranziit-service-fee.title'),
            },
        } as const;
    }, [t]);
};

export const useAdditionalServicesList = ({
    additionalServices,
}: {
    additionalServices:
        | Array<{
              type: DictAdditionalServiceT['enum'] | ApiCarrierAdditionalServiceTypeT | ApiBrokerAdditionalServiceTypeT;
              cost: DictAdditionalServiceT['cost'];
          }>
        | null
        | undefined;
}) => {
    const commonPriceDetails = useCommonPriceDetails();

    return React.useMemo(() => {
        return map(additionalServices, (additionalService): PriceDetailT => {
            if (additionalService?.type === BROKER_ADDITIONAL_SERVICES_TYPE.URGENT_PICKUP) {
                return {
                    ...commonPriceDetails.urgentOverprice,
                    price: additionalService.cost,
                };
            }

            if (additionalService?.type === BROKER_ADDITIONAL_SERVICES_TYPE.LOW_EMISSIONS) {
                return {
                    ...commonPriceDetails.greenSurcharge,
                    price: additionalService.cost,
                };
            }

            return {
                iconNode: null,
                title: <AdditionalServiceFormatter type={additionalService.type} />,
                price: additionalService.cost,
            };
        });
    }, [additionalServices, commonPriceDetails]);
};
