import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './DividerWithDropdownControl.scss';
import cs from 'classnames';
import PlusIcon from 'common/icons/PlusIcon';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import Tooltip, { TooltipPositionEnum, TooltipThemeEnum } from 'design-system/components/Tooltip/Tooltip';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import DropdownControl, {
    DropdownControlPropsT,
} from 'design-system/components/dropdowns/DropdownControl/DropdownControl';
import { MS_IN_SEC } from 'common/utils/time';

const cx = classNames.bind(styles);

export type DividerWithDropdownControlPropsT = Omit<DropdownControlPropsT, 'renderTrigger'> & {
    className?: string;
    triggerClassName?: string;
    tooltipNode: React.ReactNode;
};

const DividerWithDropdownControl: React.FC<DividerWithDropdownControlPropsT> = React.memo((props) => {
    const { className, triggerClassName, tooltipNode, ...restProps } = props;

    const renderTrigger: DropdownControlPropsT['renderTrigger'] = (isActive, onClick) => {
        return (
            <Tooltip
                position={TooltipPositionEnum.topCenter}
                theme={TooltipThemeEnum.black}
                delay={MS_IN_SEC}
                className={cs(cx('tooltip-wrap'), className)}
                tooltipNode={
                    <TooltipContent isNoWrap isCenter theme={TooltipContentThemeEnum.black}>
                        {tooltipNode}
                    </TooltipContent>
                }
            >
                {(isShow, childrenClassName) => (
                    <div
                        className={cs(
                            cx('divider', {
                                'divider--isClickable': true,
                                'divider--isToggled': isActive,
                            }),
                            childrenClassName,
                            triggerClassName,
                        )}
                        data-test-selector="divider"
                        onClick={onClick}
                    >
                        <div className={cx('divider__line')} />
                        <div className={cx('divider__icon')}>
                            <PlusIcon size={DEFAULT_ICON_SIZE} strokeColor={StyleGuideColorsEnum.currentColor} />
                        </div>
                    </div>
                )}
            </Tooltip>
        );
    };

    return <DropdownControl {...restProps} renderTrigger={renderTrigger} />;
});

export { DividerWithDropdownControl };
