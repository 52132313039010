import * as React from 'react';

import styles from './RouteSidebarSkeleton.scss';
import classNames from 'classnames/bind';

export type PropsT = {};

const cx = classNames.bind(styles);

const RouteSidebarSkeleton: React.FC<PropsT> = React.memo((props) => {
    return (
        <>
            <div className={cx('skeleton__box', 'skeleton__box--first')} />
            <div className={cx('skeleton__box', 'skeleton__box--secondary')} />
            <div className={cx('skeleton__box', 'skeleton__box--first')} />
            <div className={cx('skeleton__box', 'skeleton__box--secondary')} />
        </>
    );
});

export default RouteSidebarSkeleton;
