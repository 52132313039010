import * as React from 'react';
import classNames from 'classnames/bind';

import styles from './StartAssignment.scss';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import { useTranslation } from 'react-i18next';
import { DispatchDetailsT } from 'broker-admin/store/dispatch-details/models';
import TimeLeftForStart from 'common/layouts/sidebar-footers/TimeLeftForStart/TimeLeftForStart';
import { useSelector } from 'react-redux';
import { selectSpotRequestLimitMs } from 'broker-admin/store/settings/selectors';
import moment from 'moment/moment';
import Tooltip, { TooltipPositionEnum, TooltipThemeEnum } from 'design-system/components/Tooltip/Tooltip';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
    tourId: TourIdT | null;
    tour: DispatchDetailsT['tours'][number] | null | undefined;
    canAssignmentDispatch: boolean;
    isNeedAssignAssets: boolean;
    onStartAssignment: () => void;
    isNeedSendToSpot: boolean;
    onStartSendToSpot: () => void;
    isDisableAnyActions: boolean;
};

const StartAssignment: React.FC<PropsT> = React.memo((props) => {
    const {
        tourId,
        tour,
        canAssignmentDispatch,
        isNeedAssignAssets,
        onStartAssignment,
        isNeedSendToSpot,
        onStartSendToSpot,
        isDisableAnyActions,
    } = props;

    const { t } = useTranslation();

    const spotRequestLimitMs = useSelector(selectSpotRequestLimitMs);
    const isTimedOutForSendToSpot = React.useMemo((): boolean => {
        if (!tour) {
            return false;
        }

        const firstWaypoint = tour?.waypoints?.[0] || null;
        const pickupDockingHoursFrom =
            firstWaypoint?.correctedDateTimeFrom || firstWaypoint?.originalDateTimeFrom || null;
        if (!pickupDockingHoursFrom) {
            return false;
        }
        const pickupDockingHoursFromTimestamp = moment(pickupDockingHoursFrom).valueOf();
        const deadline = pickupDockingHoursFromTimestamp - spotRequestLimitMs;
        const diff = deadline - Date.now();

        return diff <= 0;
    }, [tour, spotRequestLimitMs]);

    if (!tour) {
        return null;
    }

    const firstWaypoint = tour?.waypoints?.[0] || null;
    const deadline = firstWaypoint?.correctedDateTimeFrom || firstWaypoint?.originalDateTimeFrom || null;

    return (
        <TimeLeftForStart
            deadline={deadline}
            alreadyStartedLabel={t('assignment.tour-already-started')}
            deadlineLabel={t('assignment.to-assign')}
            renderActions={(isDisabled) => (
                <>
                    {canAssignmentDispatch && isNeedAssignAssets && (
                        <Button
                            theme={ButtonThemeEnum.primary}
                            className={cx('action', 'action--assign')}
                            onClick={onStartAssignment}
                            isDisabled={isDisabled || isDisableAnyActions}
                        >
                            {t('order-details.actions.start-assignment')}
                        </Button>
                    )}
                    {canAssignmentDispatch && isNeedSendToSpot && (
                        <Tooltip
                            position={TooltipPositionEnum.topCenter}
                            theme={TooltipThemeEnum.black}
                            tooltipNode={
                                isTimedOutForSendToSpot ? (
                                    <TooltipContent width={150} isCenter theme={TooltipContentThemeEnum.black}>
                                        {t('order-details.tooltips.timeout-send-to-spot')}
                                    </TooltipContent>
                                ) : null
                            }
                        >
                            {() => (
                                <Button
                                    theme={ButtonThemeEnum.secondary}
                                    className={cx('action')}
                                    onClick={onStartSendToSpot}
                                    isDisabled={isDisabled || isTimedOutForSendToSpot || isDisableAnyActions}
                                >
                                    {t('order-details.actions.send-to-spot')}
                                </Button>
                            )}
                        </Tooltip>
                    )}
                </>
            )}
        />
    );
});

export default StartAssignment;
