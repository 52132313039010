import { RoutePointIdByStopIndexT } from 'broker-admin/store/order-edit/models';

export const patchRoutePointIdByStopIndexForAddStop = (
    routePointIdByStopIndex: RoutePointIdByStopIndexT,
    baseIndex: number,
    baseRoutePointId: RoutePointIdT,
): RoutePointIdByStopIndexT => {
    const result: RoutePointIdByStopIndexT = {};

    result[baseIndex] = baseRoutePointId;

    Object.keys(routePointIdByStopIndex).forEach((stopIndex) => {
        const parsedStopIndex = parseInt(stopIndex, 10);

        let resultStopIndex = parsedStopIndex;
        if (parsedStopIndex >= baseIndex) {
            resultStopIndex += 1;
        }

        result[resultStopIndex] = routePointIdByStopIndex[stopIndex];
    });

    return result;
};

export const patchRoutePointIdByStopIndexForDeleteStop = (
    routePointIdByStopIndex: RoutePointIdByStopIndexT,
    baseIndex: number,
): RoutePointIdByStopIndexT => {
    const result: RoutePointIdByStopIndexT = {};

    Object.keys(routePointIdByStopIndex).forEach((stopIndex) => {
        const parsedStopIndex = parseInt(stopIndex, 10);
        if (parsedStopIndex === baseIndex) {
            return;
        }

        let resultStopIndex = parsedStopIndex;
        if (parsedStopIndex > baseIndex) {
            resultStopIndex = Math.max(resultStopIndex - 1, 0);
        }

        result[resultStopIndex] = routePointIdByStopIndex[stopIndex];
    });

    return result;
};
