import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './IconWithNumber.scss';

const cx = classNames.bind(styles);

type PropsT = {
    isCompact?: boolean;
    number?: number;
    children?: React.ReactNode;
};

export const clusterIconWithNumberStyle = {
    paddingTop: 3,
    paddingBottom: 3,
} as const;

const IconWithNumber: React.FC<PropsT> = (props) => {
    const { children, number, isCompact } = props;

    return (
        <div className={cx('row')}>
            <div className={cx('icon', { 'icon--isCompact': isCompact })}>{children}</div>
            <div className={cx('number', { 'number--isCompact': isCompact })}>{number}</div>
        </div>
    );
};

export default IconWithNumber;
