import * as React from 'react';
import { useMemo } from 'react';

import classNames from 'classnames/bind';

import styles from './DispatchDetailsPage.scss';

import { useParams } from 'react-router-dom';
import useGoogleMaps from 'common/utils/hooks/useGoogleMaps';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import history, { goBackIfHasRePath } from 'common/utils/history';
import { DispatchesRoutesEnum, routesEnum } from 'broker-admin/constants';
import GoogleMapContext from 'common/contexts/google-map-context';
import HorizontalColumnsLayout from 'common/layouts/HorizontalColumnsLayout/HorizontalColumnsLayout';
import HeaderSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarLayout/HeaderSideBarLayout';
import HeaderSideBarContent from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarContent/HeaderSideBarContent';
import SideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLayout';
import {
    selectDispatchDetails,
    selectDispatchDetailsRequestStatus,
} from 'broker-admin/store/dispatch-details/selectors';
import { fetchDispatchDetails } from 'broker-admin/store/dispatch-details/actions';
import DispatchDetails from './DispatchDetails/DispatchDetails';
import {
    ApiDispatchStatusT,
    ApiRouteEditBlockReasonT,
    DispatchStatusEnum,
    PartnerTypeEnum,
} from 'common/utils/api/models';
import FooterSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/FooterSideBarLayout/FooterSideBarLayout';
import { formatQuery } from 'common/utils/query';
import { StyleGuideColorsEnum } from 'common/constants';
import ParanjaSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/ParanjaSideBarLayout/ParanjaSideBarLayout';
import useQuery from 'common/utils/hooks/useQuery';
import TabsLabel from 'common/components/TabsLabel/TabsLabel';
import TabLabel from 'common/components/TabsLabel/TabLabel/TabLabel';
import { TabsThemesEnum } from 'common/components/TabsLabel/constants';
import EditTimeWindowsFooterContent from './footers/EditTimeWindowsFooterContent/EditTimeWindowsFooterContent';
import { selectUpdateDispatchRequest } from 'broker-admin/store/dispatch-edit/selectors';
import DropdownControlOptionLabel from 'design-system/components/dropdowns/option/DropdownControlOptionLabel/DropdownControlOptionLabel';
import CloseIcon from 'common/icons/CloseIcon';
import CancelDispatchConfirmation, {
    CancelingDispatchConfirmationDataT,
} from './dialogs/CancelDispatchConfirmation/CancelDispatchConfirmation';
import DispatchStatusPill from 'common/components/status-pill/DispatchStatusPill/DispatchStatusPill';
import SideBarLayoutContextWrap from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLayoutContextWrap/SideBarLayoutContextWrap';
import useDocumentVisibilityChange from 'common/utils/hooks/useDocumentVisibilityChange';
import HeaderSideBarLoader from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarLoader/HeaderSideBarLoader';
import EntitySidebarSkeleton from 'common/components/order-details/EntitySidebarSkeleton/EntitySidebarSkeleton';
import SideBarLoader from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLoader/SideBarLoader';
import PageTitle from 'common/components/PageTitle/PageTitle';
import SendingToSpotFooterContent from './footers/SendingToSpotFooterContent/SendingToSpotFooterContent';
import { selectSpotUpdateRequestStatus } from 'broker-admin/store/spot/selectors';
import useModalDialog from 'common/utils/hooks/useModalDialog';

import useCloseModalDialogAfterRequest from 'common/utils/hooks/useCloseModalDialogAfterRequest';
import { useOpenLeftSidebar } from 'broker-admin/layouts/SideBars/hooks';
import { CommonSidebarsTypeEnum } from 'common/layouts/SideBars/models';
import { logWarning } from 'common/utils/logger';
import SideBars from 'broker-admin/layouts/SideBars/SideBars';
import DispatchRouteMap from 'broker-admin/layouts/DispatchesPage/DispatchDetailsPage/DispatchRouteMap/DispatchRouteMap';
import { useChannelSubscribe } from 'common/utils/hooks/useChannelSubscribe';
import { stateMachineRefreshChannel } from 'common/store/state-machine/channels';
import { StateMachineEntityDescriptorT } from 'common/store/state-machine/models';
import { updateEntityStatus } from 'common/store/state-machine/slice';
import { useStateMachine } from 'common/utils/hooks/useStateMachine';
import { urlFactory } from 'broker-admin/utils/urls';
import FeaturesContext from 'common/contexts/features';
import { ClientFeatureEnum } from 'common/utils/features/client-features-config';
import { SpecialOptionEnum } from 'design-system/components/dropdowns/DropdownControl/DropdownControl';
import RouteIcon from 'common/icons/RouteIcon';
import Tooltip, { TooltipPositionEnum, TooltipThemeEnum } from 'design-system/components/Tooltip/Tooltip';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import { QueryKeysEnum, QueryT } from './query-models';
import { ExtractRouterRouteParamsT } from 'common/utils/extract-router-route-params-t';

const cx = classNames.bind(styles);

const editBlockReasonI18nMap: Record<ApiRouteEditBlockReasonT, string> = {
    KAM_IS_NOT_ALLOWED_TO_EDIT_ORDER: 'dispatch-route-edit.edit-block-reason.KAM_IS_NOT_ALLOWED_TO_EDIT_ORDER',
    AT_LEAST_ONE_TOUR_NOT_IN_STATUS_CARRIER_UNASSIGNED:
        'dispatch-route-edit.edit-block-reason.AT_LEAST_ONE_TOUR_NOT_IN_STATUS_CARRIER_UNASSIGNED',
    AT_LEAST_ONE_TOUR_HAS_ACTIVE_TRANSPORTATION_ORDER:
        'dispatch-route-edit.edit-block-reason.AT_LEAST_ONE_TOUR_HAS_ACTIVE_TRANSPORTATION_ORDER',
    AT_LEAST_ONE_TOUR_IS_ON_SPOT: 'dispatch-route-edit.edit-block-reason.AT_LEAST_ONE_TOUR_IS_ON_SPOT',
    ORDER_IS_LOCKED_FOR_EDITING_BY_ANOTHER_USER:
        'dispatch-route-edit.edit-block-reason.ORDER_IS_LOCKED_FOR_EDITING_BY_ANOTHER_USER',
};

type PropsT = {};

const DispatchDetailsPage: React.FC<PropsT> = React.memo((props) => {
    const query = useQuery<QueryT>();

    const params = useParams<ExtractRouterRouteParamsT<DispatchesRoutesEnum.dispatchDetails>>();
    const { dispatchId } = params;

    const googleMaps = useGoogleMaps();
    const googleMapsContextValue = React.useMemo(() => {
        return {
            googleMaps,
        };
    }, [googleMaps]);

    const { t } = useTranslation();

    const dispatchDetails = useSelector(selectDispatchDetails(dispatchId));
    const dispatchDetailsRequestStatus = useSelector(selectDispatchDetailsRequestStatus(dispatchId));

    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(fetchDispatchDetails(dispatchId));
    }, [dispatchId]);

    const documentVisibilityChangeHandler = React.useCallback(() => {
        dispatch(fetchDispatchDetails(dispatchId, { isForceUpdate: false }));
    }, [dispatchId]);
    useDocumentVisibilityChange(documentVisibilityChangeHandler);

    const refreshPageHandler = React.useCallback(() => {
        dispatch(fetchDispatchDetails(dispatchId, { isForceUpdate: true }));
    }, [dispatchId]);
    useChannelSubscribe(stateMachineRefreshChannel, refreshPageHandler);

    const closeOrderDetails = () => {
        goBackIfHasRePath(() => {
            history.push(routesEnum.dispatches + history.location.search);
        });
    };

    const sendingToSpotTourId = query[QueryKeysEnum.sendingToSpotTourId] || null;
    const isActiveSendingToSpot = !!sendingToSpotTourId;
    const editingTimeWindowTourId = query[QueryKeysEnum.editingTimeWindowTourId] || null;
    const isActiveTimeWindowEdit = !!editingTimeWindowTourId;

    const handleStartEditTimeWindows = (tourId: TourIdT) => {
        history.push({
            search: formatQuery({
                ...query,
                [QueryKeysEnum.editingTimeWindowTourId]: tourId,
            }),
        });
    };
    const handleStartAssignment = (tourId: TourIdT) => {
        history.push(urlFactory.dispatchDetailsAssignments(dispatchId || '-', tourId));
    };

    const handleStartSendToSpot = (tourId: TourIdT) => {
        history.push({
            search: formatQuery({
                ...query,
                [QueryKeysEnum.sendingToSpotTourId]: tourId,
            }),
        });
    };

    const handleCloseSendingToSpot = () => {
        history.push({
            search: formatQuery({
                ...query,
                [QueryKeysEnum.sendingToSpotTourId]: undefined,
            }),
        });
    };

    const closeEditTimeWindows = () => {
        history.push({
            search: formatQuery({
                ...query,
                [QueryKeysEnum.editingTimeWindowTourId]: undefined, // remove
            }),
        });
    };

    const editTimeWindowsRequestStatus = useSelector(selectUpdateDispatchRequest);
    React.useEffect(() => {
        if (editTimeWindowsRequestStatus.ok) {
            closeEditTimeWindows();
        }
    }, [editTimeWindowsRequestStatus.ok]);

    const spotRequestStatus = useSelector(selectSpotUpdateRequestStatus);
    React.useEffect(() => {
        if (spotRequestStatus.ok) {
            handleCloseSendingToSpot();
        }
    }, [spotRequestStatus.ok]);

    const [activeIndexTab, setActiveIndexTab] = React.useState<number>(0);

    const tabs = [
        {
            label: t('dispatch-details.tabs.map'),
            node: <DispatchRouteMap key={dispatchId} dispatchDetails={dispatchDetails} />,
        },
        // {
        //     label: t('dispatch-details.tabs.activity'),
        //     node: <div>activity</div>,
        // },
    ];

    const renderFooterNode = (): React.ReactNode => {
        if (isActiveTimeWindowEdit) {
            return (
                <FooterSideBarLayout isTransparent hasShadow>
                    <EditTimeWindowsFooterContent
                        dispatchId={dispatchId}
                        dispatchDetails={dispatchDetails}
                        tourId={editingTimeWindowTourId}
                        onCancel={closeEditTimeWindows}
                    />
                </FooterSideBarLayout>
            );
        }

        if (isActiveSendingToSpot) {
            return (
                <>
                    <ParanjaSideBarLayout />
                    <FooterSideBarLayout isTransparent hasShadow>
                        <SendingToSpotFooterContent
                            dispatchId={dispatchId}
                            dispatchDetails={dispatchDetails}
                            tourId={sendingToSpotTourId}
                            onCancel={handleCloseSendingToSpot}
                        />
                    </FooterSideBarLayout>
                </>
            );
        }

        return null;
    };

    const descriptor = useMemo((): StateMachineEntityDescriptorT | null => {
        if (!dispatchDetails?.id) {
            return null;
        }

        return {
            entityType: 'DISPATCH',
            entityId: dispatchDetails.id,
        };
    }, [dispatchDetails]);

    const { updateRequest, availableSubjectStatuses } = useStateMachine<ApiDispatchStatusT>(descriptor);

    const cancelingDispatchConfirmation = useModalDialog<CancelingDispatchConfirmationDataT>();
    const handleCancelDispatch = React.useCallback(
        (data: CancelingDispatchConfirmationDataT) => {
            if (!descriptor) {
                return;
            }

            dispatch(
                updateEntityStatus({
                    descriptor,
                    update: {
                        entityType: 'DISPATCH',
                        entityId: data.dispatchId,
                        status: DispatchStatusEnum.canceled,
                    },
                }),
            );
        },
        [descriptor, dispatch],
    );

    useCloseModalDialogAfterRequest(updateRequest, [cancelingDispatchConfirmation]);

    const isAvailableCanceled =
        availableSubjectStatuses?.backward?.includes(DispatchStatusEnum.canceled) ||
        availableSubjectStatuses?.forward?.includes(DispatchStatusEnum.canceled);

    const featuresContext = React.useContext(FeaturesContext);

    const isAvailableRouteEditingFeature = !!featuresContext?.checkActiveClientFeature?.(
        ClientFeatureEnum.routeEditing,
    );
    const editBlockReason = dispatchDetails?.order?.editBlockReason || null;
    const isAvailableRouteEditing =
        isAvailableRouteEditingFeature && !!(dispatchDetails?.order?.allowToEdit || editBlockReason);
    const isDisabledRouteEditing = !dispatchDetails?.order?.allowToEdit && !!editBlockReason;

    const headerDropdownOptions = React.useMemo(() => {
        return [
            isAvailableRouteEditing
                ? {
                      label: (
                          <Tooltip
                              position={TooltipPositionEnum.centerLeft}
                              theme={TooltipThemeEnum.black}
                              tooltipNode={
                                  editBlockReason ? (
                                      <TooltipContent width={200} theme={TooltipContentThemeEnum.black}>
                                          {t(editBlockReasonI18nMap[editBlockReason])}
                                      </TooltipContent>
                                  ) : null
                              }
                          >
                              {() => (
                                  <DropdownControlOptionLabel
                                      icon={<RouteIcon fillColor={StyleGuideColorsEnum.slate} />}
                                      isDisabled={isDisabledRouteEditing}
                                      label={t('dispatch-details.header-actions.edit-route')}
                                  />
                              )}
                          </Tooltip>
                      ),
                      isDisabled: isDisabledRouteEditing,
                      onSelect: (): void => {
                          history.push(urlFactory.dispatchRouteEdit(dispatchId));
                      },
                  }
                : null,
            isAvailableRouteEditing && isAvailableCanceled ? SpecialOptionEnum.separator : null,
            isAvailableCanceled
                ? {
                      label: (
                          <DropdownControlOptionLabel
                              icon={<CloseIcon fillColor={StyleGuideColorsEnum.tomatoRed} />}
                              label={t('dispatch-details.header-actions.cancel')}
                          />
                      ),
                      onSelect: (): void => {
                          cancelingDispatchConfirmation.setData({
                              dispatchId,
                          });
                      },
                  }
                : null,
        ];
    }, [isAvailableRouteEditing, isAvailableCanceled, dispatchId]);

    const isShowLineLoader = dispatchDetailsRequestStatus.loading && !!dispatchDetails;
    const isShowOverlayLoader = !dispatchDetailsRequestStatus.error && !dispatchDetails;

    const openLeftSidebar = useOpenLeftSidebar();
    const handleOpenUserDetails = (params: {
        partnerType: PartnerTypeEnum;
        partnerId: PartnerIdT;
        userId: UserIdT | null;
    }) => {
        const { partnerId, partnerType, userId } = params;
        if (!userId) {
            logWarning('failed to open user details in dispatch details, empty user id');
            return;
        }

        openLeftSidebar(
            {
                type: CommonSidebarsTypeEnum.contact,
                partnerId,
                partnerType,
                userId,
            },
            {
                isForceShowBackAction: true,
            },
        );
    };

    return (
        <>
            <GoogleMapContext.Provider value={googleMapsContextValue}>
                <PageTitle
                    title={t('page-titles.dispatch-details', {
                        number: dispatchDetails?.number || '',
                    })}
                />
                <HorizontalColumnsLayout
                    testSelector="dispatch-details"
                    className={cx('wrap')}
                    leftColumnTitle={t('dispatch-details.main-tabs.details')}
                    leftColumnNode={
                        <SideBarLayoutContextWrap>
                            <HeaderSideBarLayout>
                                <HeaderSideBarContent
                                    title={
                                        dispatchDetails
                                            ? t('dispatch-details.title', {
                                                  number: dispatchDetails?.number || '',
                                              })
                                            : null
                                    }
                                    afterTitleNode={
                                        <DispatchStatusPill
                                            isSymmetrical
                                            status={dispatchDetails?.status}
                                            isArchived={dispatchDetails?.archived}
                                        />
                                    }
                                    onClose={closeOrderDetails}
                                    dropdownOptions={headerDropdownOptions}
                                />
                                <HeaderSideBarLoader isShow={isShowLineLoader} />
                            </HeaderSideBarLayout>
                            <SideBarLayout>
                                {dispatchDetails ? (
                                    <DispatchDetails
                                        isDisableAnyActions={dispatchDetailsRequestStatus.loading}
                                        onOpenUserDetails={handleOpenUserDetails}
                                        onStartAssignment={handleStartAssignment}
                                        onStartSendToSpot={handleStartSendToSpot}
                                        onStartEditTimeWindows={handleStartEditTimeWindows}
                                        dispatchDetails={dispatchDetails}
                                        dispatchId={dispatchId}
                                    />
                                ) : (
                                    <EntitySidebarSkeleton />
                                )}
                            </SideBarLayout>
                            {renderFooterNode()}
                            {isShowOverlayLoader && <SideBarLoader />}
                        </SideBarLayoutContextWrap>
                    }
                    rightColumnTitle={t('dispatch-details.main-tabs.info')}
                    rightColumnNode={
                        <div className={cx('tabs-wrap')}>
                            <TabsLabel>
                                {tabs.map((tab, tabIndex) => (
                                    <TabLabel
                                        key={tabIndex}
                                        theme={TabsThemesEnum.light}
                                        onClick={() => {
                                            setActiveIndexTab(tabIndex);
                                        }}
                                        isActive={tabIndex === activeIndexTab}
                                    >
                                        {tab.label}
                                    </TabLabel>
                                ))}
                            </TabsLabel>
                            <div className={cx('tab')}>{tabs[activeIndexTab].node}</div>
                        </div>
                    }
                />
            </GoogleMapContext.Provider>
            <CancelDispatchConfirmation
                requestStatus={updateRequest}
                data={cancelingDispatchConfirmation.data}
                onConfirm={handleCancelDispatch}
                onCancel={cancelingDispatchConfirmation.onCancel}
                onClose={cancelingDispatchConfirmation.onClose}
            />
            <SideBars />
        </>
    );
});

export default DispatchDetailsPage;
