import React from 'react';
import styles from './TimeIntervalDurationPillLabel.scss';
import classNames from 'classnames/bind';
import { checkMidnightTime, getDateTime, getHours, getMinutes, getTimeRelativeStartDay } from 'common/utils/time';
import { Trans, useTranslation } from 'react-i18next';
import { isNonNil } from 'common/utils';
import PillLabel, { PillLabelThemeEnum } from 'common/components/PillLabel/PillLabel';
import Tooltip, { TooltipPositionEnum, TooltipThemeEnum } from 'design-system/components/Tooltip/Tooltip';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';

const cx = classNames.bind(styles);

type PropsT = {
    i18nKey?: string | null;
    timeWindow: TimeWindowT | null | undefined;
    className?: string;
    isDayRelative: boolean;
    isChanged: boolean;
};

const TimeIntervalDurationPillLabel: React.FC<PropsT> = (props) => {
    const { timeWindow, className, isDayRelative, i18nKey, isChanged } = props;

    const { t } = useTranslation();

    if (!timeWindow) {
        return null;
    }

    const [start, end] = timeWindow;
    const diff = timeWindow[1] - timeWindow[0];

    const formatter = isDayRelative ? getTimeRelativeStartDay : getDateTime;

    const hours = getHours(diff);
    const formattedHours = hours
        ? `${hours} ${t('common:hours', {
              postProcess: 'interval',
              count: hours,
          })}`
        : null;

    const minutes = getMinutes(diff);
    const formattedMinutes = minutes
        ? `${minutes} ${t('common:minutes', {
              postProcess: 'interval',
              count: minutes,
          })}`
        : null;

    const formattedDiff = [formattedHours, formattedMinutes].filter(isNonNil).join(' ');

    const duration = `${formatter(start)} - ${checkMidnightTime(formatter(end))}`;

    const pillNode = (
        <PillLabel
            isSymmetrical
            theme={isChanged ? PillLabelThemeEnum.blazeOrange : PillLabelThemeEnum.charcoal}
            className={className}
        >
            <span className={cx('text')}>
                {i18nKey ? (
                    <Trans
                        i18nKey={i18nKey}
                        components={{
                            duration,
                            formattedDiff,
                        }}
                    />
                ) : (
                    `${duration} (${formattedDiff})`
                )}
            </span>
        </PillLabel>
    );

    if (isChanged) {
        return (
            <Tooltip
                isInline
                position={TooltipPositionEnum.topCenter}
                theme={TooltipThemeEnum.black}
                tooltipNode={
                    <TooltipContent isNoWrap theme={TooltipContentThemeEnum.black}>
                        {t('dispatch-route-edit.pills.tooltip.changed-time-window')}
                    </TooltipContent>
                }
            >
                {() => pillNode}
            </Tooltip>
        );
    }

    return pillNode;
};

export default TimeIntervalDurationPillLabel;
