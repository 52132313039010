import { TFunction } from 'i18next';
import { AsyncFormErrorsT, FormValuesT } from './constants';

type MetaT = {};

const asyncValidations = (t: TFunction, values: FormValuesT, meta: MetaT): AsyncFormErrorsT => {
    const errors: AsyncFormErrorsT = {};

    return errors;
};

export default asyncValidations;
