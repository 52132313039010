import React, { useMemo } from 'react';
import { PointToDropT } from 'broker-admin/store/dispatch-assigment/models';

export type OnMapClickCallbackT = (pointToDrop: PointToDropT | null) => void;

export type SyncAssignmentFormMapStateContextValueT = {
    onMapClickCallback: { callback: OnMapClickCallbackT } | null;
    setOnMapClickCallback: (params: { callback: OnMapClickCallbackT }) => void;

    hasDropTrailerPointError: boolean;
    setHasDropTrailerPointError: (hasDropTrailerPointError: boolean) => void;
    hasDropTruckPointError: boolean;
    setHasDropTruckPointError: (hasDropTruckPointError: boolean) => void;
};

export const usySyncMapState = (): SyncAssignmentFormMapStateContextValueT => {
    const [onMapClickCallback, setOnMapClickCallback] = React.useState<{ callback: OnMapClickCallbackT } | null>(null);
    const [hasDropTrailerPointError, setHasDropTrailerPointError] = React.useState<boolean>(false);
    const [hasDropTruckPointError, setHasDropTruckPointError] = React.useState<boolean>(false);

    return useMemo(() => {
        return {
            onMapClickCallback,
            setOnMapClickCallback,
            hasDropTrailerPointError,
            setHasDropTrailerPointError,
            hasDropTruckPointError,
            setHasDropTruckPointError,
        };
    }, [onMapClickCallback, hasDropTrailerPointError, hasDropTruckPointError]);
};
