import React, { useMemo } from 'react';
import requestStatus, { RequestStatusT } from 'common/utils/request-status';
import { TranziitApiResultT } from 'common/utils/api/tranziit/base-tranziit-api';

export type AsyncRequestFactoryResultT<T extends {}> = TranziitApiResultT<Array<T>>;
export type AsyncRequestFactoryT<T extends {}> = () => Promise<AsyncRequestFactoryResultT<T>>;

export type UseAsyncInitOptionsParamsT<T extends {}> = {
    initRequestFactory: AsyncRequestFactoryT<T>;
};

export type UseAsyncInitOptionsResultT<T extends {}> = {
    initOptions: Array<T>;
    initRequestStatus: RequestStatusT;
};

export const useAsyncInitOptionsRequest = <T extends {}>({
    initRequestFactory,
}: UseAsyncInitOptionsParamsT<T>): UseAsyncInitOptionsResultT<T> => {
    const [currentRequestStatus, setCurrentRequestStatus] = React.useState<RequestStatusT>(requestStatus.initial);
    const [initOptions, setInitOptions] = React.useState<T[]>([]);

    React.useEffect(() => {
        setCurrentRequestStatus(requestStatus.loading);

        initRequestFactory()
            .then(([error, options]) => {
                if (error) {
                    setInitOptions([]);
                    setCurrentRequestStatus(requestStatus.setError(error));
                    return;
                }

                setInitOptions(options || []);
                setCurrentRequestStatus(requestStatus.ok);
            })
            .catch((error) => {
                setInitOptions([]);
                setCurrentRequestStatus(requestStatus.setError(error));
            });
    }, [initRequestFactory]);

    return useMemo(() => {
        return {
            initOptions,
            initRequestStatus: currentRequestStatus,
        };
    }, [initOptions, currentRequestStatus]);
};
