import * as React from 'react';
import cs from 'classnames';
import classNames from 'classnames/bind';
import styles from './AssetTypeTabs.scss';
import { useTranslation } from 'react-i18next';
import { SelfCostPredictionT } from 'broker-admin/store/dispatch-assigment/models';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import ButtonGroup from 'design-system/components/ButtonGroup/ButtonGroup';
import { AssetTypeTabsEnum } from './constants';
import { TourTypeTabsEnum } from 'broker-admin/layouts/DispatchesPage/DispatchAssignmentPage/AssignmentForm/TourTypeTabs/constants';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
    selfCostPrediction?: SelfCostPredictionT | null;
    selectedTourTypeTab: TourTypeTabsEnum;
    selectedAssetType: AssetTypeTabsEnum | null;
    onSelectAssetType: (tab: AssetTypeTabsEnum | null) => void;
};

const I18N_MAP: Record<AssetTypeTabsEnum, string> = {
    [AssetTypeTabsEnum.train]: 'assignment.asset-type-tabs.train',
    [AssetTypeTabsEnum.truck]: 'assignment.asset-type-tabs.truck',
    [AssetTypeTabsEnum.trailer]: 'assignment.asset-type-tabs.trailer',
};

const AssetTypeTabs: React.FC<PropsT> = React.memo((props) => {
    const { className, selectedTourTypeTab, selectedAssetType, onSelectAssetType, selfCostPrediction } = props;

    const { t } = useTranslation();

    const availableAssetTypeTabs = React.useMemo((): Array<AssetTypeTabsEnum> => {
        const tabs: Array<AssetTypeTabsEnum> = [];

        if (
            selectedTourTypeTab === TourTypeTabsEnum.current &&
            selfCostPrediction?.calculatedSumCost &&
            selfCostPrediction?.calculatedTruckCost &&
            selfCostPrediction?.calculatedTrailerCost
        ) {
            tabs.push(AssetTypeTabsEnum.train);
        }

        const isNextTourAssetsLink =
            selfCostPrediction?.truckNextTransportationOrderInfo?.id &&
            selfCostPrediction?.trailerNextTransportationOrderInfo?.id &&
            selfCostPrediction?.truckNextTransportationOrderInfo?.id ===
                selfCostPrediction?.trailerNextTransportationOrderInfo?.id;
        if (selectedTourTypeTab === TourTypeTabsEnum.next && isNextTourAssetsLink) {
            tabs.push(AssetTypeTabsEnum.train);
        }

        if (
            selectedTourTypeTab === TourTypeTabsEnum.next &&
            !isNextTourAssetsLink &&
            selfCostPrediction?.truckNextTourNewCostDetails
        ) {
            tabs.push(AssetTypeTabsEnum.truck);
        }

        if (
            selectedTourTypeTab === TourTypeTabsEnum.next &&
            !isNextTourAssetsLink &&
            selfCostPrediction?.trailerNextTourNewCostDetails
        ) {
            tabs.push(AssetTypeTabsEnum.trailer);
        }

        if (selectedTourTypeTab === TourTypeTabsEnum.current && selfCostPrediction?.calculatedTruckCost) {
            tabs.push(AssetTypeTabsEnum.truck);
        }

        if (selectedTourTypeTab === TourTypeTabsEnum.current && selfCostPrediction?.calculatedTrailerCost) {
            tabs.push(AssetTypeTabsEnum.trailer);
        }

        return tabs;
    }, [selectedTourTypeTab, selfCostPrediction]);

    React.useEffect(() => {
        if (!selectedAssetType || !availableAssetTypeTabs.includes(selectedAssetType)) {
            onSelectAssetType(availableAssetTypeTabs[0] || null);
        }
    }, [availableAssetTypeTabs, selectedAssetType]);

    return (
        <ButtonGroup className={cs(cx('wrap'), className)}>
            {availableAssetTypeTabs.map((type) => {
                const isSelected = type === selectedAssetType;

                return (
                    <Button
                        key={type}
                        theme={ButtonThemeEnum.transparent}
                        testSelector={type}
                        isToggled={isSelected}
                        onClick={() => {
                            onSelectAssetType(type);
                        }}
                    >
                        {t(I18N_MAP[type])}
                    </Button>
                );
            })}
        </ButtonGroup>
    );
});

export default AssetTypeTabs;
