import {
    CANCEL_SPOT_REQUEST,
    CANCEL_SPOT_REQUEST_BY_DISPATCH,
    CancelSpotRequestActionT,
    CancelSpotRequestByDispatchActionT,
    SEND_TO_SPOT,
    SendToSpotActionT,
    UPDATE_REQUEST_BEGIN,
    UPDATE_REQUEST_ERROR,
    UPDATE_REQUEST_SUCCESS,
    UpdateBeginActionT,
    UpdateErrorActionT,
    UpdateSuccessActionT,
} from './types';

export const sendToSpot = (
    dispatchId: DispatchIdT,
    tourId: TourIdT,
    deadline: string,
    comment: string,
): SendToSpotActionT => ({
    type: SEND_TO_SPOT,
    dispatchId,
    tourId,
    deadline,
    comment,
});

export const cancelSpotRequestByDispatch = (dispatchId: DispatchIdT): CancelSpotRequestByDispatchActionT => ({
    type: CANCEL_SPOT_REQUEST_BY_DISPATCH,
    dispatchId,
});

export const cancelSpotRequest = (spotRequestId: SpotRequestIdT): CancelSpotRequestActionT => ({
    type: CANCEL_SPOT_REQUEST,
    spotRequestId,
});

export const updateBegin = (): UpdateBeginActionT => ({
    type: UPDATE_REQUEST_BEGIN,
});

export const updateSuccess = (): UpdateSuccessActionT => ({
    type: UPDATE_REQUEST_SUCCESS,
});

export const updateError = (error: Error): UpdateErrorActionT => ({
    type: UPDATE_REQUEST_ERROR,
    error,
});
