import { actionChannel, fork, put, select, take, takeEvery } from 'redux-saga/effects';
import {
    applyOrderEditContextRequest,
    applyOrderEditContextRequestBegin,
    applyOrderEditContextRequestError,
    applyOrderEditContextRequestSuccess,
    changeContext,
    changeContextError,
    changeContextSuccess,
    createOrderEditContextRequest,
    createOrderEditContextRequestBegin,
    createOrderEditContextRequestError,
    createOrderEditContextRequestSuccess,
    deleteOrderEditContextRequest,
    deleteOrderEditContextRequestBegin,
    deleteOrderEditContextRequestError,
    deleteOrderEditContextRequestSuccess,
    finishOrderEdit,
    initOrderEdit,
    reCreateOrderEditContextRequest,
    refreshOrderEditContextRequest,
    resetOrderEdit,
    setIsApplyingContextChanges,
} from './slice';
import { CompanyTypeEnum } from 'common/constants';
import {
    AnyOrderEditRequestErrorActionT,
    ApplyOrderEditContextRequestActionT,
    ChangeOrderEditContextRequestActionT,
    CreateOrderEditContextRequestActionT,
    DeleteOrderEditContextRequestActionT,
    FinishOrderEditActionT,
    InitOrderEditActionT,
    ReCreateOrderEditContextRequestActionT,
    RefreshOrderEditContextRequestActionT,
} from 'broker-admin/store/order-edit/types';
import brokerTranziitApi from 'broker-admin/utils/api/broker-tranziit/api';
import {
    selectOrderEditApplyContextRequest,
    selectOrderEditContext,
    selectOrderEditContextTourUpdateStopByRoutePointId,
    selectOrderEditCreateContextRequest,
    selectOrderEditDeleteContextRequest,
    selectOrderEditDispatchDetails,
} from 'broker-admin/store/order-edit/selectors';
import { buffers } from 'redux-saga';
import {
    ApiTourUpdateStopByRoutePointIdT,
    EditOrderQueryModifyChannelEventT,
} from 'broker-admin/store/order-edit/models';
import { orderEditQueryModifyChannel } from 'broker-admin/store/order-edit/channels';
import { getOrderId } from 'broker-admin/store/order-edit/utils/get-order-id';
import { ApiEditOrderContextT } from 'broker-admin/utils/api/broker-tranziit/models';
import { convertToPointLocation } from 'broker-admin/store/order-edit/utils/prepare-address-point';
import { convertRoutePointType } from 'broker-admin/store/order-edit/utils/convert-route-point-type';
import { logWarning } from 'common/utils/logger';
import { getCorrectedTimeWindows } from './utils/get-corrected-time-windows';
import { prepareTourUpdateStopByRoutePointId } from 'broker-admin/store/order-edit/utils/prepare-tour-update-stop-by-route-point-id';
import { v4 as uuidv4 } from 'uuid';
import { checkIsAvailableCountryForShipByCode } from 'common/store/country-settings-dict/utils/check-location';
import { selectCountrySettingsByCountryCode } from 'common/store/country-settings-dict/selectors';
import isNil from 'lodash/isNil';
import { patchRoutePointIdByStopIndexForDeleteStop } from './utils/patch-route-point-id-by-stop-index';

function* waitCreatingFinishIfNeedOrderContextSaga(): WrapGeneratorT<void> {
    const createContextRequest: ReturnType<typeof selectOrderEditCreateContextRequest> = yield select(
        selectOrderEditCreateContextRequest,
    );
    if (createContextRequest.loading) {
        yield take([createOrderEditContextRequestError.type, createOrderEditContextRequestSuccess.type]);
    }
}

function* waitApplyingFinishIfNeedOrderContextSaga(): WrapGeneratorT<void> {
    const applyContextRequest: ReturnType<typeof selectOrderEditApplyContextRequest> = yield select(
        selectOrderEditApplyContextRequest,
    );

    if (applyContextRequest.loading) {
        yield take([applyOrderEditContextRequestError.type, applyOrderEditContextRequestSuccess.type]);
    }
}

function* waitDeletingFinishIfNeedOrderContextSaga(): WrapGeneratorT<void> {
    const deleteContextRequest: ReturnType<typeof selectOrderEditDeleteContextRequest> = yield select(
        selectOrderEditDeleteContextRequest,
    );
    if (deleteContextRequest.loading) {
        yield take([deleteOrderEditContextRequestError.type, deleteOrderEditContextRequestSuccess.type]);
    }
}

function* waitFinishAllActionIfNeedOrderContextSaga(): WrapGeneratorT<void> {
    yield waitCreatingFinishIfNeedOrderContextSaga();
    yield waitApplyingFinishIfNeedOrderContextSaga();
    yield waitDeletingFinishIfNeedOrderContextSaga();
}

function reCreateOrderEditContextSaga() {
    return function* (action: ReCreateOrderEditContextRequestActionT): WrapGeneratorT<void> {
        const { dispatchId, fakeSessionId } = action.payload;
        if (!dispatchId) {
            return;
        }

        // yield put(resetOrderEdit({}));
        yield put(createOrderEditContextRequest({ dispatchId, fakeSessionId: uuidv4() }));
    };
}

function refreshOrderEditContextSaga() {
    return function* (action: RefreshOrderEditContextRequestActionT): WrapGeneratorT<void> {
        const { dispatchId, routePointIdByStopIndex } = action.payload;
        if (!dispatchId) {
            return;
        }

        yield put(createOrderEditContextRequest({ dispatchId }));

        const orderEditContext: ReturnType<typeof selectOrderEditContext> = yield select(selectOrderEditContext);
        if (orderEditContext) {
            const queryModifyEvent: EditOrderQueryModifyChannelEventT = {};
            queryModifyEvent.correctedTimeWindows = getCorrectedTimeWindows(orderEditContext, routePointIdByStopIndex);
        }
    };
}

function createOrderEditContextSaga() {
    return function* (action: CreateOrderEditContextRequestActionT): WrapGeneratorT<void> {
        const { dispatchId, fakeSessionId } = action.payload;
        if (!dispatchId) {
            return;
        }

        yield waitFinishAllActionIfNeedOrderContextSaga();

        yield put(createOrderEditContextRequestBegin({}));

        const [fetchDispatchError, fetchDispatchResult]: ReturnApiT<typeof brokerTranziitApi.getDispatchDetails> =
            yield brokerTranziitApi.getDispatchDetails(dispatchId);
        if (fetchDispatchError) {
            yield put(
                createOrderEditContextRequestError({
                    error: fetchDispatchError,
                }),
            );
            return;
        }

        const orderId = getOrderId(fetchDispatchResult);
        if (!orderId) {
            yield put(
                createOrderEditContextRequestError({
                    error: new Error('Empty orderId in dispatch details'),
                }),
            );
            return;
        }

        const [error, result]: ReturnApiT<typeof brokerTranziitApi.editOrderCreateOrGetContext> =
            yield brokerTranziitApi.editOrderCreateOrGetContext(orderId);
        if (error) {
            yield put(createOrderEditContextRequestError({ error }));
            return;
        }

        yield put(
            createOrderEditContextRequestSuccess({
                context: result,
                contextTourUpdateStopByRoutePointId: null, // for reinit from form (contain dynamic id for points)
                dispatchDetails: fetchDispatchResult,
                fakeSessionId,
            }),
        );
    };
}

function initOrderEditSaga() {
    return function* (action: InitOrderEditActionT): WrapGeneratorT<void> {
        const dispatchId = action?.payload?.dispatchId || null;

        yield put(createOrderEditContextRequest({ dispatchId }));
    };
}

function finishOrderEditSaga() {
    return function* (action: FinishOrderEditActionT): WrapGeneratorT<void> {
        const applyContextRequest: ReturnType<typeof selectOrderEditApplyContextRequest> = yield select(
            selectOrderEditApplyContextRequest,
        );
        if (!applyContextRequest.ok) {
            yield put(deleteOrderEditContextRequest({}));
        }

        // after finish
        yield put(resetOrderEdit({}));
    };
}

function deleteOrderEditContextSaga() {
    return function* (action: DeleteOrderEditContextRequestActionT): WrapGeneratorT<void> {
        yield waitFinishAllActionIfNeedOrderContextSaga();

        // if not created
        const orderEditDispatchDetails: ReturnType<typeof selectOrderEditDispatchDetails> = yield select(
            selectOrderEditDispatchDetails,
        );
        const orderId = getOrderId(orderEditDispatchDetails);
        if (!orderId) {
            return;
        }

        yield put(deleteOrderEditContextRequestBegin({}));

        const [error]: ReturnApiT<typeof brokerTranziitApi.editOrderDeleteContext> =
            yield brokerTranziitApi.editOrderDeleteContext(orderId);
        if (error) {
            yield put(deleteOrderEditContextRequestError({ error }));
            return;
        }

        yield put(deleteOrderEditContextRequestSuccess({}));
    };
}

function catchAnyOrderEditContextError() {
    return function* (action: AnyOrderEditRequestErrorActionT): WrapGeneratorT<void> {
        const orderEditDispatchDetails: ReturnType<typeof selectOrderEditDispatchDetails> = yield select(
            selectOrderEditDispatchDetails,
        );
        const currentDispatchId = orderEditDispatchDetails?.id || null;

        yield put(
            reCreateOrderEditContextRequest({
                dispatchId: currentDispatchId,
            }),
        );
    };
}

function applyOrderEditContextSaga() {
    return function* (action: ApplyOrderEditContextRequestActionT): WrapGeneratorT<void> {
        yield waitFinishAllActionIfNeedOrderContextSaga();

        // if not created
        const orderEditDispatchDetails: ReturnType<typeof selectOrderEditDispatchDetails> = yield select(
            selectOrderEditDispatchDetails,
        );
        const orderId = getOrderId(orderEditDispatchDetails);
        if (!orderId) {
            return;
        }

        yield put(applyOrderEditContextRequestBegin({}));

        const [error]: ReturnApiT<typeof brokerTranziitApi.editOrderApplyContext> =
            yield brokerTranziitApi.editOrderApplyContext(orderId);
        if (error) {
            yield put(applyOrderEditContextRequestError({ error }));
            return;
        }

        yield put(applyOrderEditContextRequestSuccess({}));
    };
}

// eslint-disable-next-line require-yield
function* changeReserveQuerySaga(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    companyType: CompanyTypeEnum,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    action: ChangeOrderEditContextRequestActionT,
): WrapGeneratorT<EditOrderQueryModifyChannelEventT> {
    const queryModifyEvent: EditOrderQueryModifyChannelEventT = {};

    // if not created
    const orderEditContext: ReturnType<typeof selectOrderEditContext> = yield select(selectOrderEditContext);
    if (!orderEditContext) {
        logWarning('order-edit queue, empty orderEditContext', action);
        return queryModifyEvent;
    }
    let contextAfterModify: ApiEditOrderContextT | null = orderEditContext;

    const orderEditDispatchDetails: ReturnType<typeof selectOrderEditDispatchDetails> = yield select(
        selectOrderEditDispatchDetails,
    );
    const orderId = getOrderId(orderEditDispatchDetails);
    if (!orderId) {
        logWarning('order-edit queue, empty orderId', action);
        return queryModifyEvent;
    }

    const { payload } = action;

    let updateTourUpdateStopByRoutePointId: ApiTourUpdateStopByRoutePointIdT | null = null;

    const stateTourUpdateStopByRoutePointId: ReturnType<typeof selectOrderEditContextTourUpdateStopByRoutePointId> =
        yield select(selectOrderEditContextTourUpdateStopByRoutePointId);
    const shouldAddRoutePoint = isNil(stateTourUpdateStopByRoutePointId?.[payload.routePointId]);

    if (payload.type === 'change-route-point-location' && shouldAddRoutePoint) {
        const pointLocation = convertToPointLocation(payload.address);
        if (!pointLocation) {
            logWarning('order-edit queue, empty pointLocation', action);
            return queryModifyEvent;
        }

        const pointType = convertRoutePointType(payload.routePointType);
        if (!pointType) {
            logWarning('order-edit queue, empty pointType', action);
            return queryModifyEvent;
        }

        const countrySettingsByCountryCode: ReturnType<typeof selectCountrySettingsByCountryCode> = yield select(
            selectCountrySettingsByCountryCode,
        );
        const isAvailableCountry = checkIsAvailableCountryForShipByCode(
            payload.address?.country || null,
            countrySettingsByCountryCode,
        );
        if (!isAvailableCountry) {
            return queryModifyEvent;
        }

        const [errorAfterAddStop, contextAfterAddStop]: ReturnApiT<typeof brokerTranziitApi.editOrderContextAddStop> =
            yield brokerTranziitApi.editOrderContextAddStop(orderId, {
                index: payload.routeStopIndex,
                address: pointLocation,
                type: pointType,
            });

        if (errorAfterAddStop) {
            yield put(changeContextError({ error: errorAfterAddStop }));
        }

        contextAfterModify = contextAfterAddStop;

        updateTourUpdateStopByRoutePointId = prepareTourUpdateStopByRoutePointId(
            contextAfterModify,
            payload.routePointIdByStopIndex,
        );
        queryModifyEvent.correctedTimeWindows = getCorrectedTimeWindows(
            contextAfterModify,
            payload.routePointIdByStopIndex,
        );
    }

    if (payload.type === 'change-route-point-location' && !shouldAddRoutePoint) {
        const pointLocation = convertToPointLocation(payload.address);
        if (!pointLocation) {
            logWarning('order-edit queue, empty pointLocation', action);
            return queryModifyEvent;
        }

        const countrySettingsByCountryCode: ReturnType<typeof selectCountrySettingsByCountryCode> = yield select(
            selectCountrySettingsByCountryCode,
        );
        const isAvailableCountry = checkIsAvailableCountryForShipByCode(
            payload.address?.country || null,
            countrySettingsByCountryCode,
        );
        if (!isAvailableCountry) {
            return queryModifyEvent;
        }

        const [error, result]: ReturnApiT<typeof brokerTranziitApi.editOrderContextUpdateStop> =
            yield brokerTranziitApi.editOrderContextUpdateStop(orderId, payload.routeStopIndex, {
                address: pointLocation,
            });

        if (error) {
            yield put(changeContextError({ error }));
        }

        contextAfterModify = result;

        updateTourUpdateStopByRoutePointId = prepareTourUpdateStopByRoutePointId(
            contextAfterModify,
            payload.routePointIdByStopIndex,
        );
        queryModifyEvent.correctedTimeWindows = getCorrectedTimeWindows(
            contextAfterModify,
            payload.routePointIdByStopIndex,
        );
    }

    if (payload.type === 'change-route-point-time-window') {
        const pointLocation = convertToPointLocation(payload.address);
        if (!pointLocation) {
            logWarning('order-edit queue, empty pointLocation', action);
            return queryModifyEvent;
        }

        const [error, result]: ReturnApiT<typeof brokerTranziitApi.editOrderContextUpdateStop> =
            yield brokerTranziitApi.editOrderContextUpdateStop(orderId, payload.routeStopIndex, {
                dateTimeFrom: payload.timeWindow.start,
                dateTimeTo: payload.timeWindow.end,
                address: pointLocation,
            });

        if (error) {
            yield put(changeContextError({ error }));
        }

        contextAfterModify = result;

        updateTourUpdateStopByRoutePointId = prepareTourUpdateStopByRoutePointId(
            contextAfterModify,
            payload.routePointIdByStopIndex,
        );
        queryModifyEvent.correctedTimeWindows = getCorrectedTimeWindows(
            contextAfterModify,
            payload.routePointIdByStopIndex,
        );
    }

    if (payload.type === 'delete-route-point') {
        const hasRoutePoint = !!stateTourUpdateStopByRoutePointId?.[payload.routePointId];
        if (!hasRoutePoint) {
            return queryModifyEvent;
        }

        const [error, result]: ReturnApiT<typeof brokerTranziitApi.editOrderContextDeleteStop> =
            yield brokerTranziitApi.editOrderContextDeleteStop(orderId, payload.routeStopIndex);

        if (error) {
            yield put(changeContextError({ error }));
        }

        contextAfterModify = result;

        const patchedRoutePointIdByStopIndex = patchRoutePointIdByStopIndexForDeleteStop(
            payload.routePointIdByStopIndex,
            payload.routeStopIndex,
        );

        updateTourUpdateStopByRoutePointId = prepareTourUpdateStopByRoutePointId(
            contextAfterModify,
            patchedRoutePointIdByStopIndex,
        );

        queryModifyEvent.correctedTimeWindows = getCorrectedTimeWindows(
            contextAfterModify,
            patchedRoutePointIdByStopIndex,
        );
    }

    yield put(
        changeContextSuccess({
            context: contextAfterModify,
            contextTourUpdateStopByRoutePointId: updateTourUpdateStopByRoutePointId,
        }),
    );

    return queryModifyEvent;
}

type BufferActionT = ChangeOrderEditContextRequestActionT;

function* watchSyncChangeContextQuerySaga(companyType: CompanyTypeEnum): WrapGeneratorT<void> {
    const buffer = buffers.fixed<BufferActionT>(100);
    const channel = yield actionChannel(changeContext.type, buffer);

    while (true) {
        const action: BufferActionT = yield take(channel);

        yield put(setIsApplyingContextChanges(true));

        const queryModifyEvent = yield* changeReserveQuerySaga(companyType, action);
        orderEditQueryModifyChannel.emit(queryModifyEvent);

        if (buffer.isEmpty()) {
            yield put(setIsApplyingContextChanges(false));
        }
    }
}

function* orderEditSaga(companyType: CompanyTypeEnum): WrapGeneratorT<void> {
    yield takeEvery(initOrderEdit.type, initOrderEditSaga());
    yield takeEvery(finishOrderEdit.type, finishOrderEditSaga());
    yield takeEvery(reCreateOrderEditContextRequest.type, reCreateOrderEditContextSaga());
    yield takeEvery(refreshOrderEditContextRequest.type, refreshOrderEditContextSaga());
    yield takeEvery(createOrderEditContextRequest.type, createOrderEditContextSaga());
    yield takeEvery(deleteOrderEditContextRequest.type, deleteOrderEditContextSaga());
    yield takeEvery(applyOrderEditContextRequest.type, applyOrderEditContextSaga());
    yield takeEvery([changeContextError.type, applyOrderEditContextRequestError.type], catchAnyOrderEditContextError());
    yield fork(watchSyncChangeContextQuerySaga, companyType);
}

export { orderEditSaga };
