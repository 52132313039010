import { ApiEditOrderContextT, ApiTourUpdateStopDataT } from 'broker-admin/utils/api/broker-tranziit/models';
import { ApiLocationT } from 'common/utils/api/models';

export type RoutePointIdT = string;
export type RouteStopIndexT = number;

export enum RoutePointTypeEnum {
    pickupOrDelivery = 'pickup-or-delivery',
    dropAndHook = 'drop-and-hook',
    driveThrough = 'drive-through',
    unknown = 'unknown', // pickup truck, trailer ....
}

export type RoutePointTimeWindowT = {
    start: ISODateTimeT;
    end: ISODateTimeT;
};

export type EditOrderContextT = ApiEditOrderContextT & {
    expireTime: number | null;
};

export type EditOrderQueryModifyChannelEventT = {
    correctedTimeWindows?: Record<RoutePointIdT, RoutePointTimeWindowT | null>;
};

export type FakeSessionIdT = string;

export type StopIndexByRoutePointIdT = Record<RoutePointIdT, number>;
export type RoutePointIdByStopIndexT = Record<string, RoutePointIdT>;

export type ApiTourUpdateStopByRoutePointIdT = Record<RoutePointIdT, ApiTourUpdateStopDataT>;

export type ChangeContextPayloadT =
    | {
          type: 'change-route-point-location';
          routePointId: RoutePointIdT;
          routePointIdByStopIndex: RoutePointIdByStopIndexT;
          routeStopIndex: RouteStopIndexT;
          routePointType: RoutePointTypeEnum;
          address: ApiLocationT;
      }
    | {
          type: 'change-route-point-time-window';
          routePointId: RoutePointIdT;
          routePointIdByStopIndex: RoutePointIdByStopIndexT;
          routeStopIndex: RouteStopIndexT;
          timeWindow: RoutePointTimeWindowT;
          address: ApiLocationT;
      }
    | {
          type: 'delete-route-point';
          routePointId: RoutePointIdT;
          routePointIdByStopIndex: RoutePointIdByStopIndexT;
          routeStopIndex: RouteStopIndexT;
      };
