import React from 'react';
import { useTranslation } from 'react-i18next';
import PillLabel, { PillLabelThemeEnum } from 'common/components/PillLabel/PillLabel';
import { useSelector } from 'react-redux';
import { selectMaxWindowSizeMs, selectMinWindowSizeMs } from 'common/store/settings/selectors';

type PropsT = {
    timeWindow: TimeWindowT | null | undefined;
    className?: string;
};

const TimeIntervalLimitPillLabel: React.FC<PropsT> = (props) => {
    const { timeWindow, className } = props;

    const { t } = useTranslation();

    const minWindowSizeMs = useSelector(selectMinWindowSizeMs);
    const maxWindowSizeMs = useSelector(selectMaxWindowSizeMs);

    if (!timeWindow) {
        return null;
    }

    const diff = timeWindow[1] - timeWindow[0];

    return (
        <>
            {false && diff === minWindowSizeMs && (
                <PillLabel isSymmetrical theme={PillLabelThemeEnum.blazeOrange} className={className}>
                    {t('dispatch-route-edit.pills.min-time-slot')}
                </PillLabel>
            )}
            {diff === maxWindowSizeMs && (
                <PillLabel isSymmetrical theme={PillLabelThemeEnum.blazeOrange} className={className}>
                    {t('dispatch-route-edit.pills.max-time-slot')}
                </PillLabel>
            )}
        </>
    );
};

export default TimeIntervalLimitPillLabel;
