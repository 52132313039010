import { TFunction } from 'i18next';
import { AsyncFormErrorsT, FieldsEnum, FormValuesT, reasonI18nMap } from './constants';
import { RequestStatusT } from 'common/utils/request-status';
import {
    checkIsTranziitApiRequestError,
    TranziitApiRequestErrorSubTypeEnum,
} from 'common/utils/api/tranziit/errors/tranziit-api-errors';
import { ApiSelfCostErrorReasonT } from 'broker-admin/store/dispatch-assigment/models';
import { AssetAssignmentInfoT } from 'common/utils/assignment';

const selfCostInvalidReasonI18nMap: Record<ApiSelfCostErrorReasonT, string> = {
    CARRIER_DOESNT_HAVE_REQUIRED_CERTIFICATES:
        'assignment.validation.reasons.CARRIER_DOESNT_HAVE_REQUIRED_CERTIFICATES',
    INCAPABLE_EMISSION_STANDARD: 'assignment.validation.reasons.INCAPABLE_EMISSION_STANDARD',
    INCAPABLE_TRAILER_TYPE: 'assignment.validation.reasons.INCAPABLE_TRAILER_TYPE',
    TRAILER_LINKED_WITH_ANOTHER_TRUCK: 'assignment.validation.reasons.TRAILER_LINKED_WITH_ANOTHER_TRUCK',
    TRUCK_LINKED_WITH_ANOTHER_TRAILER: 'assignment.validation.reasons.TRUCK_LINKED_WITH_ANOTHER_TRAILER',
    TRAILER_CANT_BE_ON_TIME_FOR_NEXT_TOUR: 'assignment.validation.reasons.TRAILER_CANT_BE_ON_TIME_FOR_NEXT_TOUR',
    TRAILER_IS_NOT_CONTRACTED: 'assignment.validation.reasons.TRAILER_IS_NOT_CONTRACTED',
    TRUCK_CANT_BE_ON_TIME_FOR_NEXT_TOUR: 'assignment.validation.reasons.TRUCK_CANT_BE_ON_TIME_FOR_NEXT_TOUR',
    TRUCK_IS_NOT_CONTRACTED: 'assignment.validation.reasons.TRUCK_IS_NOT_CONTRACTED',
    TRUCK_IS_NOT_IN_FREE_STATUS: 'assignment.validation.reasons.TRUCK_IS_NOT_IN_FREE_STATUS',
    TOUR_ORIGIN_IN_PROHIBITED_COUNTRIES: 'assignment.validation.reasons.TOUR_ORIGIN_IN_PROHIBITED_COUNTRIES',
    TOUR_DESTINATION_IN_PROHIBITED_COUNTRIES: 'assignment.validation.reasons.TOUR_DESTINATION_IN_PROHIBITED_COUNTRIES',
    TRAILER_CANT_BE_ON_TIME_FOR_TOUR: 'assignment.validation.reasons.TRAILER_CANT_BE_ON_TIME_FOR_TOUR',
    TRAILER_IS_NOT_IN_FREE_STATUS: 'assignment.validation.reasons.TRAILER_IS_NOT_IN_FREE_STATUS',
    TRAILER_HAS_SCHEDULE_ON_TRIP_DATES: 'assignment.validation.reasons.TRAILER_HAS_SCHEDULE_ON_TRIP_DATES',
    TRUCK_CANT_BE_ON_TIME_FOR_TOUR: 'assignment.validation.reasons.TRUCK_CANT_BE_ON_TIME_FOR_TOUR',
    TRUCK_HAS_SCHEDULE_ON_TRIP_DATES: 'assignment.validation.reasons.TRUCK_HAS_SCHEDULE_ON_TRIP_DATES',
    ASSIGNED_ASSET_CONTRACT_IS_INACTIVE: 'assignment.validation.reasons.ASSIGNED_ASSET_CONTRACT_IS_INACTIVE',
    AT_LEAST_MUST_BE_ONE_ASSET: 'assignment.validation.reasons.AT_LEAST_MUST_BE_ONE_ASSET',
    TRAILER_ALREADY_ASSIGNED: 'assignment.validation.reasons.TRAILER_ALREADY_ASSIGNED',
    TRUCK_ALREADY_ASSIGNED: 'assignment.validation.reasons.TRUCK_ALREADY_ASSIGNED',
    TRAILER_CONTRACTED_INACTIVE: 'assignment.validation.reasons.TRAILER_CONTRACTED_INACTIVE',
    TRUCK_CONTRACTED_INACTIVE: 'assignment.validation.reasons.TRUCK_CONTRACTED_INACTIVE',
    COULD_NOT_RESOLVE_ADDRESS: 'assignment.validation.reasons.COULD_NOT_RESOLVE_ADDRESS',
    DROP_POINT_IS_IN_NOT_AVAILABLE_TO_SHIP_COUNTRY:
        'assignment.validation.reasons.DROP_POINT_IS_IN_NOT_AVAILABLE_TO_SHIP_COUNTRY',
    OTHER: 'assignment.validation.reasons.OTHER',
};

type MetaT = {
    trailerById: Record<TrailerIdT, AssetAssignmentInfoT>;
    truckById: Record<TruckIdT, AssetAssignmentInfoT>;
    isPrioritySelfCostPredictionStatus: boolean;
    selfCostPredictionRequestStatus: RequestStatusT;
};

const asyncValidations = (t: TFunction, values: FormValuesT, meta: MetaT): AsyncFormErrorsT => {
    const errors: AsyncFormErrorsT = {};

    const { selfCostPredictionRequestStatus, isPrioritySelfCostPredictionStatus, trailerById, truckById } = meta;

    const selectedTrailerId = values[FieldsEnum.trailerId];
    const selectedTrailer = selectedTrailerId ? trailerById[selectedTrailerId] : null;
    if (selectedTrailer?.invalidReason && !isPrioritySelfCostPredictionStatus) {
        errors[FieldsEnum.trailerId] = t(reasonI18nMap[selectedTrailer.invalidReason]);
    }

    const selectedTruckId = values[FieldsEnum.truckId];
    const selectedTruck = selectedTruckId ? truckById[selectedTruckId] : null;
    if (selectedTruck?.invalidReason && !isPrioritySelfCostPredictionStatus) {
        errors[FieldsEnum.truckId] = t(reasonI18nMap[selectedTruck.invalidReason]);
    }

    if (
        checkIsTranziitApiRequestError(selfCostPredictionRequestStatus.error) &&
        selfCostPredictionRequestStatus?.error?.subType ===
            TranziitApiRequestErrorSubTypeEnum.assignmentSelfCostPrediction &&
        selfCostPredictionRequestStatus?.error?.response?.reason
    ) {
        const errorCode = selfCostPredictionRequestStatus.error.response.reason;

        if (!errorCode.includes('TRUCK')) {
            errors[FieldsEnum.trailerId] = t(selfCostInvalidReasonI18nMap[errorCode as ApiSelfCostErrorReasonT]);
        }

        if (!errorCode.includes('TRAILER')) {
            errors[FieldsEnum.truckId] = t(selfCostInvalidReasonI18nMap[errorCode as ApiSelfCostErrorReasonT]);
        }
    }

    return errors;
};

export default asyncValidations;
