import * as React from 'react';
import { useMemo } from 'react';
import { FieldsEnum, FormValuesT, RoutePointFieldsEnum } from '../constants';
import { useSelector } from 'react-redux';
import { ALL_DAY_RANGE, DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import { RangeSelectorMarkT, RangeSelectorRangeT } from 'common/components/RangeSelector/RangeSelector';
import { isNonNil } from 'common/utils';
import { clearDateTimezone, MS_IN_MIN } from 'common/utils/time';
import { RangeSelectorZoneThemeEnum } from 'common/components/RangeSelector/constants';
import {
    selectMaxWindowSizeMs,
    selectMinWindowSizeMs,
    selectTimeSlotsDelegationLimitMs,
    selectTimeWindowQuantumMs,
} from 'common/store/settings/selectors';
import { useTranslation } from 'react-i18next';
import FormikConnectContextField from 'common/components/forms/FormikConnectContextField/FormikConnectContextField';
import GeoSuggest from 'common/components/GeoSuggest/GeoSuggest';
import TimeIntervalDurationPillLabel from '../TimeIntervalDurationPillLabel/TimeIntervalDurationPillLabel';
import { useFormikContext } from 'formik';
import { getFieldFormValue } from 'common/utils/form-values';
import cs from 'classnames';
import classNames from 'classnames/bind';
import styles from './DropAndHookRoutePointPicker.scss';
import TimeWindowPicker from 'common/components/range-selectors/TimeWindowPicker/TimeWindowPicker';
import DeleteWaypointTrigger from '../DeleteWaypointTrigger/DeleteWaypointTrigger';
import WaypointNumberIcon from '../WaypointNumberIcon/WaypointNumberIcon';
import LinkDatePicker, {
    DatePickerOverlayPositionEnum,
} from 'design-system/components/date-pickers/LinkDatePicker/LinkDatePicker';
import { CalendarDateValueT } from 'design-system/components/Calendar/Calendar';
import moment from 'moment';
import isEqual from 'lodash/isEqual';
import CalendarAltIcon from 'common/icons/CalendarAltIcon';

import TimeIntervalLimitPillLabel from '../TimeIntervalLimitPillLabel/TimeIntervalLimitPillLabel';
import TimeWindowZoneText from '../TimeWindowZoneText/TimeWindowZoneText';
import TeamDriveIcon, { TeamDriveIconProps } from 'common/icons/TeamDriveIcon';
import UrgentIcon, { UrgentIconProps } from 'common/icons/UrgentIcon';
import HourglassIcon, { HourglassIconProps } from 'common/icons/HourglassIcon';
import { ApiTourUpdateStopDataT } from 'broker-admin/utils/api/broker-tranziit/models';
import {
    convertReserveTimeWindowToRelativeTimeWindow,
    setDateForReserveTimeWindow,
    setTimeWindowForReserveTimeWindow,
    suggestLastTimeWindowOfDay,
} from 'common/layouts/NewOrderPage/ShipmentDetailsForm/utils';
import { OnChangeRoutePointAddressT, OnChangeRoutePointTimeWindowT, OnDeleteRoutePointReserveT } from '../models';
import { prepareLocation } from 'common/utils/prepare-location';
import FieldValue from 'common/components/FieldValue/FieldValue';
import {
    checkIsPointFakeId,
    getRoutePointTimeWindowHash,
} from 'broker-admin/layouts/DispatchesPage/DispatchRouteEditPage/RouteEditForm/utils';
import Tooltip, { TooltipPositionEnum, TooltipThemeEnum } from 'design-system/components/Tooltip/Tooltip';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
    routePointIndex: number;
    isFirstRoutePointIndex: boolean;
    isLastRoutePointIndex: boolean;
    tourUpdateStop: ApiTourUpdateStopDataT | null;
    oldTourUpdateStop: ApiTourUpdateStopDataT | null;
    onRemove: OnDeleteRoutePointReserveT;
    isAllowRemove: boolean;

    isAllowUpdateTimeWindows: boolean;
    isAllowUpdateAddress: boolean;

    onManualSelectLocation: (location: LocationT | null, routePointIndex: number) => void;
    onChangeRoutePointAddress: OnChangeRoutePointAddressT;
    onChangeRoutePointTimeWindow: OnChangeRoutePointTimeWindowT;
    isDisabledPreviewReserve?: boolean;
    isShowSuggestLoading?: boolean;
};

export enum RangeIdSetEnum {
    original = 'original',
    urgentPickUp = 'urgent-pick-up',
    regularPickUp = 'regular-pick-up',
    teamDriveDropOff = 'team-drive-drop-off',
    regularDropOff = 'regular-drop-off',
    layoverDropOff = 'layover-drop-off',
}

const surchargeRangeTypeSet = new Set<string>([
    RangeIdSetEnum.urgentPickUp,
    RangeIdSetEnum.teamDriveDropOff,
    RangeIdSetEnum.layoverDropOff,
]);

export type TimeWindowPickerSettingsT = {
    availableValues: TimeWindowT;
    timeWindowRanges: RangeSelectorRangeT[];
    marks?: Array<RangeSelectorMarkT>;
};

export const DEFAULT_TIME_WINDOW_PICKER_SETTINGS: TimeWindowPickerSettingsT = {
    availableValues: ALL_DAY_RANGE,
    timeWindowRanges: [],
};

const DropAndHookRoutePointPicker: React.FC<PropsT> = React.memo((props) => {
    const {
        className,
        routePointIndex,
        isFirstRoutePointIndex,
        isLastRoutePointIndex,
        tourUpdateStop,
        oldTourUpdateStop,
        onRemove,
        isAllowUpdateTimeWindows,
        isAllowUpdateAddress,
        isAllowRemove,
        onManualSelectLocation,
        onChangeRoutePointAddress,
        onChangeRoutePointTimeWindow,
        isDisabledPreviewReserve,
        isShowSuggestLoading,
    } = props;

    const { t } = useTranslation();

    const formik = useFormikContext<FormValuesT>();

    const idFieldName = `${FieldsEnum.route}.${routePointIndex}.${RoutePointFieldsEnum.id}` as const;
    const locationFieldName = `${FieldsEnum.route}.${routePointIndex}.${RoutePointFieldsEnum.location}` as const;
    const timeWindowFieldName = `${FieldsEnum.route}.${routePointIndex}.${RoutePointFieldsEnum.timeWindow}` as const;
    const typeFieldName = `${FieldsEnum.route}.${routePointIndex}.${RoutePointFieldsEnum.type}` as const;

    const selectedTimeWindow = getFieldFormValue(formik.values, timeWindowFieldName);
    const id = getFieldFormValue(formik.values, idFieldName);
    const type = getFieldFormValue(formik.values, typeFieldName);
    const location = getFieldFormValue(formik.values, locationFieldName);

    const isChangedTimeWindow =
        !!oldTourUpdateStop &&
        getRoutePointTimeWindowHash(selectedTimeWindow?.start, selectedTimeWindow?.end) !==
            getRoutePointTimeWindowHash(oldTourUpdateStop?.originalDateTimeFrom, oldTourUpdateStop?.originalDateTimeTo);

    const timeWindowQuantumMs = useSelector(selectTimeWindowQuantumMs);

    const minWindowSizeMs = useSelector(selectMinWindowSizeMs);
    const maxWindowSizeMs = useSelector(selectMaxWindowSizeMs);

    const minRangeTimeWindow = minWindowSizeMs - MS_IN_MIN;
    const maxRangeTimeWindow = maxWindowSizeMs + MS_IN_MIN;

    const teamDriveMark = useMemo(
        () => ({
            borderTheme: RangeSelectorZoneThemeEnum.orange,
            icon: <TeamDriveIcon {...TeamDriveIconProps.getCreateOrder()} />,
        }),
        [],
    );

    const urgentPickUpMark = useMemo(
        () => ({
            borderTheme: RangeSelectorZoneThemeEnum.orange,
            icon: <UrgentIcon {...UrgentIconProps.getCreateOrder()} />,
        }),
        [],
    );

    const originalRange = useMemo(
        () => ({
            id: RangeIdSetEnum.original,
            label: t('common:time-windows-types.urgent'),
            theme: RangeSelectorZoneThemeEnum.charcoal,
            children: (
                <Tooltip
                    position={TooltipPositionEnum.topCenter}
                    theme={TooltipThemeEnum.black}
                    className={cx('original-zone-tooltip-content')}
                    tooltipNode={
                        <TooltipContent isNoWrap theme={TooltipContentThemeEnum.black}>
                            {t('dispatch-route-edit.fields.route-point.tooltip.original-time-window')}
                        </TooltipContent>
                    }
                >
                    {() => <div style={{ height: '100%', width: '100%' }} />}
                </Tooltip>
            ),
        }),
        [t],
    );

    const layoverMark = useMemo(
        () => ({
            borderTheme: RangeSelectorZoneThemeEnum.orange,
            icon: <HourglassIcon {...HourglassIconProps.getCreateOrder()} />,
        }),
        [],
    );

    const teamDriveDropOffRange = useMemo(
        () => ({
            id: RangeIdSetEnum.teamDriveDropOff,
            label: t('common:time-windows-types.team-drive'),
            theme: RangeSelectorZoneThemeEnum.orange,
        }),
        [t],
    );

    const regularDropOffRange = useMemo(
        () => ({
            id: RangeIdSetEnum.regularDropOff,
            theme: null,
        }),
        [t],
    );

    const urgentPickUpRange = useMemo(
        () => ({
            id: RangeIdSetEnum.urgentPickUp,
            label: t('common:time-windows-types.urgent'),
            theme: RangeSelectorZoneThemeEnum.orange,
        }),
        [t],
    );

    const regularPickUpRange = useMemo(
        () => ({
            id: RangeIdSetEnum.regularPickUp,
            theme: null,
        }),
        [t],
    );

    const layoverDropOffRange = useMemo(
        () => ({
            id: RangeIdSetEnum.layoverDropOff,
            label: t('common:time-windows-types.layover'),
            theme: RangeSelectorZoneThemeEnum.orange,
        }),
        [t],
    );

    const renderZoneTextNode = React.useCallback((currentRanges: RangeSelectorRangeT[] | null) => {
        const surchargeCurrentRanges = currentRanges?.filter((range) => {
            return range && surchargeRangeTypeSet.has(range.id);
        });

        if (!surchargeCurrentRanges?.length) {
            return null;
        }

        return <TimeWindowZoneText currentRanges={surchargeCurrentRanges} />;
    }, []);

    const tourUpdateStopTimeWindows = React.useMemo(() => {
        if (!tourUpdateStop) {
            return {
                urgentTimeWindow: null,
                regularTimeWindow: null,
                layoverTimeWindow: null,
                originalTimeWindow: null,
            };
        }

        const { regularFrom, urgentFrom, layoverStart, latestTo, originalDateTimeFrom, originalDateTimeTo } =
            tourUpdateStop;

        return {
            originalTimeWindow:
                originalDateTimeFrom && originalDateTimeTo
                    ? {
                          start: originalDateTimeFrom,
                          end: originalDateTimeTo,
                      }
                    : null,
            urgentTimeWindow:
                urgentFrom && regularFrom && urgentFrom !== regularFrom
                    ? {
                          start: urgentFrom,
                          end: regularFrom,
                      }
                    : null,
            regularTimeWindow: regularFrom
                ? {
                      start: regularFrom,
                      end: layoverStart ? null : latestTo || null,
                  }
                : null,
            layoverTimeWindow: layoverStart
                ? {
                      start: layoverStart,
                      end: latestTo || null,
                  }
                : null,
        };
    }, [tourUpdateStop]);

    const settings = React.useMemo<TimeWindowPickerSettingsT>(() => {
        const isFirstTimeWindow = routePointIndex === 0;

        if (!tourUpdateStop || !selectedTimeWindow) {
            return DEFAULT_TIME_WINDOW_PICKER_SETTINGS;
        }

        const baseDate = selectedTimeWindow.start;

        const availableValuesStart =
            tourUpdateStopTimeWindows?.urgentTimeWindow?.start || tourUpdateStopTimeWindows?.regularTimeWindow?.start;
        if (!availableValuesStart) {
            return DEFAULT_TIME_WINDOW_PICKER_SETTINGS;
        }

        const availableValuesEnd =
            tourUpdateStopTimeWindows?.layoverTimeWindow?.end ||
            tourUpdateStopTimeWindows?.regularTimeWindow?.end ||
            null;

        const availableValues = suggestLastTimeWindowOfDay(
            {
                start: availableValuesStart,
                end: availableValuesEnd,
            },
            baseDate,
        );

        const urgentTimeWindow = convertReserveTimeWindowToRelativeTimeWindow(
            suggestLastTimeWindowOfDay(tourUpdateStopTimeWindows?.urgentTimeWindow || null, baseDate),
        );

        const regularTimeWindow = convertReserveTimeWindowToRelativeTimeWindow(
            suggestLastTimeWindowOfDay(tourUpdateStopTimeWindows?.regularTimeWindow || null, baseDate),
        );

        const layoverTimeWindow = convertReserveTimeWindowToRelativeTimeWindow(
            suggestLastTimeWindowOfDay(tourUpdateStopTimeWindows?.layoverTimeWindow || null, baseDate),
        );

        const originalTimeWindow = convertReserveTimeWindowToRelativeTimeWindow(
            suggestLastTimeWindowOfDay(tourUpdateStopTimeWindows?.originalTimeWindow || null, baseDate),
        );

        const marks: Array<RangeSelectorMarkT> = [];

        if (layoverTimeWindow) {
            marks.push({
                ...layoverMark,
                value: layoverTimeWindow[0],
            });
        }

        const timeWindowRanges: RangeSelectorRangeT[] = [
            urgentTimeWindow
                ? {
                      ...(isFirstTimeWindow ? urgentPickUpRange : teamDriveDropOffRange),
                      values: urgentTimeWindow,
                      marks: [
                          {
                              ...(isFirstTimeWindow ? urgentPickUpMark : teamDriveMark),
                              value: urgentTimeWindow[0],
                          },
                      ],
                  }
                : null,
            regularTimeWindow
                ? {
                      ...(isFirstTimeWindow ? regularPickUpRange : regularDropOffRange),
                      values: regularTimeWindow,
                  }
                : null,
            layoverTimeWindow
                ? {
                      ...layoverDropOffRange,
                      values: layoverTimeWindow,
                      isHidden: true,
                      marks: [
                          {
                              ...layoverMark,
                              value: layoverTimeWindow[0],
                          },
                      ],
                  }
                : null,
            originalTimeWindow
                ? {
                      ...originalRange,
                      values: originalTimeWindow,
                  }
                : null,
        ].filter(isNonNil);

        const settings: TimeWindowPickerSettingsT = {
            availableValues: convertReserveTimeWindowToRelativeTimeWindow(availableValues) || ALL_DAY_RANGE,
            timeWindowRanges,
            marks,
        };

        return settings;
    }, [
        tourUpdateStop,
        tourUpdateStopTimeWindows,
        selectedTimeWindow,
        routePointIndex,
        teamDriveDropOffRange,
        regularDropOffRange,
        urgentPickUpRange,
        regularPickUpRange,
    ]);

    const relativeTimeWindow = useMemo((): TimeWindowT | null => {
        return convertReserveTimeWindowToRelativeTimeWindow(selectedTimeWindow);
    }, [selectedTimeWindow]);

    const handleChangeTimeWindow = (value: TimeWindowT) => {
        const newTimeWindow = setTimeWindowForReserveTimeWindow(value, selectedTimeWindow);

        const start = newTimeWindow?.start || null;
        const end = newTimeWindow?.end || null;
        if (!start || !end) {
            return;
        }

        const preparedLocation = prepareLocation(location);
        if (!preparedLocation) {
            return;
        }

        formik.setFieldValue(timeWindowFieldName, newTimeWindow);

        onChangeRoutePointTimeWindow(routePointIndex, {
            id,
            timeWindow: {
                start,
                end,
            },
            address: preparedLocation,
        });
    };

    const selectedDate = React.useMemo(() => {
        if (!selectedTimeWindow) {
            return null;
        }

        return moment(clearDateTimezone(selectedTimeWindow.start)).toDate();
    }, [selectedTimeWindow]);

    const timeSlotsDelegationLimitMs = useSelector(selectTimeSlotsDelegationLimitMs);

    const allowedTimeWindowStart = useMemo(() => {
        return (
            tourUpdateStopTimeWindows?.urgentTimeWindow?.start || tourUpdateStopTimeWindows?.regularTimeWindow?.start
        );
    }, [timeSlotsDelegationLimitMs, tourUpdateStopTimeWindows]);

    const minDate = allowedTimeWindowStart;
    const maxDate =
        (tourUpdateStopTimeWindows?.layoverTimeWindow
            ? tourUpdateStopTimeWindows?.layoverTimeWindow?.end
            : tourUpdateStopTimeWindows?.regularTimeWindow?.end) || undefined;

    const handleChangeDate = (date: CalendarDateValueT): void => {
        if (!date) {
            formik.setFieldValue(timeWindowFieldName, null);
            return;
        }

        const newTimeWindow = setDateForReserveTimeWindow(date, selectedTimeWindow);
        const isSameValue = isEqual(newTimeWindow, selectedTimeWindow);
        if (isSameValue) {
            return;
        }

        const start = newTimeWindow?.start || null;
        const end = newTimeWindow?.end || null;
        if (!start || !end) {
            return;
        }

        const preparedLocation = prepareLocation(location);
        if (!preparedLocation) {
            return;
        }

        formik.setFieldValue(timeWindowFieldName, newTimeWindow);

        onChangeRoutePointTimeWindow(routePointIndex, {
            id,
            timeWindow: {
                start,
                end,
            },
            address: preparedLocation,
        });
    };

    const isDisabledUpdateTimeWindows = isDisabledPreviewReserve || !isAllowUpdateTimeWindows;

    return (
        <div className={cs(cx('time-picker'), className)}>
            <FormikConnectContextField<FormValuesT, typeof locationFieldName>
                name={locationFieldName}
                label={t('dispatch-route-edit.fields.drop-and-hook-point.label')}
                fieldLabelClassName={cx('field', 'field--location')}
                subLabel={
                    <>
                        <TimeIntervalDurationPillLabel
                            className={cx('time-interval', {
                                'time-interval--isHalfOpacity': isDisabledUpdateTimeWindows,
                            })}
                            i18nKey={null}
                            isDayRelative
                            timeWindow={relativeTimeWindow}
                            isChanged={isChangedTimeWindow}
                        />
                        <TimeIntervalLimitPillLabel
                            className={cx('time-interval', {
                                'time-interval--isHalfOpacity': isDisabledUpdateTimeWindows,
                            })}
                            timeWindow={relativeTimeWindow}
                        />
                    </>
                }
                labelRightNode={
                    <div className={cx('right-node')}>
                        {selectedDate && (
                            <LinkDatePicker
                                leftIcon={
                                    <CalendarAltIcon
                                        size={DEFAULT_ICON_SIZE}
                                        strokeColor={StyleGuideColorsEnum.brandDark}
                                    />
                                }
                                isDisabled={isDisabledUpdateTimeWindows}
                                minDate={minDate || undefined}
                                maxDate={maxDate}
                                value={selectedDate}
                                onChange={handleChangeDate}
                                overlayPosition={DatePickerOverlayPositionEnum.bottomRight}
                            />
                        )}
                    </div>
                }
            >
                {(props, extendsProps) => (
                    <div className={cx('wrap')} data-point-id={id}>
                        {isAllowUpdateAddress ? (
                            <GeoSuggest
                                className={cx('geo-suggest')}
                                name={extendsProps.name}
                                value={extendsProps.value}
                                placeholder={t('dispatch-route-edit.fields.drop-and-hook-point.placeholder')}
                                renderLeftIcon={({ hasValue, hasError, hasWarning, isDisabled }) => (
                                    <WaypointNumberIcon
                                        routePointIndex={routePointIndex}
                                        hasValue={hasValue}
                                        hasError={hasError}
                                        hasWarning={hasWarning}
                                        isDisabled={isDisabled}
                                        isDropAndHookRoutePointPicker
                                        isNewRoutePoint={checkIsPointFakeId(id)}
                                    />
                                )}
                                onChange={(value) => {
                                    const location = prepareLocation(value);
                                    if (!location) {
                                        return;
                                    }

                                    props.onChange(value);

                                    onManualSelectLocation(value, routePointIndex);

                                    onChangeRoutePointAddress(routePointIndex, {
                                        id,
                                        routePointType: type,
                                        address: location,
                                    });
                                }}
                                hasError={props.hasError}
                                hasWarning={props.hasWarning}
                                onFocus={props.onFocus}
                                onBlur={props.onBlur}
                                testSelector={`pickup-or-delivery-location-${routePointIndex}`}
                                showClearControl={!!extendsProps.value}
                                isLoading={isShowSuggestLoading}
                            />
                        ) : (
                            <FieldValue
                                withoutErrorMessage
                                icon={
                                    <WaypointNumberIcon
                                        routePointIndex={routePointIndex}
                                        isDropAndHookRoutePointPicker
                                        isNewRoutePoint={checkIsPointFakeId(id)}
                                        hasValue={!!extendsProps.value}
                                    />
                                }
                                value={extendsProps.value?.address || ''}
                            />
                        )}
                        {isAllowRemove && (
                            <DeleteWaypointTrigger
                                onClick={() => {
                                    onRemove(routePointIndex, id);
                                }}
                            />
                        )}
                    </div>
                )}
            </FormikConnectContextField>
            <TimeWindowPicker
                range={ALL_DAY_RANGE}
                isDisabled={isDisabledUpdateTimeWindows}
                availableValues={settings?.availableValues}
                ranges={settings?.timeWindowRanges || []}
                marks={settings?.marks}
                values={relativeTimeWindow}
                step={timeWindowQuantumMs}
                minRangeWidth={minRangeTimeWindow}
                maxRangeWidth={maxRangeTimeWindow}
                renderZoneTextNode={renderZoneTextNode}
                onChange={handleChangeTimeWindow}
            />
        </div>
    );
});

export default DropAndHookRoutePointPicker;
