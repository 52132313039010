export enum RepeatStrategyTypeEnum {
    none = 'NONE',
    weekly = 'WEEKLY',
    monthly = 'MONTHLY',
    yearly = 'YEARLY',
}

export enum PriceComponentTypeEnum {
    costOfOperation = 'COST_OF_OPERATION',
    lineHaul = 'LINE_HAUL',
    fuel = 'FUEL',
    roadTaxes = 'ROAD_TAXES',
    serviceFee = 'SERVICE_FEE',
}

export enum StatisticGranularityEnum {
    day = 'DAY',
    week = 'WEEK',
    month = 'MONTH',
}

export enum StatisticSourceEnum {
    native = 'NATIVE',
    import = 'IMPORT',
}

export type ApiDoneStatisticT = Api.Definitions.SeriesAndAggregateMarginRateStatisticsDto;
export type ApiDoneFiltersStatisticT = ApiPaths.ApiV1BrokerStatisticsRatesDone.Get.QueryParameters;

export type ApiRejectStatisticT = Api.Definitions.SeriesAndAggregateRateStatisticsDto;
export type ApiRejectFiltersStatisticT = ApiPaths.ApiV1BrokerStatisticsRatesReject.Get.QueryParameters;

export type ApiBrokerInvoiceT = Api.Definitions.BrokerInvoiceDto;
export enum BrokerInvoicesSortEnum {
    createDate = 'create-date',
}

export type ApiCoordinateT = Api2.Definitions.Coordinate;

export type ApiEditOrderContextT = Api2.Definitions.OrderEditContext;
export type ApiTourEditNewStopT = Api2.Definitions.TourEditNewStop;
export type ApiTourUpdateUpdateStopT = Api2.Definitions.TourUpdateUpdateStop;
export type ApiTourUpdateStopDataT = Api2.Definitions.TourUpdateStopData;
