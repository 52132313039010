import { v4 as uuidv4 } from 'uuid';
import { FieldsEnum, RoutePointFieldsEnum, RoutePointFormValuesT } from './constants';
import { ApiTourUpdateStopDataT } from 'broker-admin/utils/api/broker-tranziit/models';

export const generatePointFakeId = (): string => {
    return `fake-id-${uuidv4()}`;
};

export const checkIsPointFakeId = (id: string) => {
    return id.startsWith('fake-id-');
};

export const getRoutePointFieldName = <T extends RoutePointFieldsEnum>(
    pointIndex: number,
    routePointField: T,
): `${FieldsEnum.route}.${number}.${T}` => {
    return `${FieldsEnum.route}.${pointIndex}.${routePointField}`;
};

export const checkIsAvailableRoutePoint = (routePointValues: RoutePointFormValuesT) => {
    return !!routePointValues[RoutePointFieldsEnum.location];
};

export const getRoutePointTimeWindowHash = (
    start: ISODateTimeT | null | undefined,
    end: ISODateTimeT | null | undefined,
) => {
    return `${start}-${end}`;
};

export const checkIsAllowToUpdateAddress = (tourUpdateStop: ApiTourUpdateStopDataT | null | undefined) => {
    return tourUpdateStop ? !!tourUpdateStop.allowToUpdateAddress : true;
};

export const checkIsAllowToDelete = (tourUpdateStop: ApiTourUpdateStopDataT | null | undefined) => {
    return tourUpdateStop ? !!tourUpdateStop.allowToDelete : true;
};

export const checkIsAllowToUpdateTimeSlots = (tourUpdateStop: ApiTourUpdateStopDataT | null | undefined) => {
    return tourUpdateStop ? !!tourUpdateStop.allowToUpdateTimeSlots : false;
};
