import * as React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';
import NumberIcon from 'common/icons/NumberIcon';

type PropsT = {
    className?: string;
    routePointIndex: number;
    isNewRoutePoint?: boolean;
    isDriveThroughRoutePointPicker?: boolean;
    isDropAndHookRoutePointPicker?: boolean;
    isPickUpDeliveryRoutePointPicker?: boolean;
    hasValue?: boolean;
    hasError?: boolean;
    hasWarning?: boolean;
    isDisabled?: boolean;
};

const WaypointNumberIcon: React.FC<PropsT> = React.memo((props) => {
    const {
        className,
        routePointIndex,
        isNewRoutePoint,
        isDriveThroughRoutePointPicker,
        isDropAndHookRoutePointPicker,
        isPickUpDeliveryRoutePointPicker,
        hasValue,
        hasError,
        hasWarning,
        isDisabled,
    } = props;

    let fillColor = StyleGuideColorsEnum.light;

    if (isDriveThroughRoutePointPicker && hasValue) {
        fillColor = StyleGuideColorsEnum.gray;
    }

    if (isDropAndHookRoutePointPicker && hasValue) {
        fillColor = StyleGuideColorsEnum.charcoal;
    }

    if (isPickUpDeliveryRoutePointPicker && hasValue) {
        fillColor = StyleGuideColorsEnum.charcoal;
    }

    if (isNewRoutePoint && hasValue) {
        fillColor = StyleGuideColorsEnum.blazeOrange;
    }

    if (hasError && hasValue) {
        fillColor = StyleGuideColorsEnum.tomatoRed;
    }

    if (hasWarning && hasValue) {
        fillColor = StyleGuideColorsEnum.orange;
    }

    if (isDisabled) {
        fillColor = StyleGuideColorsEnum.slate;
    }

    return <NumberIcon className={className} number={routePointIndex + 1} fillColor={fillColor} />;
});

export default WaypointNumberIcon;
