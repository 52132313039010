import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import requestStatus, { RequestStatusT } from 'common/utils/request-status';
import {
    ApiTourUpdateStopByRoutePointIdT,
    ChangeContextPayloadT,
    EditOrderContextT,
    FakeSessionIdT,
    RoutePointIdByStopIndexT,
} from 'broker-admin/store/order-edit/models';
import { DispatchDetailsT } from 'broker-admin/store/dispatch-details/models';
import isNumber from 'lodash/isNumber';
import { MS_IN_SEC } from 'common/utils/time';
import { ApiEditOrderContextT } from 'broker-admin/utils/api/broker-tranziit/models';

export type OrderEditStateT = {
    createContextRequestStatus: RequestStatusT;
    fakeSessionId: FakeSessionIdT | null;
    context: EditOrderContextT | null;
    contextTourUpdateStopByRoutePointId: ApiTourUpdateStopByRoutePointIdT | null;
    dispatchDetails: DispatchDetailsT | null;
    deleteContextRequestStatus: RequestStatusT;
    isApplyingContextChanges: boolean;
    applyContextRequestStatus: RequestStatusT;
    changeContextError: Error | null;
};

const initialState: OrderEditStateT = {
    createContextRequestStatus: requestStatus.initial,
    fakeSessionId: null,
    context: null,
    contextTourUpdateStopByRoutePointId: null,
    dispatchDetails: null,
    deleteContextRequestStatus: requestStatus.initial,
    isApplyingContextChanges: false,
    applyContextRequestStatus: requestStatus.initial,
    changeContextError: null,
};

export const orderEditSlice = createSlice({
    name: 'orderEdit',
    initialState,
    reducers: {
        resetOrderEdit: (
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            state,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            action: PayloadAction<{
                // nothing
            }>,
        ) => {
            return initialState;
        },
        initOrderEdit: (
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            state,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            action: PayloadAction<{
                dispatchId: DispatchIdT | null;
            }>,
        ) => {
            // nothing
        },
        finishOrderEdit: (
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            state,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            action: PayloadAction<{
                dispatchId: DispatchIdT | null;
            }>,
        ) => {
            // nothing
        },
        reCreateOrderEditContextRequest: (
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            state,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            action: PayloadAction<{
                dispatchId: DispatchIdT | null;
                fakeSessionId?: FakeSessionIdT | null;
            }>,
        ) => {
            // nothing
        },
        refreshOrderEditContextRequest: (
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            state,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            action: PayloadAction<{
                dispatchId: DispatchIdT | null;
                routePointIdByStopIndex: RoutePointIdByStopIndexT;
            }>,
        ) => {
            // nothing
        },
        createOrderEditContextRequest: (
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            state,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            action: PayloadAction<{
                dispatchId: DispatchIdT | null;
                fakeSessionId?: FakeSessionIdT | null;
            }>,
        ) => {
            // nothing
        },
        createOrderEditContextRequestBegin: (
            state,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            action: PayloadAction<{}>,
        ) => {
            state.createContextRequestStatus = requestStatus.loading;
        },
        setContextTourUpdateStopByRoutePointId: (
            state,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            action: PayloadAction<{
                contextTourUpdateStopByRoutePointId: ApiTourUpdateStopByRoutePointIdT | null;
            }>,
        ) => {
            state.contextTourUpdateStopByRoutePointId = action.payload.contextTourUpdateStopByRoutePointId;
        },
        createOrderEditContextRequestSuccess: (
            state,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            action: PayloadAction<{
                context: ApiEditOrderContextT | null;
                contextTourUpdateStopByRoutePointId: ApiTourUpdateStopByRoutePointIdT | null;
                dispatchDetails: DispatchDetailsT | null;
                fakeSessionId?: FakeSessionIdT | null;
            }>,
        ) => {
            const { context, dispatchDetails, contextTourUpdateStopByRoutePointId, fakeSessionId } = action.payload;

            if (fakeSessionId) {
                state.fakeSessionId = fakeSessionId;
            }

            state.contextTourUpdateStopByRoutePointId = contextTourUpdateStopByRoutePointId;
            state.context = context
                ? {
                      ...context,
                      expireTime: isNumber(context.secondsToExpire)
                          ? context.secondsToExpire * MS_IN_SEC + Date.now()
                          : null,
                  }
                : null;
            state.dispatchDetails = dispatchDetails;
            state.createContextRequestStatus = requestStatus.ok;
        },
        createOrderEditContextRequestError: (
            state,
            action: PayloadAction<{
                error: Error;
            }>,
        ) => {
            const { error } = action.payload;

            state.context = null;
            state.createContextRequestStatus = requestStatus.setError(error);
        },
        deleteOrderEditContextRequest: (
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            state,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            action: PayloadAction<{}>,
        ) => {
            // nothing
        },
        deleteOrderEditContextRequestBegin: (
            state,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            action: PayloadAction<{}>,
        ) => {
            state.deleteContextRequestStatus = requestStatus.loading;
        },
        deleteOrderEditContextRequestSuccess: (
            state,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            action: PayloadAction<{}>,
        ) => {
            state.context = null;
            state.deleteContextRequestStatus = requestStatus.ok;
        },
        deleteOrderEditContextRequestError: (
            state,
            action: PayloadAction<{
                error: Error;
            }>,
        ) => {
            const { error } = action.payload;

            state.context = null;
            state.deleteContextRequestStatus = requestStatus.setError(error);
        },
        applyOrderEditContextRequest: (
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            state,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            action: PayloadAction<{}>,
        ) => {
            // nothing
        },
        applyOrderEditContextRequestBegin: (
            state,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            action: PayloadAction<{}>,
        ) => {
            state.applyContextRequestStatus = requestStatus.loading;
        },
        applyOrderEditContextRequestSuccess: (
            state,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            action: PayloadAction<{}>,
        ) => {
            state.context = null;
            state.applyContextRequestStatus = requestStatus.ok;
        },
        applyOrderEditContextRequestError: (
            state,
            action: PayloadAction<{
                error: Error;
            }>,
        ) => {
            const { error } = action.payload;

            state.context = null;
            state.applyContextRequestStatus = requestStatus.setError(error);
        },
        changeContextError: (
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            state,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            action: PayloadAction<{
                error: Error;
            }>,
        ) => {
            state.changeContextError = action.payload.error;
        },
        changeContext: (
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            state,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            action: PayloadAction<ChangeContextPayloadT>,
        ) => {
            // nothing
        },
        changeContextSuccess: (
            state,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            action: PayloadAction<{
                context: ApiEditOrderContextT | null;
                contextTourUpdateStopByRoutePointId: ApiTourUpdateStopByRoutePointIdT | null;
            }>,
        ) => {
            const { context, contextTourUpdateStopByRoutePointId } = action.payload;
            if (!context) {
                return;
            }

            state.contextTourUpdateStopByRoutePointId = contextTourUpdateStopByRoutePointId;
            state.context = context
                ? {
                      ...context,
                      expireTime: isNumber(context.secondsToExpire)
                          ? context.secondsToExpire * MS_IN_SEC + Date.now()
                          : null,
                  }
                : null;
        },
        setIsApplyingContextChanges: (
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            state,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            action: PayloadAction<boolean>,
        ) => {
            state.isApplyingContextChanges = action.payload;
        },
    },
});

export const {
    initOrderEdit,
    finishOrderEdit,
    resetOrderEdit,
    refreshOrderEditContextRequest,
    reCreateOrderEditContextRequest,
    createOrderEditContextRequest,
    createOrderEditContextRequestBegin,
    createOrderEditContextRequestSuccess,
    createOrderEditContextRequestError,
    deleteOrderEditContextRequest,
    deleteOrderEditContextRequestBegin,
    deleteOrderEditContextRequestSuccess,
    deleteOrderEditContextRequestError,
    applyOrderEditContextRequest,
    applyOrderEditContextRequestBegin,
    applyOrderEditContextRequestError,
    applyOrderEditContextRequestSuccess,
    changeContext,
    changeContextError,
    changeContextSuccess,
    setIsApplyingContextChanges,
    setContextTourUpdateStopByRoutePointId,
} = orderEditSlice.actions;

export default orderEditSlice.reducer;
