import { FetchRulePageQueryT } from 'broker-admin/store/price-settings/rules/models';
import { QueryFiltersKeysEnum, QueryFiltersT } from 'broker-admin/layouts/SettingsPage/PriceSettingsRules/query-models';
import { PageSortT, SortDirectionEnum } from 'common/utils/query';
import { RulesSortEnum } from 'common/utils/api/models';

const formatSort = (sort: PageSortT<RulesSortEnum> | null): FetchRulePageQueryT['sortBy'] | undefined => {
    switch (sort?.value) {
        case RulesSortEnum.name: {
            if (sort?.direction === SortDirectionEnum.ASC) {
                return 'NAME_ASC';
            }
            return 'NAME_DESC';
        }
        case RulesSortEnum.updateDate: {
            if (sort?.direction === SortDirectionEnum.ASC) {
                return 'UPDATE_DATE_ASC';
            }
            return 'UPDATE_DATE_DESC';
        }
        default: {
            return undefined;
        }
    }
};

const prepareFetchPageQuery = (
    queryFilters: QueryFiltersT,
    sort: PageSortT<RulesSortEnum> | null,
): FetchRulePageQueryT => {
    const rulesShipperId = queryFilters[QueryFiltersKeysEnum.rulesShipperId];
    const trailerTypeIds = queryFilters[QueryFiltersKeysEnum.rulesTrailerTypeIds];

    return {
        name: queryFilters[QueryFiltersKeysEnum.rulesName] || undefined,
        group: queryFilters[QueryFiltersKeysEnum.rulesGroup] || undefined,
        trailerTypeIds: trailerTypeIds ? trailerTypeIds.map((trailerTypeId) => +trailerTypeId) : undefined,
        repeatStrategy: queryFilters[QueryFiltersKeysEnum.rulesRepeatStrategy] || undefined,
        appliedTarget: queryFilters[QueryFiltersKeysEnum.rulesImpactPriceComponent] || undefined,
        companyId: rulesShipperId || undefined,
        countriesFrom: queryFilters[QueryFiltersKeysEnum.rulesFromCountryCodes],
        countriesTo: queryFilters[QueryFiltersKeysEnum.rulesToCountryCodes],
        sortBy: formatSort(sort),
    };
};

export { prepareFetchPageQuery };
