import { TFunction } from 'i18next';

import { FieldsEnum, FormErrorsT, FormValuesT, RoutePointFieldsEnum } from './constants';
import { CountrySettingsByCodeT } from 'common/store/country-settings-dict/models';
import { checkIsAvailableCountryForShip } from 'common/store/country-settings-dict/utils/check-location';

type MetaT = {
    countriesSettingsByCode: CountrySettingsByCodeT;
};

const verifyLocation = (t: TFunction, location: LocationT | undefined | null): string | null => {
    if (!location) {
        return null;
    }

    if (Number.isNaN(location.utcOffsetMinutes)) {
        return t('dispatch-route-edit.errors.wrong-utc-offset');
    }

    if (
        !location.addressComponents?.zipCode &&
        !location.addressComponents?.city &&
        !location.addressComponents?.countryCode &&
        !location.addressComponents?.street1 &&
        !location.addressComponents?.street2
    ) {
        return t('dispatch-route-edit.errors.unsupported-area');
    }

    if (!location.addressComponents?.countryCode) {
        return t('dispatch-route-edit.errors.need-address-details.country-code');
    }

    if (!location.addressComponents?.city) {
        return t('dispatch-route-edit.errors.need-address-details.locality');
    }

    return null;
};

const validate = (t: TFunction, values: FormValuesT, meta: MetaT): FormErrorsT => {
    const { countriesSettingsByCode } = meta;

    const errors: FormErrorsT = {};

    const routeFormValue = values[FieldsEnum.route];
    routeFormValue.forEach((routePointFormValue, routePointIndex) => {
        const addRoutePointError = (fieldName: RoutePointFieldsEnum, message: string) => {
            if (!errors[FieldsEnum.route]) {
                errors[FieldsEnum.route] = [];
            }

            if (!errors[FieldsEnum.route][routePointIndex]) {
                errors[FieldsEnum.route][routePointIndex] = {};
            }

            errors[FieldsEnum.route][routePointIndex][fieldName] = message;
        };

        const routePointLocation = routeFormValue[routePointIndex][RoutePointFieldsEnum.location];
        if (!routePointLocation) {
            addRoutePointError(RoutePointFieldsEnum.location, t('dispatch-route-edit.errors.required-select-item'));
        }

        const routePointLocationError = verifyLocation(t, routePointLocation);
        if (routePointLocation && routePointLocationError) {
            addRoutePointError(RoutePointFieldsEnum.location, routePointLocationError);
        }

        if (!checkIsAvailableCountryForShip(routePointLocation, countriesSettingsByCode)) {
            addRoutePointError(RoutePointFieldsEnum.location, t('dispatch-route-edit.errors.unsupported-country'));
        }
    });

    return errors;
};

export default validate;
